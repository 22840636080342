import { b2x } from '@b2x/react/src';
import classNames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { CustomRadio } from './CustomRadio';
import { t } from './i18n/i18n';
import { Slider, SliderBreakpoint, SliderResponsiveProps } from './slider/Slider';

export interface SkuAttributeFormGroupProps {
  attribute: {
    title: string;
    value?: string;
  };
  className?: string;
  formGroup: b2x.FormGroupProps;
  radios: Array<{ radio: b2x.RadioProps; skus: Array<b2x.SkuApiDto> }>;
  sliderResponsive: Partial<Record<SliderBreakpoint, SliderResponsiveProps>>;
  slidesPerView: number | 'auto';
  spaceBetween?: number;
}

export const SkuAttributeFormGroup = ({
  attribute,
  className,
  formGroup,
  radios,
  sliderResponsive,
  slidesPerView,
  spaceBetween,
}: SkuAttributeFormGroupProps) => {
  const radioSquare = React.useMemo<boolean>(
    () =>
      radios.some(
        (element) => typeof element.radio.label === 'string' && element.radio.label && element.radio.label.length > 3
      ),
    [radios]
  );

  return (
    <b2x.FormGroup {...formGroup} className={classNames(className, 'mb-2 mb-md-3')} label={undefined} noMarginBottom>
      <div className="form-label small fw-light mb-2">
        <span className="fw-normal">{attribute.title}:</span>&nbsp;
        {attribute.value ?? t('product.selectSize')}
      </div>
      <div className="variant-slider-container d-flex position-relative">
        <Slider navigation responsive={sliderResponsive} slidesPerView={slidesPerView} spaceBetween={spaceBetween}>
          {radios.map(({ radio }) => (
            <SwiperSlide key={radio.id}>
              <b2x.Radio {...radio} inline noMargin>
                <CustomRadio isSquare={radioSquare} label={radio.label as string} />
              </b2x.Radio>
            </SwiperSlide>
          ))}
        </Slider>
      </div>
    </b2x.FormGroup>
  );
};
