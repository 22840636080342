import { AssetContentSectionV1 } from './contentTypes';
import { ImageFromContentV1 } from './ImageFromContentV1';
import { VideoFromContentV1 } from './VideoFromContentV1';

export interface AssetV1Props extends AssetContentSectionV1 {
  className?: string;
  fluid?: boolean;
}

export const AssetV1 = ({ className, fluid, image, type, video }: AssetV1Props) => {
  return (
    <>
      {type === 'image' && image && <ImageFromContentV1 {...image} className={className} fluid={fluid} />}
      {type === 'video' && video && <VideoFromContentV1 {...video} className={className} />}
    </>
  );
};
