import { SearchFieldsHelper } from './form/SearchForm';
import { SearchFiltersA } from './SearchFiltersA';
import { SearchFiltersB } from './SearchFiltersB';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface SearchFiltersProps {
  excludeFacets?: Array<string>;
  fieldsHelper: SearchFieldsHelper;
}

export type SearchFiltersVariants = 'A' | 'B';

const SearchFiltersController = (props: PropsWithCustomComponent<SearchFiltersProps>) => (
  <VariantsController<SearchFiltersProps, SearchFiltersVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: SearchFiltersA, B: SearchFiltersB },
      name: 'SearchFilters',
    }}
  />
);
export { SearchFiltersController as SearchFilters };
