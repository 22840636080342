import { b2x } from '@b2x/react/src';

import { FooterIconsContentType } from './contentTypes';
import { Icon, IconName, isIconName } from './Icon';

export interface FooterIconsProps {
  className?: string;
}

export const FooterIcons = ({ className }: FooterIconsProps) => {
  const footerIconsContent = b2x.useContent<FooterIconsContentType>('FOOTER_ICONS_CONTENT');
  return (
    <>
      {footerIconsContent?.body.iconsList && (
        <b2x.Row gap={{ xl: 4, xs: 0 }}>
          {footerIconsContent.body.iconsList.map((item) => {
            return (
              item.name &&
              isIconName(item.name) && (
                <b2x.Col
                  className="d-flex d-lg-block justify-content-between align-items-center px-3 mb-3 mb-lg-0 text-lg-center"
                  key={item.iconTitle}
                  size={{ lg: 'auto', xs: 12 }}
                >
                  {item.url ? (
                    <b2x.router.Link
                      className="d-flex d-lg-block justify-content-between w-100 align-items-center text-white text-reset text-decoration-none"
                      to={item.url}
                    >
                      <Icon className="order-2" fontSize={35} name={item.name as IconName} />
                      <h6 className="order-1 pt-lg-3">{item.title}</h6>
                    </b2x.router.Link>
                  ) : (
                    <>
                      <Icon className="order-2" fontSize={35} name={item.name as IconName} />
                      <h6 className="order-1 pt-lg-3">{item.title}</h6>
                    </>
                  )}
                </b2x.Col>
              )
            );
          })}
        </b2x.Row>
      )}
    </>
  );
};
