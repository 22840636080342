import { MenuApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { FormikProps } from 'formik';
import React from 'react';

import { Accordion, AccordionItem } from '../Accordion';
import { Button } from '../Button';
import { Col } from '../Col';
import { Container } from '../Container';
import { Checkbox } from '../form/fields/RadioCheck';
import { ShopSearchFormHelper, ShopSearchFormValues } from '../form/ShopSearchForm';
import { Div, Span } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Row } from '../Row';
import { GoogleMapInstance } from '../useGoogleMap';
import { uiClassName } from '../util';
import { useStoreLocatorContext } from './StoreLocatorContext';

export interface FiltersPanelProps {
  filters: MenuApiDto | undefined;
  filtersPanelVisibility: boolean;
  googleMap: GoogleMapInstance;
  toggleFiltersPanel(): void;
}

export const FiltersPanel = ({ filters, filtersPanelVisibility, toggleFiltersPanel }: FiltersPanelProps) => {
  const { iconClose, variantPrimary } = useStoreLocatorContext();

  const handleResetButtonClick = React.useCallback((formik: FormikProps<ShopSearchFormValues>) => {
    formik.setFieldValue('facets', []);
    formik.setFieldValue('simpleSearch', '');
    formik.submitForm();
  }, []);

  return (
    <Div
      className={classnames(uiClassName({ bs5: 'position-absolute top-0 bottom-0 overflow-auto' }), {
        in: filtersPanelVisibility,
      })}
      id="filters-panel"
    >
      <Container className={uiClassName({ bs5: 'd-flex flex-column h-100' })}>
        <Div
          alignItems="center"
          className={uiClassName({ bs5: `text-${variantPrimary}` })}
          display="flex"
          justifyContent="spaceBetween"
          paddingY={2}
        >
          <h6 className={uiClassName({ bs5: 'fw-bold text-uppercase small m-0' })}>
            {t('storeLocator.filterPanel.selectProduct')}
          </h6>
          <Button
            className={uiClassName({ bs5: 'pe-0' })}
            iconStart={{ name: iconClose, size: 16 }}
            onClick={toggleFiltersPanel}
            style={{ background: 'transparent', borderColor: 'transparent' }}
          />
        </Div>
        {/* Metto questo rendering condizionale per "resettare" il form a ogni chiusura del pannello */}
        {filtersPanelVisibility && (
          <ShopSearchFormHelper
            className={uiClassName({ bs5: 'd-flex flex-column align-items-between flex-grow-1' })}
            menu={filters}
            onSuccess={toggleFiltersPanel}
          >
            {({ fieldsHelper, formik }) => (
              <>
                <Accordion alwaysOpened className={uiClassName({ bs5: 'flex-grow-1' })} id="filter">
                  {fieldsHelper.filters
                    .filter((filter) => filter.filter.code !== 'FACE_BRAND')
                    .map((filter, index) => {
                      // Recupero il numero di filtri attivi per la categoria di filtraggio corrente
                      const activeFilters = filter.children.reduce(
                        (accumulator, currentValue) =>
                          currentValue.filter.checkType === 'CHECKED' ? accumulator + 1 : accumulator,
                        0
                      );
                      return (
                        <AccordionItem
                          id={filter.filter.id}
                          key={filter.filter.id}
                          show
                          title={
                            <Div alignItems="center" className="w-100" display="flex" justifyContent="spaceBetween">
                              <Span>{filter.filter.name}</Span>
                              {activeFilters > 0 && (
                                <Span
                                  alignItems="center"
                                  className={uiClassName({ bs5: 'bg-primary rounded-circle' })}
                                  display="flex"
                                  justifyContent="center"
                                  marginEnd={2}
                                  style={{ height: 20, width: 20 }}
                                >
                                  <Span className={uiClassName({ bs5: `lh-1 small fw-bold text-${variantPrimary}` })}>
                                    {activeFilters}
                                  </Span>
                                </Span>
                              )}
                            </Div>
                          }
                        >
                          <>
                            {filter.children.map((child, childrenIndex) => (
                              <Checkbox
                                key={child.checkbox.id}
                                {...child.checkbox}
                                className={filter.children.length === childrenIndex + 1 ? 'mb-0' : undefined}
                              />
                            ))}
                          </>
                        </AccordionItem>
                      );
                    })}
                </Accordion>
                <Div
                  className={uiClassName({ bs5: 'position-sticky bottom-0' })}
                  id="filters-panel-action"
                  marginX={-3}
                  padding={3}
                >
                  <Row gap={3}>
                    <Col className={uiClassName({ bs5: 'd-grid' })} size={6}>
                      <Button
                        disabled={formik.values.facets.length === 0}
                        label={'Reset'}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => {
                          handleResetButtonClick(formik);
                        }}
                        variant="outline-secondary"
                      />
                    </Col>
                    <Col className={uiClassName({ bs5: 'd-grid' })} size={6}>
                      <Button {...fieldsHelper.buttons.submit} />
                    </Col>
                  </Row>
                </Div>
              </>
            )}
          </ShopSearchFormHelper>
        )}
      </Container>
    </Div>
  );
};
