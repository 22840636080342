import { VideoAsBackgroundContentSectionV1 } from './contentTypes';
import { VideoVariant } from './Video';
import { VideoAsBackground } from './VideoAsBackground';

export interface VideoAsBackgroundFromContentV1Props extends VideoAsBackgroundContentSectionV1 {}

export const VideoAsBackgroundFromContentV1 = ({ variants }: VideoAsBackgroundFromContentV1Props) => {
  return (
    <>
      {variants && (
        <VideoAsBackground
          variants={variants.reduce<Array<VideoVariant>>((accumulator, { variant }) => {
            const { height, source, thumbnail, width } = variant ?? {};
            if (width !== undefined && height !== undefined && source !== undefined) {
              accumulator.push({
                height: height,
                source: {
                  bcom: source.bcom?.file
                    ? {
                        file: source.bcom.file,
                      }
                    : undefined,
                  vimeo: source.vimeo?.id
                    ? {
                        id: source.vimeo.id,
                        privacyHash: source.vimeo.privacyHash,
                        title: source.vimeo.title,
                      }
                    : undefined,
                  youtube: source.youtube?.id
                    ? {
                        id: source.youtube.id,
                      }
                    : undefined,
                },
                thumbnail: thumbnail?.url,
                width: width,
              });
            }
            return accumulator;
          }, [])}
        />
      )}
    </>
  );
};

export const isVideoAsBackgroundFromContentValid = (contentSection?: VideoAsBackgroundContentSectionV1) => {
  const variant = contentSection?.variants?.at(0)?.variant;
  return (
    variant?.width &&
    variant.height &&
    variant.source &&
    (variant.source.bcom?.file || variant.source.youtube?.id || variant.source.vimeo?.id)
  );
};
