import { b2x } from '@b2x/react/src';
import _ from 'lodash';

import { en } from './locales/en';

const mergedEn = _.merge(b2x.locales.en, en);

export type Resource = b2x.PartialDeep<typeof mergedEn>;
export type ResourceKeys = b2x.Leaves<typeof mergedEn>;

export const { addResource, changeLanguage, language, t } = b2x.i18nBuild<ResourceKeys>();
