import { GetProductOptions } from '@b2x/storefront-api-js-client/src/products';

import { ApiRequestConfig } from './api/useApiRequest';
import { useProductsByIds, UseProductsByIdsResult } from './useProductsByIds';

export interface ProductsByIdsProps {
  children?(props: UseProductsByIdsResult): React.ReactNode;
  config?: ApiRequestConfig;
  ids: Array<string | undefined>;
  options?: GetProductOptions;
}

export const ProductsByIds = ({ children, config, ids, options }: ProductsByIdsProps) => {
  const products = useProductsByIds(ids, options, config);

  return <>{children && children(products)}</>;
};
