import React from 'react';

import { ParentCoverer } from './ParentCoverer';
import { useWindowSize } from './useWindowSize';
import { Video, VideoVariant } from './Video';

export interface VideoAsBackgroundProps {
  variants: Array<VideoVariant>;
}

export const VideoAsBackground = ({ variants }: VideoAsBackgroundProps) => {
  const windowSize = useWindowSize();
  const parentRef = React.useRef<HTMLDivElement>(null);

  // const { pip = false, playsinline = true } = options ?? {};

  const variant = React.useMemo(() => {
    // Provare a rifare con un reducer
    if (parentRef.current && variants.length > 0) {
      const aspectRatio = parentRef.current.clientWidth / parentRef.current.clientHeight;

      return variants.reduce((accumulator, currentValue) => {
        const shortestDistanceSoFar = Math.abs(aspectRatio - accumulator.width / accumulator.height);
        const currentDistance = Math.abs(aspectRatio - currentValue.width / currentValue.height);
        if (currentDistance < shortestDistanceSoFar) {
          accumulator = currentValue;
        }
        return accumulator;
      });
    } else return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants, windowSize]);

  return (
    <div ref={parentRef} style={{ bottom: 0, left: 0, overflow: 'hidden', position: 'absolute', right: 0, top: 0 }}>
      {variant && (
        <ParentCoverer childHeight={variant.height} childWidth={variant.width}>
          <Video
            options={{
              autoplay: true,
              controls: false,
              keyboard: false,
              loop: true,
              muted: true,
              pip: false,
              transcript: false,
            }}
            variants={{ xs: variant }}
          />
        </ParentCoverer>
      )}
    </div>
  );
};
