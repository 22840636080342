import './SliderButton.scss';

import classnames from 'classnames';
import React from 'react';

import { Button } from '../Button';

export interface SliderButtonProps {
  className?: string;
  direction: 'right' | 'left';
  innerRef?: React.RefObject<HTMLButtonElement>;
  onClick?(): void;
  size: 'small' | 'large';
  style?: React.CSSProperties;
}

export const SliderButton = ({ className, direction, innerRef, onClick, size, style }: SliderButtonProps) => {
  return (
    <Button
      className={classnames(
        'slider-button',
        { 'swiper-button-next': direction === 'right' },
        { 'swiper-button-prev': direction === 'left' },
        `swiper-button-${size}`,
        className
      )}
      iconStart={{
        name:
          direction === 'right'
            ? size === 'small'
              ? 'arrow-dx'
              : 'arrow-slider-dx'
            : size === 'small'
            ? 'arrow-sx'
            : 'arrow-slider-sx',
        size: size === 'large' ? 30 : 16,
      }}
      innerRef={innerRef}
      onClick={onClick}
      style={style}
      variant="blank"
    />
  );
};
