import './Footer.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppStaticContext } from './AppContext';
import { Container } from './Container';
import { FooterContentType } from './contentTypes';
import { FooterIcons } from './FooterIcons';
import { NewsletterForm } from './forms/NewsletterForm';
import { t } from './i18n/i18n';
import { ListAFromMenu } from './ListAFromMenu';
import { SocialIcons } from './SocialIcons';

export interface FooterProps {}

export const Footer = (props: FooterProps) => {
  const footerMenu = b2x.useMenu('MENU_FOOTER');
  const footerBottomMenu = b2x.useMenu('MENU_BOTTOM_FOOTER');
  const [currentBreakpoint] = React.useState<string>(b2x.getCurrentBreakpoint());
  const { setFooterCopyrightRef } = useAppStaticContext();

  const footerContent = b2x.useContent<FooterContentType>('FOOTER_CONTENT');
  return (
    <footer className="footer mt-auto">
      <div className="bg-secondary">
        <Container>
          <div className="footer-first-row text-white">
            <b2x.Row className="justify-content-center" gap={0}>
              <b2x.Col className="py-lg-5 pt-3 border-bottom" size={{ lg: 12, xl: 11, xs: 12 }}>
                <b2x.Row className="justify-content-between" gap={0}>
                  <b2x.Col className="footer-icon-col" size={{ lg: 'auto', xs: 12 }}>
                    <FooterIcons />
                  </b2x.Col>
                  <b2x.Col className="social-col d-none d-lg-block" size={{ lg: 4, xs: 12 }}>
                    <SocialIcons />
                    <div className="pt-3">{t('misc.followUs')}</div>
                  </b2x.Col>
                </b2x.Row>
              </b2x.Col>
            </b2x.Row>
          </div>
          <div className="footer-second-row pt-3 py-lg-5 text-white ">
            <b2x.Row className="justify-content-center" gap={0}>
              <b2x.Col size={{ lg: 12, xl: 11, xs: 12 }}>
                <b2x.Row gap={0}>
                  <b2x.Col className="footer-newsletter order-lg-2 order-1" size={{ lg: 4, xs: 12 }}>
                    <div className="px-3 px-lg-0">
                      <h6 className={classnames('fw-bold mb-1', { h4: 'xs sm md'.includes(currentBreakpoint) })}>
                        {t('misc.newsletterTitle')}
                      </h6>
                      <p>{b2x.formatHtml(footerContent?.body.newsletterSubtitle)}</p>
                      <NewsletterForm emailFieldStyle="inputGroup" source="website-footer" />
                    </div>
                    <div className="d-lg-none border-top text-white py-3">
                      <b2x.Row className="align-items-center justify-content-between" gap={0}>
                        <b2x.Col size={{ xs: 6 }}>
                          <div className=" fw-bold ps-3">{t('misc.followUs')}</div>
                        </b2x.Col>
                        <b2x.Col size={{ xs: 6 }}>
                          <div className="d-flex justify-content-end">
                            <SocialIcons />
                          </div>
                        </b2x.Col>
                      </b2x.Row>
                    </div>
                  </b2x.Col>
                  <b2x.Col className="order-2 order-lg-0" size={{ lg: 8, xs: 12 }}>
                    <AccordionFooterMenu id="first-footer-accordion" menu={footerMenu} />
                  </b2x.Col>
                </b2x.Row>
              </b2x.Col>
            </b2x.Row>
          </div>
        </Container>
      </div>
      <Container>
        <div className="footer-third-row extra-small py-lg-3 py-0 " ref={setFooterCopyrightRef}>
          <b2x.Row className="justify-content-between align-items-center" gap={0}>
            <b2x.Col
              className="footer-payment-icons d-flex align-items-center order-lg-2 py-3 py-lg-0 mb-3 mb-lg-0 px-3 px-lg-0"
              size={{ lg: 'auto', xs: 12 }}
            >
              <b2x.PaymentIcons />
            </b2x.Col>
            <b2x.Col className="mb-3 mb-lg-0">
              <div className="d-none d-lg-block text-uppercase ">
                {footerBottomMenu && <ListAFromMenu menu={footerBottomMenu} textUppercase />}
              </div>
              {footerContent && <div className="px-3 px-lg-0">{b2x.formatHtml(footerContent.body.copyright)}</div>}
            </b2x.Col>
          </b2x.Row>
        </div>
      </Container>
    </footer>
  );
};

interface AccordionFooterMenuProps {
  id: string;
  menu?: b2x.MenuApiDto;
}
const AccordionFooterMenu = ({ id, menu }: AccordionFooterMenuProps) => {
  const { session } = b2x.useAppContext();
  const { showAccountOffcanvas } = useAppStaticContext();
  return (
    <b2x.Accordion
      columnLayoutFrom="lg"
      id={id}
      itemBodyClassName="small pb-3"
      itemButtonClassName="bg-transparent text-white fw-bold mb-lg-1 p-3 p-lg-0"
      itemClassName="bg-transparent"
    >
      {menu?.children.map(
        (column) =>
          column.label && (
            <b2x.AccordionItem id={`accordionItem-${column.id}`} key={column.code} title={column.label}>
              {column.children.map((li) =>
                li.code === 'SITE_CCN_AREA' ? (
                  session?.userLogged ? (
                    li.children.map(
                      (subLi) =>
                        subLi.link && (
                          <div key={subLi.code}>
                            <b2x.router.Link className="text-reset text-decoration-none d-block py-1" to={subLi.link}>
                              {subLi.label}
                            </b2x.router.Link>
                          </div>
                        )
                    )
                  ) : (
                    <div key={li.code}>
                      <b2x.BlankButton className="d-block py-1" onClick={showAccountOffcanvas}>
                        {t('account.login')}
                      </b2x.BlankButton>
                    </div>
                  )
                ) : (
                  li.link && (
                    <div key={li.code}>
                      {li.image ? (
                        <b2x.router.Link className={'mt-2 d-inline-block'} to={li.link}>
                          <b2x.Image {...li.image} fluid width={100} />
                        </b2x.router.Link>
                      ) : (
                        <b2x.router.Link className="text-reset text-decoration-none d-block py-1" to={li.link}>
                          {li.label}
                        </b2x.router.Link>
                      )}
                    </div>
                  )
                )
              )}
            </b2x.AccordionItem>
          )
      )}
    </b2x.Accordion>
  );
};
