import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { Checkbox } from './form/fields/RadioCheck';
import { SearchFilter } from './form/SearchForm';
import { t } from './i18n/i18n';
import { SearchFiltersProps } from './SearchFilters';
import { renderUI } from './util';

export const SearchFiltersA = ({ excludeFacets, fieldsHelper }: SearchFiltersProps) => {
  const filteredFilters = fieldsHelper.filters.filter(({ filter }) => !excludeFacets?.includes(filter.code));

  return renderUI({
    bs5: (
      <div className="search-filters">
        <ul className="list-unstyled m-0">
          {fieldsHelper.activeFilters.map(
            (activeFilter) =>
              activeFilter.fromSimpleSearch && (
                <li className="search-result p-3" key={activeFilter.filter.code}>
                  <h6 className="d-none d-lg-block text-primary mb-0">{t('misc.youSearchedFor')}</h6>
                  <ul className="list-unstyled">
                    <li>
                      <span className="d-inline-block d-lg-none me-3">{t('misc.youSearchedFor')}</span>
                      <div className="d-grid">
                        <Button
                          className="btn-sm extra-small ps-0 pe-1 pb-0"
                          iconEnd={{ name: 'delete', size: 14 }}
                          justifyContent="between"
                          label={activeFilter.filter.name}
                          onClick={activeFilter.handleClick}
                          variant={'outline'}
                        />
                      </div>
                    </li>
                  </ul>
                </li>
              )
          )}
          {filteredFilters.map((filter, filterIndex) => (
            <li
              className={classnames(
                { 'py-3': filter.filter.type !== 'CATEGORY' },
                { 'border-top other-search-filter': filterIndex > 0 },
                'd-none d-lg-block',
                `filter-type-${filter.filter.type?.toLocaleLowerCase()}`
              )}
              key={filter.checkbox.id}
            >
              {filter.filter.type === 'CATEGORY' ? (
                <>
                  <ul className="list-unstyled">
                    {filter.children.map((filterChildren, filterChildrenIndex) => (
                      <li
                        className={classnames({ 'border-top': filterChildrenIndex > 0 })}
                        key={filterChildren.checkbox.id}
                      >
                        {filterChildren.children.length > 0 ? (
                          <FilterWithChildren filter={filterChildren} mainCategory />
                        ) : (
                          <Checkbox {...filterChildren.checkbox} className="text-primary py-1 my-2" />
                        )}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  <div className="text-primary mb-2 fw-bold">{filter.filter.name}</div>
                  <ul className="list-unstyled">
                    {filter.children.map((filterChildren) => (
                      <li key={filterChildren.checkbox.id}>
                        <Checkbox {...filterChildren.checkbox} className="py-1" />
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    ),
  });
};

interface FilterWithChildrenProps {
  filter: SearchFilter;
  mainCategory?: boolean;
}

const FilterWithChildren = ({ filter, mainCategory }: FilterWithChildrenProps) => {
  const accordionButtonRef = React.useRef<HTMLButtonElement>(null);
  const [accordionCollapsed, setAccordionCollapsed] = React.useState<boolean>();

  React.useEffect(() => {
    setAccordionCollapsed(filter.filter.checkType === 'NOT_CHECKED' ? true : false);
  }, [filter.filter.checkType]);

  const accordionButtonClick = React.useCallback(() => {
    if (accordionButtonRef.current?.classList.contains('collapsed')) {
      setAccordionCollapsed(true);
    } else {
      setAccordionCollapsed(false);
    }
  }, []);

  return renderUI({
    bs5: (
      <div className="accordion" id={`accordion-${filter.checkbox.id}`}>
        <div className="accordion-item border-0">
          <div
            className={classnames('accordion-header', { 'main-category': mainCategory })}
            id={`heading-${filter.checkbox.id}`}
          >
            <div className={classnames({ 'py-2': mainCategory }, 'd-flex justify-content-between')}>
              <div>
                <Checkbox
                  {...filter.checkbox}
                  className={classnames({ 'text-primary ': mainCategory }, { 'small pb-2': !mainCategory }, 'py-1')}
                />
              </div>
              <div className="d-flex align-items-center pe-3">
                <Button
                  aria-controls={`collapse-${filter.checkbox.id}`}
                  aria-expanded="true"
                  className={classnames(
                    'btn-sm bg-transparent text-black p-0 border-0 shadow-none',
                    {
                      collapsed: filter.filter.checkType === 'NOT_CHECKED',
                    },
                    { 'pe-1': !mainCategory }
                  )}
                  data-bs-target={`#collapse-${filter.checkbox.id}`}
                  data-bs-toggle="collapse"
                  iconStart={{
                    name: accordionCollapsed
                      ? mainCategory
                        ? 'plus'
                        : 'small-arrow-down'
                      : mainCategory
                      ? 'minus'
                      : 'small-arrow-up',
                    size: mainCategory ? 20 : 14,
                  }}
                  innerRef={accordionButtonRef}
                  onClick={accordionButtonClick}
                  type="button"
                />
              </div>
            </div>
          </div>
          <div
            aria-labelledby={`heading-${filter.checkbox.id}`}
            className={classnames(
              'accordion-collapse collapse',
              { show: filter.filter.checkType === 'CHECKED' },
              { show: filter.filter.checkType === 'CHILD_CHECKED' }
            )}
            data-bs-parent={`#accordion-${filter.checkbox.id}`}
            id={`collapse-${filter.checkbox.id}`}
          >
            <div className="accordion-body p-0 pb-2">
              <ul className="list-unstyled ps-3">
                {filter.children.map((filterItem) => (
                  <li key={filterItem.checkbox.id}>
                    {filterItem.children.length > 0 ? (
                      <FilterWithChildren filter={filterItem} />
                    ) : (
                      <Checkbox {...filterItem.checkbox} className="small py-1" />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    ),
  });
};
