import { VideoContentSectionDeprecated } from './contentTypes';
import { DeprecatedVideo } from './DeprecatedVideo';

export interface DeprecatedVideoFromContentProps extends VideoContentSectionDeprecated {
  autoplay?: boolean;
  controls?: boolean;
  loop?: boolean;
  onLoad?(): void;
}

export const DeprecatedVideoFromContent = ({
  autoplay,
  controls,
  loop,
  onLoad,
  src,
}: DeprecatedVideoFromContentProps) => {
  return (
    <>
      {src?.xs?.video !== undefined && (
        <DeprecatedVideo
          autoplay={autoplay}
          controls={controls}
          loop={loop}
          onLoad={onLoad}
          src={{
            lg: src.lg?.video ? { preview: src.lg.preview?.url, video: src.lg.video } : undefined,
            md: src.md?.video ? { preview: src.md.preview?.url, video: src.md.video } : undefined,
            sm: src.sm?.video ? { preview: src.sm.preview?.url, video: src.sm.video } : undefined,
            xl: src.xl?.video ? { preview: src.xl.preview?.url, video: src.xl.video } : undefined,
            xs: { preview: src.xs.preview?.url, video: src.xs.video },
            xxl: src.xxl?.video ? { preview: src.xxl.preview?.url, video: src.xxl.video } : undefined,
          }}
        />
      )}
    </>
  );
};
