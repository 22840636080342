import './Button.scss';

import { b2x } from '@b2x/react/src';

import { IconName, IconSize } from './Icon';

export type ButtonVariant = b2x.CommonButtonVariant | 'primary' | 'secondary' | 'outline-primary' | 'outline-secondary';

export type ButtonProps = b2x.ButtonProps<ButtonVariant, IconName, IconSize>;

export const Button = (props: ButtonProps) => <b2x.Button<ButtonVariant, IconName, IconSize> {...props} />;
