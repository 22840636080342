export interface DivAsButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick?(): void;
}

export const DivAsButton = ({ onClick, ...otherProps }: DivAsButtonProps) => {
  return (
    <div {...otherProps} onClick={onClick} onKeyPress={onClick} role={onClick ? 'button' : undefined}>
      {otherProps.children}
    </div>
  );
};
