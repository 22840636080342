/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-elements */
//import { DeprecatedCta } from './Cta';
import { FloatingActionButton } from './FloatingActionButton';

export interface CookieFABButtonProps {
  background?: string;
  className?: string;
  color?: string;
  size?: number;
}

export const CookieFABButton = ({ background = '#000', color = '#FFFFFF' }: CookieFABButtonProps) => {
  return (
    <FloatingActionButton>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <a
        className={'cookie-fab-button'}
        // eslint-disable-next-line no-script-url
        // href={'javascript: Cookiebot.show()'}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => {
          Cookiebot.show();
        }}
        style={{
          background: background,
        }}
      >
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <g fillRule="evenodd">
            <circle cx="12" cy="12" r="12"></circle>
            <path
              d="M15.094 13.978c-1.146 0-1.946-.813-1.946-1.978s.8-1.978 1.946-1.978c1.145 0 1.945.813 1.945 1.978s-.8 1.978-1.945 1.978M9.07 10.022h3.883l-.094.09c-.537.515-.844 1.203-.844 1.888 0 1.738 1.294 3 3.079 3 1.786 0 3.082-1.262 3.082-3s-1.296-3-3.082-3H9.079C7.295 9 6 10.262 6 12s1.295 3 3.079 3h2.144v-1.022H9.07c-1.136 0-1.932-.813-1.937-1.978 0-1.146.815-1.978 1.937-1.978"
              fill={color}
            ></path>
          </g>
        </svg>
      </a>
      {/* <DeprecatedCta
        className={'cookie-fab-button'}
        cta={{ to: { code: 'SITE_LGL_COOKIE' } }}
        style={{
          background: background,
        }}
      >
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <g fillRule="evenodd">
            <circle cx="12" cy="12" r="12"></circle>
            <path
              d="M15.094 13.978c-1.146 0-1.946-.813-1.946-1.978s.8-1.978 1.946-1.978c1.145 0 1.945.813 1.945 1.978s-.8 1.978-1.945 1.978M9.07 10.022h3.883l-.094.09c-.537.515-.844 1.203-.844 1.888 0 1.738 1.294 3 3.079 3 1.786 0 3.082-1.262 3.082-3s-1.296-3-3.082-3H9.079C7.295 9 6 10.262 6 12s1.295 3 3.079 3h2.144v-1.022H9.07c-1.136 0-1.932-.813-1.937-1.978 0-1.146.815-1.978 1.937-1.978"
              fill={color}
            ></path>
          </g>
        </svg>
      </DeprecatedCta> */}
    </FloatingActionButton>
  );
};
