import './PriceBlock.scss';

import { b2x } from '@b2x/react/src';

export type PriceBlockProps = b2x.PriceBlockProps;

export const PriceBlock = ({ alignItems, classNames, ...otherProps }: PriceBlockProps) => {
  return (
    <b2x.PriceBlock
      {...otherProps}
      alignItems={alignItems ? alignItems : 'center'}
      classNames={{
        crossedOutPrice: `${classNames?.crossedOutPrice}`,
        discountPercentage: `px-2 fw-bold bg-promo text-white d-flex align-items-center ${classNames?.discountPercentage}`,
        price: `fw-bold text-black ${classNames?.price}`,
        specialPrice: `fw-bold text-promo ${classNames?.specialPrice}`,
      }}
    />
  );
};
