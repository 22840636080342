import { default as classNames } from 'classnames';
import React from 'react';

import { Button } from '../Button';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { GoogleMapInstance } from '../useGoogleMap';
import { useModals } from '../useModals';
import { FiltersToggle } from './FiltersToggle';
import { SearchForm } from './SearchForm';
import { useStoreLocatorContext } from './StoreLocatorContext';

export interface ControlsProps {
  className?: string;
  currentPosition: GeolocationPosition | undefined;
  filterCount: number;
  googleMap: GoogleMapInstance;
  toggleFiltersPanel(): void;
}

export const Controls = ({ className, currentPosition, filterCount, googleMap, toggleFiltersPanel }: ControlsProps) => {
  const { filtersRootDirectoryCode, iconMyLocation, iconZoomIn, iconZoomOut, iconsSize } = useStoreLocatorContext();
  const { showModal } = useModals();

  const handleGeolocatorButtonClick = React.useCallback(() => {
    if (currentPosition) {
      googleMap.panTo({ lat: currentPosition.coords.latitude, lng: currentPosition.coords.longitude });
      googleMap.setZoom(15);
    } else {
      showModal({
        children: t('storeLocator.modal.geolocationError.content'),
        title: t('storeLocator.modal.geolocationError.title'),
      });
    }
  }, [currentPosition, googleMap, showModal]);

  const handleZoomIn = React.useCallback(() => {
    googleMap.setZoom(googleMap.getZoom() + 1);
  }, [googleMap]);

  const handleZoomOut = React.useCallback(() => {
    googleMap.setZoom(googleMap.getZoom() - 1);
  }, [googleMap]);

  return (
    <Div className={classNames('controls', className)} style={{ pointerEvents: 'none' }}>
      <Div className="w-100" display={{ lg: 'none', xs: 'flex' }} gap={2}>
        <Div style={{ flex: 1, pointerEvents: 'all' }}>
          <SearchForm googleMap={googleMap} inputClassName="on-map-item with-color" />
        </Div>
        {filtersRootDirectoryCode && (
          <Div style={{ pointerEvents: 'all' }}>
            <FiltersToggle
              className="on-map-item with-color"
              filterCount={filterCount}
              toggleFiltersPanel={toggleFiltersPanel}
            />
          </Div>
        )}
      </Div>
      <Div marginTop={{ lg: 0, xs: 3 }} style={{ float: 'right' }}>
        <Div display="flex" flexDirection="column" gap={2}>
          <Button
            className="on-map-item with-color"
            iconStart={{ name: iconMyLocation, size: iconsSize }}
            onClick={handleGeolocatorButtonClick}
            style={{ pointerEvents: 'all' }}
            variant="plain"
          />
          <Button
            className="on-map-item with-color"
            iconStart={{ name: iconZoomIn, size: iconsSize }}
            onClick={handleZoomIn}
            style={{ pointerEvents: 'all' }}
            variant="plain"
          />
          <Button
            className="on-map-item with-color"
            iconStart={{ name: iconZoomOut, size: iconsSize }}
            onClick={handleZoomOut}
            style={{ pointerEvents: 'all' }}
            variant="plain"
          />
        </Div>
      </Div>
    </Div>
  );
};
