import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { analytics } from '../analytics/analytics';
import { useAppStaticContext } from '../AppContext';
import { Button } from '../Button';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { useNavigate, useSearchParams } from '../router/router';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { TextInput, TextInputProps } from './fields/Input';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { SimpleSearchFormA } from './SimpleSearchFormA';

export interface SimpleSearchFormProps extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  submitIconName?: string;
}

interface FormValues {
  text: formikString;
}

type ValidationSchema = {
  text: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  text: { formGroup: FormGroupProps; textInput: TextInputProps };
}

export const SimpleSearchFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  ...otherProps
}: SimpleSearchFormProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setMainCategory } = useAppStaticContext();

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      text: getInitialString(searchParams.get('simpleSearch') ?? undefined),
      ...initialValues,
    }),
    [initialValues, searchParams]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      text: yup.string(),
    }),
    []
  );

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      setMainCategory(undefined);
      navigate(`search?simpleSearch=${values.text}`);
      analytics.events.search('EVENT_ID', values.text);
      onSuccess && onSuccess();
      return Promise.resolve();
    },
    [navigate, onSuccess, setMainCategory]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('simple-search-form', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: 'Reset',
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: 'Submit',
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          text: {
            formGroup: { label: t('form.simpleSearchForm.text.label'), names: ['text'] },
            textInput: { name: 'text', placeholder: t('form.simpleSearchForm.text.placeholder') },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal, onSuccess })
        ) : (
          <>
            <FormGroup {...fieldsHelper.text.formGroup}>
              <TextInput {...fieldsHelper.text.textInput} />
            </FormGroup>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type SimpleSearchFormVariants = 'A';

const SimpleSearchFormController = (props: PropsWithCustomComponentWithoutChildren<SimpleSearchFormProps>) => (
  <VariantsController<SimpleSearchFormProps, SimpleSearchFormVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: SimpleSearchFormA },
      defaultComponent: SimpleSearchFormHelper,
      name: 'SimpleSearchForm',
    }}
  />
);
export { SimpleSearchFormController as SimpleSearchForm };
