import React from 'react';
import { SwiperOptions } from 'swiper/types/swiper-options';

import { SwiperContentSection } from './contentTypes';
import { Swiper, SwiperProps } from './Swiper';

export interface SwiperFromContentProps<T>
  extends Omit<SwiperProps, 'autoplay' | 'effect' | 'loop' | 'speed'>, // gestisco queste prop da contenuto
    Omit<SwiperContentSection<T>, 'slides'> {}

export const SwiperFromContent = <T,>({ options, ...otherProps }: SwiperFromContentProps<T>) => {
  const swiperOptionsFromContent = React.useMemo(
    (): SwiperOptions => ({
      autoplay: options?.autoplay?.enabled
        ? {
            delay: options.autoplay.delay,
            disableOnInteraction: options.autoplay.disableOnInteraction,
            pauseOnMouseEnter: options.autoplay.pauseOnMouseEnter,
            stopOnLastSlide: options.autoplay.stopOnLastSlide,
          }
        : undefined,
      effect: options?.effect,
    }),
    [
      options?.autoplay?.delay,
      options?.autoplay?.disableOnInteraction,
      options?.autoplay?.enabled,
      options?.autoplay?.pauseOnMouseEnter,
      options?.autoplay?.stopOnLastSlide,
      options?.effect,
    ]
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <Swiper {...otherProps} {...(swiperOptionsFromContent as any)} />;
};
