export const Icomoon = () => (
  <svg
    aria-hidden="true"
    style={{ height: 0, overflow: 'hidden', position: 'absolute', width: 0 }}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <symbol id="icon-filter-map" viewBox="0 0 32 32">
        <path d="M21.554 30c0.011 0.072 0.017 0.154 0.017 0.238 0 0.487-0.204 0.926-0.532 1.237l-0.001 0.001c-0.32 0.321-0.764 0.52-1.253 0.52s-0.933-0.199-1.253-0.52l-7.13-7.124c-0.327-0.32-0.53-0.765-0.53-1.258 0-0.077 0.005-0.153 0.015-0.227l-0.001 0.009v-9.1l-8.512-10.896c-0.231-0.297-0.37-0.675-0.37-1.086 0-0.563 0.262-1.065 0.671-1.391l0.004-0.003c0.3-0.24 0.683-0.389 1.099-0.4l0.002-0h24.884c0.419 0.011 0.802 0.16 1.106 0.403l-0.004-0.003c0.414 0.329 0.677 0.832 0.677 1.396 0 0.413-0.141 0.794-0.378 1.096l0.003-0.004-8.514 10.888v16.224zM7.402 3.556l7.040 9v9.812l3.56 3.548v-13.384l7.040-8.976z"></path>
      </symbol>
      <symbol id="icon-geolocator-map" viewBox="0 0 32 32">
        <path d="M15.746 3.047c3.363 0.010 6.086 2.733 6.096 6.095v0.001c0 3.2-3.2 8.38-6.096 12.037-2.894-3.81-6.094-8.837-6.094-12.037 0.010-3.363 2.733-6.086 6.095-6.096h0.001zM15.748 0c-5.044 0.014-9.13 4.099-9.145 9.142v0.001c0 6.857 9.143 16.763 9.143 16.763s9.143-10.059 9.143-16.763c-0.015-5.043-4.1-9.128-9.142-9.143h-0.001zM15.746 6.096c-1.683 0-3.047 1.364-3.047 3.047s1.364 3.047 3.047 3.047c1.683 0 3.047-1.364 3.047-3.047v0c-0.005-1.681-1.366-3.042-3.047-3.047h-0zM27.936 25.906c0 3.353-5.486 6.096-12.19 6.096s-12.19-2.745-12.19-6.098c0-1.98 1.829-3.657 4.724-4.876l0.914 1.371c-1.524 0.763-2.59 1.676-2.59 2.743 0 2.133 4.114 3.81 9.143 3.81s9.143-1.675 9.143-3.808c0-1.067-1.067-1.98-2.743-2.743l0.914-1.371c3.049 1.218 4.876 2.894 4.876 4.875z"></path>
      </symbol>
      <symbol id="icon-locator-map" viewBox="0 0 32 32">
        <path d="M15.644 7.2c2.209 0 4 1.791 4 4s-1.791 4-4 4c-2.209 0-4-1.791-4-4v0c0-2.209 1.791-4 4-4v0zM15.644 0c6.186 0 11.2 5.014 11.2 11.2v0c0 8.4-11.2 20.8-11.2 20.8s-11.2-12.4-11.2-20.8c0-6.186 5.014-11.2 11.2-11.2v0zM15.644 3.2c-4.418 0-8 3.582-8 8v0c0 1.6 0 4.8 8 15.536 8-10.736 8-13.936 8-15.536 0-4.418-3.582-8-8-8v0z"></path>
      </symbol>
      <symbol id="icon-navigator" viewBox="0 0 32 32">
        <path d="M31.035 10.057l-10.057 10.057-2.578-2.578 5.632-5.65h-10.368c-0.011-0-0.023-0-0.036-0-4.525 0-8.194 3.668-8.194 8.194 0 0.013 0 0.025 0 0.038v-0.002 11.884h-3.657v-11.886c0-6.565 5.322-11.886 11.886-11.886v0h10.368l-5.652-5.65 2.597-2.578z"></path>
      </symbol>
      <symbol id="icon-sharing" viewBox="0 0 32 32">
        <path d="M19.232 26.181c0-0.001 0-0.003 0-0.004 0-0.563 0.081-1.108 0.231-1.623l-0.010 0.041-7.719-4.507c-1.056 1.069-2.522 1.732-4.142 1.732-3.215 0-5.82-2.606-5.82-5.82s2.606-5.82 5.82-5.82c1.621 0 3.086 0.662 4.142 1.731l0.001 0.001 7.716-4.51c-0.142-0.479-0.223-1.028-0.223-1.597 0-3.221 2.611-5.833 5.833-5.833s5.833 2.611 5.833 5.833c0 3.221-2.611 5.833-5.833 5.833-1.618 0-3.083-0.659-4.139-1.723l-0-0-7.723 4.507c0.139 0.474 0.218 1.018 0.218 1.581s-0.080 1.107-0.228 1.623l0.010-0.041 7.726 4.503c1.053-1.059 2.511-1.714 4.123-1.714 3.212 0 5.815 2.604 5.815 5.815s-2.604 5.815-5.815 5.815c-3.212 0-5.815-2.604-5.815-5.815 0-0.004 0-0.009 0-0.013v0.001zM22.142 26.181c0 0 0 0 0 0.001 0 1.606 1.302 2.908 2.908 2.908s2.908-1.302 2.908-2.908c0-1.606-1.302-2.908-2.908-2.908-1.006 0-1.892 0.511-2.415 1.287l-0.007 0.010c-0.026 0.060-0.050 0.109-0.077 0.156l0.004-0.008c-0.030 0.050-0.062 0.098-0.096 0.144-0.2 0.383-0.318 0.838-0.318 1.319v0zM4.686 16c0 0.001 0 0.001 0 0.002 0 1.606 1.302 2.908 2.908 2.908 1.042 0 1.956-0.548 2.469-1.371l0.007-0.012c0.011-0.018 0.023-0.041 0.034-0.062l0.037-0.060c0.227-0.404 0.361-0.887 0.361-1.402s-0.134-0.998-0.369-1.416l0.008 0.015c-0.014-0.021-0.027-0.041-0.039-0.064l-0.036-0.066c-0.521-0.832-1.433-1.377-2.473-1.377-1.606 0-2.907 1.301-2.908 2.906v0zM25.049 8.729c0.001 0 0.002 0 0.003 0 1.607 0 2.91-1.303 2.91-2.91s-1.303-2.91-2.91-2.91c-1.607 0-2.91 1.303-2.91 2.91 0 0.513 0.133 0.995 0.366 1.414l-0.008-0.015 0.041 0.064c0.011 0.018 0.023 0.040 0.034 0.063l0.002 0.005c0.522 0.832 1.433 1.378 2.473 1.38h0z"></path>
      </symbol>
      <symbol id="icon-voice-recorder" viewBox="0 0 32 32">
        <path d="M16.235 0c2.79 0 5.052 2.262 5.052 5.052v0 10.105c0 2.79-2.262 5.052-5.052 5.052s-5.052-2.262-5.052-5.052v0-10.105c0-2.79 2.262-5.052 5.052-5.052v0zM28.025 15.157c-0.003 5.916-4.36 10.814-10.040 11.663l-0.065 0.008v5.172h-3.371v-5.17c-5.746-0.857-10.103-5.756-10.105-11.673v-0h3.369c0 4.651 3.77 8.421 8.421 8.421s8.421-3.77 8.421-8.421v0z"></path>
      </symbol>
      <symbol id="icon-voice-recorder-on" viewBox="0 0 32 32">
        <path d="M16 32c-8.836-0.001-15.998-7.164-15.998-16 0-4.417 1.79-8.417 4.685-11.312l-0 0c2.886-2.841 6.849-4.595 11.222-4.595 8.837 0 16 7.163 16 16 0 4.372-1.754 8.335-4.596 11.223l0.002-0.002c-2.879 2.895-6.866 4.686-11.27 4.686-0.015 0-0.031-0-0.046-0h0.002zM5.819 16c0.005 5.109 3.766 9.338 8.671 10.073l0.056 0.007v4.466h2.908v-4.466c4.962-0.74 8.725-4.97 8.727-10.080v-0h-2.908c0 4.017-3.256 7.273-7.273 7.273s-7.273-3.256-7.273-7.273v0zM16 2.91c-2.408 0.003-4.359 1.953-4.364 4.36v8.729c0 2.41 1.954 4.364 4.364 4.364s4.364-1.954 4.364-4.364v0-8.729c-0.003-2.408-1.955-4.359-4.362-4.361h-0z"></path>
      </symbol>
      <symbol id="icon-award" viewBox="0 0 23 32">
        <path d="M22.44 11.58c0-1.075 0.24-2.135 0.71-3.105-1.805-1.22-2.945-3.2-3.105-5.37-1.070-0.080-2.11-0.395-3.040-0.93-0.925-0.54-1.72-1.285-2.32-2.175-0.97 0.47-2.035 0.715-3.115 0.715-1.075 0-2.135-0.245-3.1-0.71-0.605 0.89-1.4 1.63-2.33 2.17-0.93 0.535-1.97 0.855-3.040 0.935-0.080 1.070-0.4 2.11-0.935 3.045-0.54 0.93-1.275 1.725-2.165 2.33 0.47 0.965 0.715 2.025 0.715 3.1s-0.245 2.135-0.715 3.105c0.89 0.605 1.63 1.4 2.165 2.33s0.855 1.975 0.935 3.045c2.17 0.145 4.15 1.295 5.36 3.105 1.965-0.955 4.26-0.955 6.23 0 1.21-1.805 3.19-2.955 5.36-3.105 0.080-1.070 0.4-2.11 0.935-3.040 0.54-0.93 1.285-1.72 2.175-2.32-0.475-0.97-0.72-2.035-0.72-3.115zM14.89 12.845l0.79 4.57-4.105-2.155-4.105 2.16 0.785-4.57-3.33-3.24 4.595-0.67 2.055-4.155 2.050 4.155 4.595 0.67-3.33 3.235z"></path>
        <path d="M7.845 25.46l-0.875-1.285c-0.445-0.665-1.035-1.225-1.73-1.625-0.25-0.135-0.51-0.245-0.775-0.34l-2.2 8.21 3.98-3.115 1.89 4.69 2.020-7.53c-0.31 0.080-0.615 0.185-0.905 0.32l-1.4 0.675z"></path>
        <path d="M17.905 22.55c-0.69 0.41-1.28 0.965-1.73 1.63l-0.875 1.275-1.395-0.67c-0.29-0.13-0.595-0.24-0.905-0.32l2.020 7.53 1.89-4.685 3.98 3.115-2.205-8.215c-0.27 0.095-0.53 0.205-0.78 0.34z"></path>
      </symbol>
      <symbol id="icon-quote" viewBox="0 0 35 32">
        <path d="M23.232 22.678c-2.247-3.233-3.371-6.903-3.371-11.004 0-3.675 0.88-6.543 2.64-8.596 1.766-2.054 3.847-3.078 6.244-3.078 2.015 0 3.609 0.637 4.794 1.915s1.771 2.751 1.771 4.412-0.62 3.022-1.86 4.179-2.712 1.744-4.412 1.744c-0.426 0-0.908-0.078-1.45-0.232s-0.891-0.232-1.046-0.232c-0.891 0-1.334 0.753-1.334 2.264 0 2.209 0.637 4.888 1.915 8.043s4.046 6.299 8.303 9.438l-0.349 0.465c-5.652-2.984-9.604-6.089-11.846-9.322zM3.371 22.678c-2.247-3.233-3.371-6.903-3.371-11.004 0-3.714 0.891-6.593 2.674-8.624 1.777-2.031 3.853-3.050 6.211-3.050 2.015 0 3.609 0.637 4.794 1.915s1.771 2.751 1.771 4.412-0.62 3.022-1.86 4.179-2.712 1.744-4.412 1.744c-0.426 0-0.908-0.078-1.45-0.232s-0.891-0.232-1.046-0.232c-0.814 0-1.218 0.753-1.218 2.264 0 2.984 0.852 6.050 2.557 9.205s4.257 5.912 7.667 8.275l-0.465 0.465c-5.652-2.984-9.604-6.089-11.846-9.322z"></path>
      </symbol>
      <symbol id="icon-one-column-display" viewBox="0 0 32 32">
        <path d="M0.25 0.26h31.5v31.485h-31.5v-31.485z"></path>
      </symbol>
      <symbol id="icon-two-column-display" viewBox="0 0 32 32">
        <path d="M0.25 0.25h14v31.5h-14v-31.5z"></path>
        <path d="M17.75 0.25h14v31.5h-14v-31.5z"></path>
      </symbol>
      <symbol id="icon-locator" viewBox="0 0 24 32">
        <path d="M12.001 0c-6.625 0.008-11.993 5.376-12.001 12v0.001c0.066 3.079 1.152 5.893 2.933 8.129l-0.021-0.027 8.001 11.334c0.245 0.342 0.641 0.563 1.089 0.563s0.844-0.221 1.086-0.559l0.003-0.004 8.020-11.363c1.75-2.202 2.828-5.006 2.89-8.060l0-0.014c-0.009-6.623-5.376-11.99-11.998-11.999h-0.001zM18.901 18.58l-6.9 9.776-6.89-9.76c-1.47-1.785-2.381-4.077-2.442-6.58l-0-0.013c0-5.155 4.179-9.334 9.334-9.334s9.334 4.179 9.334 9.334v0c-0.061 2.51-0.969 4.797-2.449 6.596l0.015-0.019z"></path>
        <path d="M11.999 5.999c-3.313 0-5.999 2.686-5.999 5.999s2.686 5.999 5.999 5.999c3.313 0 5.999-2.686 5.999-5.999v0c0-3.313-2.686-5.999-5.999-5.999v0zM11.999 15.332c-1.841 0-3.333-1.492-3.333-3.333s1.492-3.333 3.333-3.333c1.841 0 3.333 1.492 3.333 3.333v0c0 1.841-1.492 3.333-3.333 3.333v0z"></path>
      </symbol>
      <symbol id="icon-menu" viewBox="0 0 32 32">
        <path d="M30.905 6.905h-29.81c-0.605 0-1.095-0.49-1.095-1.095s0.49-1.095 1.095-1.095h29.81c0.605 0 1.095 0.49 1.095 1.095s-0.49 1.095-1.095 1.095z"></path>
        <path d="M30.905 17.42h-29.81c-0.605 0-1.095-0.49-1.095-1.095s0.49-1.095 1.095-1.095h29.81c0.605 0 1.095 0.49 1.095 1.095s-0.49 1.095-1.095 1.095z"></path>
        <path d="M30.905 27.29h-29.81c-0.605 0-1.095-0.49-1.095-1.095s0.49-1.095 1.095-1.095h29.81c0.605 0 1.095 0.49 1.095 1.095s-0.49 1.095-1.095 1.095z"></path>
      </symbol>
      <symbol id="icon-delete-compare" viewBox="0 0 32 32">
        <path d="M16 32c-8.825 0-16-7.18-16-16s7.175-16 16-16 16 7.18 16 16-7.175 16-16 16zM16 1.23c-8.145 0-14.77 6.625-14.77 14.77s6.625 14.77 14.77 14.77 14.77-6.625 14.77-14.77-6.625-14.77-14.77-14.77z"></path>
        <path d="M20.72 19.16l-3.71-3.71 3.71-3.71c0.41-0.41 0.41-1.070 0-1.475s-1.070-0.41-1.475 0l-3.71 3.71-3.71-3.71c-0.41-0.41-1.070-0.41-1.475 0s-0.41 1.070 0 1.475l3.71 3.71-3.705 3.71c-0.41 0.41-0.41 1.070 0 1.475s1.070 0.41 1.475 0v0l3.71-3.71 3.705 3.705c0.41 0.41 1.070 0.41 1.475 0s0.41-1.070 0-1.475v0z"></path>
      </symbol>
      <symbol id="icon-wishlist-full" viewBox="0 0 32 32">
        <path d="M23.19 1.32c-2.765 0.025-5.385 1.235-7.19 3.33-1.81-2.095-4.435-3.305-7.2-3.33-4.805-0.050-8.745 3.805-8.8 8.61 0 0.065 0 0.13 0 0.19 0 6.035 5.44 10.975 13.68 18.45l2.32 2.11 2.32-2.11c8.24-7.47 13.68-12.42 13.68-18.445 0.055-4.805-3.8-8.745-8.61-8.8-0.065 0-0.135 0-0.2 0z"></path>
      </symbol>
      <symbol id="icon-account" viewBox="0 0 32 32">
        <path d="M16 3.8c2.32 0 4.2 1.88 4.2 4.2s-1.88 4.2-4.2 4.2-4.2-1.88-4.2-4.2v0c0-2.32 1.88-4.2 4.2-4.2zM16 21.8c5.94 0 12.2 2.92 12.2 4.2v2.2h-24.4v-2.2c0-1.28 6.26-4.2 12.2-4.2zM16 0c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zM16 18c-5.34 0-16 2.68-16 8v6h32v-6c0-5.32-10.66-8-16-8z"></path>
      </symbol>
      <symbol id="icon-arrow-down" viewBox="0 0 32 32">
        <path d="M15.635 6.905c0.42 0 0.825 0.17 1.12 0.47l14.78 15.055c0.605 0.62 0.595 1.61-0.020 2.215-0.625 0.61-1.615 0.595-2.215-0.020l-13.635-13.885-12.97 13.86c-0.595 0.63-1.585 0.66-2.215 0.075-0.63-0.59-0.665-1.585-0.075-2.215l14.085-15.060c0.29-0.31 0.7-0.49 1.125-0.495h0.020z"></path>
      </symbol>
      <symbol id="icon-arrow-dx" viewBox="0 0 32 32">
        <path d="M8.51-0.070c0.37 0 0.74 0.135 1.030 0.405l15.12 14.14c0.3 0.28 0.475 0.675 0.48 1.085 0 0.41-0.16 0.81-0.45 1.095l-15.12 14.84c-0.595 0.585-1.55 0.575-2.135-0.020s-0.575-1.55 0.020-2.135l13.995-13.74-13.97-13.060c-0.61-0.57-0.64-1.525-0.070-2.135 0.3-0.32 0.7-0.48 1.1-0.48z"></path>
      </symbol>
      <symbol id="icon-arrow-slider-dx" viewBox="0 0 32 32">
        <path d="M8.115 32l-0.23-0.23 15.77-15.77-15.77-15.77 0.23-0.23 16 16-16 16z"></path>
      </symbol>
      <symbol id="icon-arrow-slider-sx" viewBox="0 0 32 32">
        <path d="M23.885 32l0.23-0.23-15.77-15.77 15.77-15.77-0.23-0.23-16 16 16 16z"></path>
      </symbol>
      <symbol id="icon-arrow-sx" viewBox="0 0 32 32">
        <path d="M23.49 32.070c-0.37 0-0.74-0.135-1.030-0.405l-15.12-14.14c-0.3-0.28-0.475-0.675-0.48-1.085 0-0.41 0.16-0.81 0.45-1.095l15.12-14.84c0.595-0.585 1.55-0.575 2.135 0.020s0.575 1.55-0.020 2.135l-13.995 13.74 13.97 13.065c0.61 0.57 0.64 1.525 0.070 2.135-0.3 0.32-0.7 0.48-1.1 0.48z"></path>
      </symbol>
      <symbol id="icon-arrow-up" viewBox="0 0 32 32">
        <path d="M16.345 25.095c-0.42 0-0.825-0.17-1.12-0.47l-14.775-15.050c-0.605-0.62-0.595-1.61 0.020-2.215 0.625-0.61 1.615-0.595 2.215 0.020l13.635 13.885 12.97-13.86c0.595-0.63 1.585-0.66 2.215-0.075 0.63 0.59 0.665 1.585 0.075 2.215l-14.085 15.055c-0.29 0.31-0.7 0.49-1.125 0.495h-0.020z"></path>
      </symbol>
      <symbol id="icon-cart" viewBox="0 0 32 32">
        <path d="M31.53 7.74c-0.58-1.010-1.645-1.635-2.81-1.65h-21.15l-0.96-3.735c-0.2-0.745-0.885-1.25-1.65-1.225h-3.305c-0.915 0-1.655 0.74-1.655 1.655s0.74 1.655 1.655 1.655h2.050l4.565 16.955c0.2 0.74 0.885 1.25 1.65 1.225h14.87c0.62 0 1.185-0.355 1.465-0.91l5.42-10.84c0.48-1 0.425-2.17-0.145-3.125zM23.765 19.305h-12.59l-2.695-9.91h20.245l-4.96 9.91z"></path>
        <path d="M11.57 28.395c0 1.37-1.11 2.48-2.48 2.48s-2.48-1.11-2.48-2.48c0-1.37 1.11-2.48 2.48-2.48s2.475 1.105 2.48 2.475c0 0 0 0 0 0.005z"></path>
        <path d="M28.095 28.395c0 1.37-1.11 2.48-2.48 2.48s-2.48-1.11-2.48-2.48c0-1.37 1.11-2.48 2.48-2.48v0c1.37 0 2.475 1.105 2.48 2.475v0z"></path>
      </symbol>
      <symbol id="icon-check" viewBox="0 0 32 32">
        <path d="M32 6.32l-21.945 21.94-10.055-10.055 2.58-2.58 7.48 7.46 19.36-19.345 2.58 2.58z"></path>
      </symbol>
      <symbol id="icon-compare" viewBox="0 0 32 32">
        <path d="M16 0c-8.82 0-16 7.18-16 16s7.18 16 16 16 16-7.18 16-16-7.18-16-16-16zM16 30.015c-7.725 0-14.015-6.285-14.015-14.015s6.29-14.015 14.015-14.015 14.015 6.285 14.015 14.015-6.285 14.015-14.015 14.015z"></path>
        <path d="M22.005 15.005h-5.010v-5.010c0-0.55-0.445-0.995-0.995-0.995s-0.995 0.445-0.995 0.995v5.010h-5.010c-0.55 0-0.995 0.445-0.995 0.995s0.445 0.995 0.995 0.995h5.010v5.010c0 0.55 0.445 0.995 0.995 0.995s0.995-0.445 0.995-0.995v-5.010h5.010c0.55 0 0.995-0.445 0.995-0.995s-0.445-0.995-0.995-0.995z"></path>
      </symbol>
      <symbol id="icon-trash" viewBox="0 0 32 32">
        <path d="M21.77 11.67v14.43h-11.545v-14.43h11.545zM19.605 3.015h-7.215l-1.445 1.445h-5.050v2.885h20.2v-2.885h-5.050l-1.445-1.445zM24.655 8.785h-17.31v17.315c0 1.585 1.3 2.885 2.885 2.885h11.545c1.585 0 2.885-1.3 2.885-2.885v-17.315z"></path>
      </symbol>
      <symbol id="icon-delete" viewBox="0 0 32 32">
        <path d="M32 2l-2-2-14 14-14-14-2 2 14 14-14 14 2 2 14-14 14 14 2-2-14-14 14-14z"></path>
      </symbol>
      <symbol id="icon-delivery" viewBox="0 0 32 32">
        <path d="M32 14.115l-4.78-4.78s-4.55 0-4.55 0v-6.67h-20.615c-1.135 0-2.055 0.92-2.055 2.055v17.945h3.135c-0.67 1.4-0.735 3.14 0.56 4.99 0.29 0.415 0.675 0.77 1.125 1.010 3.57 1.935 7.18-0.62 7.18-4 0-0.72-0.175-1.39-0.47-2h8.935c-0.67 1.4-0.735 3.14 0.56 4.99 0.29 0.415 0.675 0.77 1.125 1.010 3.57 1.935 7.18-0.62 7.18-4 0-0.72-0.175-1.39-0.47-2h3.135v-8.55zM4.72 5.335h15.28v14.665h-17.335v-12.615c0-1.135 0.92-2.055 2.055-2.055zM8.88 23.325c1.435 2.060-0.83 4.33-2.89 2.895-0.080-0.055-0.15-0.125-0.205-0.205-1.435-2.060 0.83-4.33 2.89-2.895 0.080 0.055 0.15 0.125 0.205 0.205zM26.215 23.325c1.435 2.060-0.83 4.33-2.89 2.895-0.080-0.055-0.15-0.125-0.205-0.205-1.435-2.060 0.83-4.33 2.89-2.895 0.080 0.055 0.15 0.125 0.205 0.205zM29.335 20.005h-6.665v-8h3.45l3.22 3.22v4.78z"></path>
      </symbol>
      <symbol id="icon-discount" viewBox="0 0 32 32">
        <path d="M3.555 30.22c-0.455 0-0.91-0.175-1.255-0.52-0.695-0.695-0.695-1.82 0-2.515l24.89-24.885c0.695-0.695 1.82-0.695 2.515 0s0.695 1.82 0 2.515l-24.895 24.885c-0.345 0.345-0.8 0.52-1.255 0.52z"></path>
        <path d="M6.22 12.445c-3.43 0-6.22-2.79-6.22-6.225s2.79-6.22 6.22-6.22 6.22 2.79 6.22 6.22-2.79 6.22-6.22 6.22zM6.22 3.555c-1.47 0-2.665 1.195-2.665 2.665s1.195 2.665 2.665 2.665 2.665-1.195 2.665-2.665-1.195-2.665-2.665-2.665z"></path>
        <path d="M25.78 32c-3.43 0-6.22-2.79-6.22-6.22s2.79-6.22 6.22-6.22 6.22 2.79 6.22 6.22-2.79 6.22-6.22 6.22zM25.78 23.11c-1.47 0-2.665 1.195-2.665 2.665s1.195 2.665 2.665 2.665 2.665-1.195 2.665-2.665-1.195-2.665-2.665-2.665z"></path>
      </symbol>
      <symbol id="icon-download" viewBox="0 0 32 32">
        <path d="M23.5 19.75v3.75h-15v-3.75h-2.5v3.75c0 1.375 1.125 2.5 2.5 2.5h15c1.375 0 2.5-1.125 2.5-2.5v-3.75h-2.5zM22.25 14.75l-1.76-1.76-3.24 3.225v-10.215h-2.5v10.215l-3.24-3.225-1.76 1.76 6.25 6.25 6.25-6.25z"></path>
      </symbol>
      <symbol id="icon-email" viewBox="0 0 32 32">
        <path d="M31.975 7.010c-0.145-2.275-2.025-4.095-4.335-4.1h-23.28c-2.295 0.005-4.165 1.805-4.33 4.065-0.020 0.095-0.030 0.19-0.030 0.29 0 0 0 0.005 0 0.010v17.46c0.005 2.395 1.965 4.35 4.365 4.36h23.275c2.395-0.005 4.35-1.965 4.36-4.365v-17.46s0-0.005 0-0.010c0-0.085-0.010-0.165-0.025-0.25zM4.37 5.815h23.265c0.57 0 1.060 0.335 1.3 0.81l-12.93 9.050-12.935-9.045c0.24-0.475 0.73-0.805 1.295-0.81zM27.64 26.18h-23.27c-0.8 0-1.455-0.655-1.46-1.455v-14.66l12.255 8.58c0.25 0.175 0.545 0.265 0.835 0.265s0.585-0.090 0.835-0.265l12.255-8.58v14.655c0 0.8-0.655 1.455-1.455 1.46z"></path>
      </symbol>
      <symbol id="icon-filter" viewBox="0 0 32 32">
        <path d="M1.22 11.155h3.43c0.225 1.41 0.94 2.675 2.045 3.605 1.080 0.91 2.42 1.395 3.82 1.395 0.175 0 0.35-0.005 0.525-0.025 2.74-0.235 4.93-2.3 5.36-4.98h14.38c0.525 0 0.955-0.43 0.955-0.955s-0.43-0.955-0.955-0.955h-14.38c-0.49-3.045-3.255-5.25-6.385-4.98-2.74 0.235-4.93 2.3-5.36 4.98h-3.435c-0.525 0-0.955 0.43-0.955 0.955s0.43 0.955 0.955 0.955zM14.58 10.205c0 2.235-1.815 4.055-4.050 4.060v0c-2.235 0-4.050-1.815-4.055-4.050 0-0.145 0.005-0.29 0.025-0.435 0.22-2.060 1.95-3.62 4.025-3.62v0c2.235 0 4.050 1.815 4.055 4.050z"></path>
        <path d="M30.78 20.845h-4.86c-0.225-1.41-0.94-2.675-2.045-3.605-1.215-1.025-2.76-1.51-4.34-1.375-2.74 0.235-4.93 2.3-5.36 4.98h-12.955c-0.525 0-0.955 0.43-0.955 0.955s0.43 0.955 0.955 0.955h12.955c0.225 1.41 0.94 2.675 2.045 3.605 1.080 0.91 2.42 1.395 3.82 1.395 0.175 0 0.35-0.005 0.525-0.025 2.74-0.235 4.93-2.3 5.36-4.98h4.855c0.525 0 0.955-0.43 0.955-0.955s-0.425-0.95-0.95-0.955zM15.99 21.795c0-2.235 1.81-4.055 4.045-4.060h0.005c2.235 0 4.050 1.815 4.055 4.050 0 0.145-0.005 0.29-0.025 0.435-0.22 2.060-1.95 3.62-4.025 3.625h-0.005c-2.235 0-4.050-1.815-4.055-4.050z"></path>
      </symbol>
      <symbol id="icon-delivery-rounded" viewBox="0 0 32 32">
        <path d="M16 32c-8.825 0-16-7.175-16-16s7.175-16 16-16 16 7.175 16 16-7.175 16-16 16zM16 0.64c-8.47 0-15.36 6.89-15.36 15.36s6.89 15.36 15.36 15.36 15.36-6.89 15.36-15.36-6.89-15.36-15.36-15.36z"></path>
        <path d="M25.28 15.325l-2.7-2.7h-2.57v-3.765h-11.635c-0.64 0-1.16 0.52-1.16 1.16v0 10.125h1.77c-0.44 0.925-0.315 2.015 0.32 2.815 0.165 0.235 0.38 0.43 0.635 0.57 1.255 0.765 2.895 0.37 3.66-0.89 0.25-0.41 0.385-0.885 0.39-1.37 0-0.39-0.095-0.775-0.265-1.13h5.045c-0.44 0.925-0.315 2.015 0.32 2.815 0.165 0.235 0.38 0.43 0.635 0.57 1.255 0.765 2.895 0.37 3.66-0.885 0.25-0.415 0.385-0.885 0.39-1.37 0-0.39-0.095-0.775-0.265-1.13h1.77v-4.83zM9.885 10.37h8.625v8.28h-9.78v-7.12c0-0.64 0.52-1.16 1.16-1.16zM12.24 20.52c0.405 0.495 0.335 1.225-0.155 1.63-0.43 0.355-1.045 0.355-1.475 0-0.045-0.030-0.085-0.070-0.115-0.115-0.415-0.49-0.35-1.22 0.14-1.635 0.43-0.365 1.060-0.365 1.49 0 0.045 0.030 0.085 0.070 0.115 0.115zM22.020 20.515c0.41 0.495 0.34 1.225-0.155 1.635-0.43 0.355-1.050 0.355-1.475 0-0.045-0.030-0.085-0.070-0.115-0.115-0.405-0.495-0.335-1.225 0.155-1.63 0.43-0.355 1.045-0.355 1.475 0 0.045 0.030 0.085 0.070 0.115 0.115zM23.78 18.645h-3.76v-4.515h1.945l1.815 1.815v2.7z"></path>
      </symbol>
      <symbol id="icon-facebook-rounded" viewBox="0 0 32 32">
        <path d="M16 0.64c-2.075 0-4.085 0.405-5.98 1.205-1.83 0.775-3.47 1.88-4.885 3.29-1.41 1.41-2.52 3.055-3.29 4.885-0.8 1.895-1.205 3.905-1.205 5.98s0.405 4.085 1.205 5.98c0.775 1.83 1.88 3.47 3.29 4.885 1.41 1.41 3.055 2.52 4.885 3.29 1.895 0.8 3.905 1.205 5.98 1.205s4.085-0.405 5.98-1.205c1.83-0.775 3.47-1.88 4.885-3.29 1.41-1.41 2.52-3.055 3.29-4.885 0.8-1.895 1.205-3.905 1.205-5.98s-0.405-4.085-1.205-5.98c-0.775-1.83-1.88-3.47-3.29-4.885-1.41-1.41-3.055-2.52-4.885-3.29-1.895-0.8-3.905-1.205-5.98-1.205zM16 0c8.835 0 16 7.165 16 16s-7.165 16-16 16-16-7.165-16-16 7.165-16 16-16z"></path>
        <path d="M17.26 10.485c-0.53 0-1.050 0.155-1.485 0.46-0.485 0.355-0.81 0.89-0.895 1.485-0.040 0.25-0.060 0.505-0.065 0.765-0.010 0.4 0 0.795 0 1.2v0.15h-1.695v1.985h1.69v5h2.060v-4.985h1.685c0.085-0.66 0.17-1.315 0.26-1.995h-1.96s0-0.985 0.015-1.41c0.020-0.59 0.365-0.77 0.835-0.785 0.34-0.015 0.68 0 1.025 0h0.14v-1.78c-0.18-0.020-0.375-0.045-0.565-0.055-0.35-0.015-0.7-0.035-1.050-0.025z"></path>
      </symbol>
      <symbol id="icon-instagram-rounded" viewBox="0 0 32 32">
        <path d="M16 0.64c-2.075 0-4.085 0.405-5.98 1.205-1.83 0.775-3.47 1.88-4.885 3.29-1.41 1.41-2.52 3.055-3.29 4.885-0.8 1.895-1.205 3.905-1.205 5.98s0.405 4.085 1.205 5.98c0.775 1.83 1.88 3.47 3.29 4.885 1.41 1.41 3.055 2.52 4.885 3.29 1.895 0.8 3.905 1.205 5.98 1.205s4.085-0.405 5.98-1.205c1.83-0.775 3.47-1.88 4.885-3.29 1.41-1.41 2.52-3.055 3.29-4.885 0.8-1.895 1.205-3.905 1.205-5.98s-0.405-4.085-1.205-5.98c-0.775-1.83-1.88-3.47-3.29-4.885-1.41-1.41-3.055-2.52-4.885-3.29-1.895-0.8-3.905-1.205-5.98-1.205zM16 0c8.835 0 16 7.165 16 16s-7.165 16-16 16-16-7.165-16-16 7.165-16 16-16z"></path>
        <path d="M16 12.725c-1.81 0-3.275 1.465-3.275 3.275v0c0 1.81 1.465 3.275 3.275 3.275s3.275-1.465 3.275-3.275c0-1.81-1.465-3.275-3.275-3.275v0zM16 18.485c-1.37 0-2.48-1.11-2.48-2.48s1.11-2.48 2.48-2.48c1.37 0 2.48 1.11 2.48 2.48v0c0 1.37-1.115 2.475-2.48 2.48v0z"></path>
        <path d="M19.265 10.56h-6.535c-1.225 0-2.22 0.995-2.225 2.225v6.435c0 1.225 0.995 2.22 2.225 2.225h6.535c1.225 0 2.22-0.995 2.225-2.225v-6.435c0-1.225-0.995-2.22-2.225-2.225zM20.675 19.215c0 0.78-0.635 1.41-1.41 1.41h-6.535c-0.78 0-1.41-0.635-1.41-1.41v-6.435c0-0.78 0.635-1.41 1.41-1.41h6.535c0.78 0 1.41 0.635 1.41 1.41v6.435z"></path>
        <path d="M19.32 12.355c-0.285 0-0.515 0.23-0.515 0.515s0.23 0.515 0.515 0.515c0.285 0 0.515-0.23 0.515-0.515s-0.23-0.515-0.515-0.52v0z"></path>
      </symbol>
      <symbol id="icon-lock-rounded" viewBox="0 0 32 32">
        <path d="M16 32c-8.825 0-16-7.175-16-16s7.175-16 16-16 16 7.175 16 16-7.175 16-16 16zM16 0.64c-8.47 0-15.36 6.89-15.36 15.36s6.89 15.36 15.36 15.36 15.36-6.89 15.36-15.36-6.89-15.36-15.36-15.36z"></path>
        <path d="M20.85 13.795h-0.69v-2.135c0-2.355-1.86-4.265-4.15-4.265s-4.15 1.91-4.15 4.265v2.135h-0.69c-1.145 0-2.075 0.955-2.075 2.135v4.975c0 1.18 0.93 2.13 2.075 2.135h9.685c1.145 0 2.075-0.955 2.075-2.135v-4.975c0-1.18-0.93-2.13-2.075-2.135zM13.245 11.66c-0.025-1.57 1.195-2.865 2.725-2.885 1.53-0.025 2.785 1.23 2.81 2.8 0 0.030 0 0.055 0 0.085v2.135h-5.535v-2.135zM21.545 20.905c0 0.395-0.31 0.71-0.69 0.71h-9.685c-0.38 0-0.69-0.32-0.69-0.71v-4.975c0-0.395 0.31-0.71 0.69-0.71h9.685c0.38 0 0.69 0.32 0.69 0.71v4.975z"></path>
      </symbol>
      <symbol id="icon-return-rounded" viewBox="0 0 32 32">
        <path d="M16 0.64c-2.075 0-4.085 0.405-5.98 1.205-1.83 0.775-3.47 1.88-4.885 3.29-1.41 1.41-2.52 3.055-3.29 4.885-0.8 1.895-1.205 3.905-1.205 5.98s0.405 4.085 1.205 5.98c0.775 1.83 1.88 3.47 3.29 4.885 1.41 1.41 3.055 2.52 4.885 3.29 1.895 0.8 3.905 1.205 5.98 1.205s4.085-0.405 5.98-1.205c1.83-0.775 3.47-1.88 4.885-3.29 1.41-1.41 2.52-3.055 3.29-4.885 0.8-1.895 1.205-3.905 1.205-5.98s-0.405-4.085-1.205-5.98c-0.775-1.83-1.88-3.47-3.29-4.885-1.41-1.41-3.055-2.52-4.885-3.29-1.895-0.8-3.905-1.205-5.98-1.205zM16 0c8.835 0 16 7.165 16 16s-7.165 16-16 16-16-7.165-16-16 7.165-16 16-16z"></path>
        <path d="M15.835 23.235c-3.73 0.060-6.805-2.91-6.88-6.64v-0.345c0-0.415 0.335-0.75 0.75-0.75s0.75 0.335 0.75 0.75v0.345c0.11 2.97 2.61 5.285 5.58 5.175 2.81-0.105 5.065-2.36 5.175-5.175-0.065-2.91-2.465-5.22-5.375-5.165h-0.685l0.85 0.82c0.29 0.28 0.3 0.74 0.025 1.030-0.010 0.010-0.015 0.015-0.025 0.025-0.3 0.27-0.755 0.27-1.050 0l-2.165-2.085c-0.29-0.28-0.3-0.74-0.025-1.030 0.005-0.010 0.015-0.015 0.025-0.025l2.165-2.085c0.295-0.28 0.755-0.28 1.050 0 0.29 0.28 0.3 0.74 0.025 1.030-0.005 0.010-0.015 0.015-0.025 0.025l-0.85 0.82h0.685c3.73-0.060 6.805 2.91 6.88 6.64-0.070 3.73-3.15 6.7-6.88 6.64z"></path>
      </symbol>
      <symbol id="icon-headphone" viewBox="0 0 32 32">
        <path d="M16 0c-8.82 0-16 7.175-16 16v11.2c0 2.645 2.155 4.8 4.8 4.8h1.6c2.645 0 4.8-2.155 4.8-4.8v-4.8c0-2.645-2.155-4.8-4.8-4.8h-3.2v-1.6c0-7.060 5.74-12.8 12.8-12.8s12.8 5.74 12.8 12.8v1.6h-3.2c-2.645 0-4.8 2.155-4.8 4.8v4.8c0 2.645 2.155 4.8 4.8 4.8h1.6c2.645 0 4.8-2.155 4.8-4.8v-11.2c0-8.825-7.18-16-16-16zM6.4 20.8c0.88 0 1.6 0.72 1.6 1.6v4.8c0 0.88-0.72 1.6-1.6 1.6h-1.6c-0.88 0-1.6-0.72-1.6-1.6v-6.4h3.2zM28.8 27.2c0 0.88-0.72 1.6-1.6 1.6h-1.6c-0.88 0-1.6-0.72-1.6-1.6v-4.8c0-0.88 0.72-1.6 1.6-1.6h3.2v6.4z"></path>
      </symbol>
      <symbol id="icon-language" viewBox="0 0 32 32">
        <path d="M22.975 19.2c0.135-1.060 0.205-2.13 0.215-3.2-0.005-1.070-0.075-2.14-0.215-3.2h5.395c0.555 2.095 0.555 4.305 0 6.4zM20.13 28.095c0.965-1.8 1.705-3.715 2.21-5.695h4.73c-1.55 2.67-4.010 4.69-6.93 5.695zM19.74 19.2h-7.49c-0.34-2.12-0.34-4.28 0-6.4h7.49c0.16 1.060 0.245 2.13 0.255 3.2-0.010 1.070-0.1 2.14-0.255 3.2zM15.995 28.735c-1.355-1.93-2.39-4.070-3.055-6.335h6.11c-0.67 2.265-1.7 4.405-3.055 6.335zM9.595 9.6h-4.67c1.535-2.675 3.99-4.7 6.91-5.695-0.955 1.81-1.705 3.72-2.24 5.695zM4.925 22.4h4.67c0.535 1.975 1.285 3.885 2.24 5.695-2.915-1.005-5.37-3.030-6.91-5.695zM3.615 19.2c-0.555-2.095-0.555-4.305 0-6.4h5.41c-0.14 1.060-0.215 2.13-0.225 3.2 0.010 1.070 0.085 2.14 0.225 3.2zM16 3.25c1.355 1.935 2.39 4.080 3.055 6.35h-6.11c0.665-2.27 1.7-4.415 3.055-6.35zM27.070 9.6h-4.72c-0.5-1.98-1.24-3.895-2.21-5.695 2.92 1 5.385 3.020 6.93 5.695zM16 0c-8.835 0-16 7.165-16 16s7.165 16 16 16 16-7.165 16-16c-0.010-8.835-7.165-15.99-16-16z"></path>
      </symbol>
      <symbol id="icon-logout" viewBox="0 0 32 32">
        <path d="M15.75 24.945h-8.895v-18.265h9.145c0.83 0 1.5-0.67 1.5-1.5s-0.67-1.5-1.5-1.5h-10.645c-0.83 0-1.5 0.67-1.5 1.5v21.265c0 0.83 0.67 1.5 1.5 1.5h10.395c0.83 0 1.5-0.67 1.5-1.5s-0.67-1.5-1.5-1.5z"></path>
        <path d="M27.2 14.705l-4.54-4.14c-0.61-0.56-1.56-0.515-2.12 0.095s-0.515 1.56 0.095 2.12l1.885 1.72h-10.48c-0.83 0-1.5 0.67-1.5 1.5s0.67 1.5 1.5 1.5h10.32l-1.77 1.76c-0.59 0.585-0.59 1.535-0.005 2.12 0.295 0.295 0.68 0.445 1.065 0.445s0.765-0.145 1.055-0.435l4.54-4.51c0.29-0.29 0.45-0.685 0.44-1.095s-0.185-0.8-0.49-1.075z"></path>
      </symbol>
      <symbol id="icon-minus" viewBox="0 0 32 32">
        <path d="M0 14.315h32v3.37h-32v-3.37z"></path>
      </symbol>
      <symbol id="icon-pay" viewBox="0 0 32 32">
        <path d="M28.33 3.25h-24.66c-2.025 0-3.67 1.645-3.67 3.67v18.155c0 2.025 1.645 3.67 3.67 3.67h24.66c2.025 0 3.67-1.645 3.67-3.67v-18.155c0-2.025-1.645-3.67-3.67-3.67zM3.67 5.995h24.66c0.51 0 0.93 0.415 0.93 0.93v3.215h-26.515v-3.215c0-0.51 0.415-0.93 0.93-0.93zM28.33 26.010h-24.66c-0.51 0-0.93-0.415-0.93-0.93v-12.2h26.515v12.2c0 0.51-0.415 0.93-0.93 0.93z"></path>
      </symbol>
      <symbol id="icon-phone" viewBox="0 0 32 32">
        <path d="M23.82 21.155h-0.535c-0.475-0.015-0.93 0.18-1.245 0.535l-3.91 3.91c-5.020-2.62-9.115-6.715-11.735-11.735l3.91-3.91c0.53-0.43 0.68-1.175 0.355-1.78-0.57-2.085-0.87-4.235-0.89-6.4-0.055-0.95-0.815-1.71-1.77-1.775h-6.22c-0.955 0.065-1.715 0.825-1.78 1.78-0.015 16.68 13.495 30.21 30.17 30.22 0.015 0 0.035 0 0.050 0 0.955-0.065 1.715-0.825 1.78-1.78v-6.22c-0.065-0.955-0.825-1.715-1.78-1.78zM3.555 3.555h2.665c0.195 1.56 0.49 3.105 0.89 4.62l-2.135 2.135c-0.745-2.185-1.225-4.455-1.42-6.755zM28.445 28.445c-2.3-0.2-4.57-0.675-6.755-1.42l2.135-2.135c1.505 0.425 3.060 0.66 4.62 0.71v2.845z"></path>
      </symbol>
      <symbol id="icon-plus" viewBox="0 0 32 32">
        <path d="M32 14.315h-14.315v-14.315h-3.37v14.315h-14.315v3.37h14.315v14.315h3.37v-14.315h14.315v-3.37z"></path>
      </symbol>
      <symbol id="icon-privacy" viewBox="0 0 32 32">
        <path d="M26.18 13.090h-1.455v-4.365c0-4.81-3.915-8.725-8.725-8.725s-8.725 3.915-8.725 8.725v4.365h-1.455c-2.405 0-4.365 1.955-4.365 4.365v10.18c0 2.405 1.955 4.365 4.365 4.365h20.365c2.405 0 4.365-1.955 4.365-4.365v-10.18c0-2.405-1.955-4.365-4.365-4.365zM10.18 8.725c0-3.21 2.61-5.82 5.82-5.82s5.82 2.61 5.82 5.82v4.365h-11.635v-4.365zM27.635 27.635c0 0.8-0.65 1.455-1.455 1.455h-20.36c-0.8 0-1.455-0.65-1.455-1.455v-10.18c0-0.8 0.65-1.455 1.455-1.455h20.365c0.8 0 1.455 0.65 1.455 1.455v10.18z"></path>
      </symbol>
      <symbol id="icon-return" viewBox="0 0 32 32">
        <path d="M16 30.74c-7.28 0-13.2-5.715-13.2-12.74v-0.665c0-0.78 0.645-1.415 1.44-1.415s1.44 0.635 1.44 1.415v0.665c0 5.465 4.63 9.91 10.315 9.91s10.315-4.445 10.315-9.91-4.63-9.91-10.315-9.91h-1.31l1.63 1.57c0.28 0.27 0.43 0.625 0.43 1.010s-0.155 0.745-0.43 1.010c-0.545 0.525-1.475 0.52-2.020 0l-4.15-4c-0.28-0.27-0.43-0.625-0.43-1.010s0.155-0.745 0.43-1.010l4.15-4c0.555-0.535 1.46-0.535 2.020 0 0.28 0.27 0.43 0.625 0.43 1.010s-0.155 0.745-0.43 1.010l-1.63 1.57h1.31c7.28 0 13.2 5.715 13.2 12.74s-5.92 12.74-13.2 12.74z"></path>
      </symbol>
      <symbol id="icon-search" viewBox="0 0 32 32">
        <path d="M22.88 20.135h-1.445l-0.515-0.49c4.28-4.985 3.705-12.495-1.28-16.775-4.98-4.275-12.49-3.705-16.77 1.28s-3.705 12.495 1.28 16.775c4.455 3.825 11.040 3.825 15.495 0l0.495 0.51v1.445l9.14 9.115 2.725-2.725-9.12-9.14zM11.915 20.135c-4.54 0-8.225-3.68-8.225-8.225 0-4.54 3.68-8.225 8.225-8.225s8.225 3.68 8.225 8.225v0c0.005 4.535-3.665 8.22-8.205 8.225-0.005 0-0.015 0-0.020 0z"></path>
      </symbol>
      <symbol id="icon-size" viewBox="0 0 32 32">
        <path d="M26.155 7.385h-26.155v17.23h32v-17.23h-5.845zM30.155 22.77h-28.31v-13.54h4v8.615h1.845v-8.615h4.31v3.075h1.845v-3.075h4.31v8.615h1.845v-8.615h4.31v3.075h1.845v-3.075h4v13.54z"></path>
      </symbol>
      <symbol id="icon-wishlist" viewBox="0 0 32 32">
        <path d="M16.16 26.2l-0.16 0.16-0.175-0.16c-7.6-6.9-12.625-11.45-12.625-16.080-0.075-3.020 2.315-5.525 5.335-5.6 0.090 0 0.18 0 0.265 0 2.485 0.020 4.72 1.5 5.71 3.775h2.975c0.99-2.275 3.225-3.755 5.705-3.775 3.020-0.075 5.525 2.315 5.6 5.33 0 0.090 0 0.18 0 0.265 0 4.625-5.025 9.185-12.64 16.080zM23.19 1.32c-2.765 0.025-5.385 1.235-7.19 3.33-1.81-2.095-4.435-3.305-7.2-3.33-4.805-0.050-8.745 3.805-8.8 8.61 0 0.065 0 0.13 0 0.19 0 6.035 5.44 10.975 13.68 18.45l2.32 2.11 2.32-2.11c8.24-7.47 13.68-12.42 13.68-18.445 0.055-4.805-3.8-8.745-8.61-8.8-0.065 0-0.135 0-0.2 0z"></path>
      </symbol>
      <symbol id="icon-hidden" viewBox="0 0 32 32">
        <path d="M3.85 1.643l-2.053 2.052 26.422 26.423 2.053-2.053-26.422-26.422z"></path>
        <path d="M6.134 8.034l1.933 1.933c-2.116 1.801-3.8 4.043-4.889 6.005l-0.003 0.007c3.032 5.069 7.843 9.86 13.914 9.133 1.639-0.196 3.168-0.756 4.565-1.558l1.956 1.956c-2.723 1.748-5.844 2.673-9.226 2.216-6.098-0.825-11.084-5.826-14.227-11.7 1.476-2.97 3.495-5.81 5.978-7.991zM10.53 5.169c1.705-0.76 3.55-1.207 5.521-1.239 0.109-0.001 1.408 0.061 1.997 0.158 0.369 0.061 0.737 0.137 1.1 0.232 5.784 1.507 10.070 6.397 12.764 11.435-1.13 2.283-2.611 4.51-4.376 6.419l-1.874-1.874c1.304-1.406 2.392-2.97 3.229-4.488 0 0-0.842-1.354-1.48-2.204-0.411-0.546-0.842-1.077-1.296-1.588-0.358-0.403-1.748-1.772-2.079-2.057-2.22-1.909-4.826-3.406-7.952-3.381-1.21 0.019-2.375 0.258-3.48 0.66l-2.073-2.073z"></path>
        <path d="M11.395 13.295l1.591 1.59c-0.104 0.316-0.161 0.655-0.161 1.005 0 1.771 1.438 3.209 3.209 3.209 0.351 0 0.689-0.056 1.005-0.161l1.591 1.591c-0.768 0.431-1.653 0.676-2.595 0.676-2.934 0-5.315-2.381-5.315-5.315 0-0.942 0.246-1.827 0.676-2.595zM15.936 10.576c0.032-0.001 0.066-0.001 0.098-0.001 2.933 0 5.315 2.382 5.315 5.315 0 0.033 0 0.066-0 0.098l-5.412-5.412z"></path>
      </symbol>
      <symbol id="icon-visible" viewBox="0 0 32 32">
        <path d="M16.342 3.955c7.047 0.1 12.828 6.436 15.724 11.887 0 0-1.019 2.085-1.975 3.42-0.462 0.646-0.949 1.275-1.46 1.883-0.365 0.433-0.741 0.855-1.132 1.265-3.49 3.665-8.502 6.4-13.795 5.338-5.88-1.18-10.676-6.218-13.559-11.637 0 0 1.024-2.087 1.985-3.42 0.431-0.598 0.882-1.18 1.356-1.744 0.362-0.432 0.739-0.854 1.127-1.264 3.084-3.249 7.040-5.751 11.73-5.729zM16.317 6.621c-3.925-0.015-7.189 2.177-9.772 4.898-0.351 0.37-0.69 0.751-1.018 1.142-0.431 0.515-0.843 1.046-1.236 1.59s-0.795 1.23-1.114 1.813c2.542 4.271 6.357 8.129 11.051 9.071 4.374 0.878 8.456-1.533 11.34-4.562 0.353-0.37 0.693-0.752 1.022-1.143 0.467-0.555 0.91-1.129 1.332-1.719 0.39-0.546 0.793-1.234 1.111-1.817-2.638-4.415-7.151-9.186-12.717-9.273z"></path>
        <path d="M16.105 10.634c-2.948 0-5.343 2.395-5.343 5.343s2.395 5.343 5.343 5.343c2.949 0 5.344-2.394 5.344-5.343s-2.395-5.343-5.344-5.343zM16.105 13.306c1.475 0 2.672 1.197 2.672 2.671s-1.197 2.672-2.672 2.672c-1.474 0-2.671-1.197-2.671-2.672s1.197-2.671 2.671-2.671z"></path>
      </symbol>
      <symbol id="icon-info" viewBox="0 0 32 32">
        <path d="M15.5 12.25h1.3v13.35h-1.3v-13.35z"></path>
        <path d="M16.15 6.9c-0.6 0-1.050 0.5-1.050 1.050s0.45 1 1.050 1 1.050-0.45 1.050-1.050c0-0.5-0.45-1-1.050-1z"></path>
        <path d="M16 0c-8.8 0-16 7.2-16 16s7.2 16 16 16c8.8 0 16-7.2 16-16s-7.2-16-16-16zM16 29.85c-7.65 0-13.85-6.2-13.85-13.85s6.2-13.85 13.85-13.85c7.65 0 13.85 6.2 13.85 13.85s-6.2 13.85-13.85 13.85z"></path>
      </symbol>
      <symbol id="icon-flag" viewBox="0 0 32 32">
        <path d="M11.75 27.25c0 0-0.050 0-0.050 0-0.75 0-1.45-0.35-1.95-0.85l-8.95-9.4c-1.050-1.1-1.050-2.9 0.1-3.95 1.1-1.1 2.85-1.050 3.95 0.1l7 7.3 15.4-14.9c1.1-1.1 2.9-1.050 3.95 0.050 1.1 1.1 1.050 2.9-0.050 3.95l-17.45 16.9c-0.55 0.5-1.25 0.8-1.95 0.8z"></path>
      </symbol>
    </defs>
  </svg>
);
