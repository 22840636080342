import './StoreLocatorPage.scss';

import { b2x } from '@b2x/react/src';

import { ButtonVariant } from '../Button';
import { IconName, IconSize } from '../Icon';
import pin from '../images/pin2.png';
import { Page } from './Page';

export interface StoreLocatorPageProps {}

export const StoreLocatorPage = (props: StoreLocatorPageProps) => {
  return (
    <Page hiddenFooter noPaddingBottom noPaddingTop>
      <div className="h-100">
        <b2x.StoreLocator<ButtonVariant, IconName, IconSize>
          debug
          filtersToggleButtonVariant="outline-primary"
          getDetailsPanelMarkerContent={getDetailsPanelMarkerContent}
          getMarkerContent={getMarkerContent}
          getShopName={getShopName}
          hideShopsInListingPanel
          iconArrowLeft="arrow-sx"
          iconArrowRight="arrow-dx"
          iconClose="delete"
          iconDirections="navigator"
          iconFilter="filter-map"
          iconMyLocation="geolocator-map"
          iconPhoneCall="phone"
          iconPin="locator-map"
          iconSearch="search"
          iconShare="sharing"
          iconSpeechRecognition="voice-recorder"
          iconSpeechRecognitionActive="voice-recorder-on"
          iconZoomIn="plus"
          iconZoomOut="minus"
          iconsSize={24}
          variantPrimary="primary"
          variantSecondary="secondary"
        />
      </div>
    </Page>
  );
};

export const getShopName = (shop: b2x.ShopApiDto) => shop.name ?? '';

const getMarkerContent = (shop: b2x.ShopApiDto): b2x.MarkerContent => {
  return {
    image: {
      src: pin,
    },
  };
};

const getDetailsPanelMarkerContent = (shop: b2x.ShopApiDto): b2x.MarkerContent => {
  return {
    image: {
      src: pin,
      width: 30,
    },
  };
};
