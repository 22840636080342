// https://github.com/mihai-craita/countries_center_box/blob/master/countries.json

export const countries = [
  {
    center_lat: 33.93911,
    center_lng: 67.709953,
    code: 'AF',
    id: 1,
    name: 'Afghanistan',
    ne_lat: 38.490877,
    ne_lng: 74.889862,
    sw_lat: 29.3772,
    sw_lng: 60.517,
  },
  {
    center_lat: 41.153332,
    center_lng: 20.168331,
    code: 'AL',
    id: 2,
    name: 'Albania',
    ne_lat: 42.661082,
    ne_lng: 21.057239,
    sw_lat: 39.64473,
    sw_lng: 19.263904,
  },
  {
    center_lat: 28.033886,
    center_lng: 1.659626,
    code: 'DZ',
    id: 3,
    name: 'Algeria',
    ne_lat: 37.089821,
    ne_lng: 12.0,
    sw_lat: 18.968147,
    sw_lng: -8.666667,
  },
  {
    center_lat: 42.506285,
    center_lng: 1.521801,
    code: 'AD',
    id: 4,
    name: 'Andorra',
    ne_lat: 42.655791,
    ne_lng: 1.786639,
    sw_lat: 42.428749,
    sw_lng: 1.408705,
  },
  {
    center_lat: -11.202692,
    center_lng: 17.873887,
    code: 'AO',
    id: 5,
    name: 'Angola',
    ne_lat: -4.387944,
    ne_lng: 24.084444,
    sw_lat: -18.039104,
    sw_lng: 11.669562,
  },
  {
    center_lat: 17.074656,
    center_lng: -61.817521,
    code: 'AG',
    id: 6,
    name: 'Antigua',
    ne_lat: 17.17376,
    ne_lng: -61.673155,
    sw_lat: 16.997626,
    sw_lng: -61.906251,
  },
  {
    center_lat: -38.416097,
    center_lng: -63.616672,
    code: 'AR',
    id: 7,
    name: 'Argentina',
    ne_lat: -21.780814,
    ne_lng: -53.637481,
    sw_lat: -55.057715,
    sw_lng: -73.56036,
  },
  {
    center_lat: 40.069099,
    center_lng: 45.038189,
    code: 'AM',
    id: 8,
    name: 'Armenia',
    ne_lat: 41.300993,
    ne_lng: 46.634222,
    sw_lat: 38.840244,
    sw_lng: 43.447212,
  },
  {
    center_lat: -25.274398,
    center_lng: 133.775136,
    code: 'AU',
    id: 9,
    name: 'Australia',
    ne_lat: -9.221084,
    ne_lng: 159.255528,
    sw_lat: -54.777219,
    sw_lng: 112.921454,
  },
  {
    center_lat: 47.516231,
    center_lng: 14.550072,
    code: 'AT',
    id: 10,
    name: 'Austria',
    ne_lat: 49.020608,
    ne_lng: 17.160686,
    sw_lat: 46.372336,
    sw_lng: 9.530783,
  },
  {
    center_lat: 40.143105,
    center_lng: 47.576927,
    code: 'AZ',
    id: 11,
    name: 'Azerbaijan',
    ne_lat: 41.91234,
    ne_lng: 50.467866,
    sw_lat: 38.39199,
    sw_lng: 44.764683,
  },
  {
    center_lat: 25.03428,
    center_lng: -77.39628,
    code: 'BS',
    id: 12,
    name: 'The Bahamas',
    ne_lat: 27.263362,
    ne_lng: -72.712069,
    sw_lat: 20.912131,
    sw_lng: -80.474947,
  },
  {
    center_lat: 26.0667,
    center_lng: 50.5577,
    code: 'BH',
    id: 13,
    name: 'Bahrain',
    ne_lat: 26.330394,
    ne_lng: 50.824846,
    sw_lat: 25.556458,
    sw_lng: 50.378151,
  },
  {
    center_lat: 23.684994,
    center_lng: 90.356331,
    code: 'BD',
    id: 14,
    name: 'Bangladesh',
    ne_lat: 26.634243,
    ne_lng: 92.680115,
    sw_lat: 20.75438,
    sw_lng: 88.008589,
  },
  {
    center_lat: 13.193887,
    center_lng: -59.543198,
    code: 'BB',
    id: 15,
    name: 'Barbados',
    ne_lat: 13.335126,
    ne_lng: -59.420098,
    sw_lat: 13.045,
    sw_lng: -59.65103,
  },
  {
    center_lat: 53.709807,
    center_lng: 27.953389,
    code: 'BY',
    id: 16,
    name: 'Belarus',
    ne_lat: 56.171872,
    ne_lng: 32.77682,
    sw_lat: 51.262011,
    sw_lng: 23.178338,
  },
  {
    center_lat: 50.503887,
    center_lng: 4.469936,
    code: 'BE',
    id: 17,
    name: 'Belgium',
    ne_lat: 51.505145,
    ne_lng: 6.408124,
    sw_lat: 49.497013,
    sw_lng: 2.544795,
  },
  {
    center_lat: 17.189877,
    center_lng: -88.49765,
    code: 'BZ',
    id: 18,
    name: 'Belize',
    ne_lat: 18.495942,
    ne_lng: -87.491727,
    sw_lat: 15.885619,
    sw_lng: -89.227588,
  },
  {
    center_lat: 9.30769,
    center_lng: 2.315834,
    code: 'BJ',
    id: 19,
    name: 'Benin',
    ne_lat: 12.408611,
    ne_lng: 3.843343,
    sw_lat: 6.234832,
    sw_lng: 0.776667,
  },
  {
    center_lat: 27.514162,
    center_lng: 90.433601,
    code: 'BT',
    id: 20,
    name: 'Bhutan',
    ne_lat: 28.360825,
    ne_lng: 92.125232,
    sw_lat: 26.702016,
    sw_lng: 88.746473,
  },
  {
    center_lat: -16.290154,
    center_lng: -63.588653,
    code: 'BO',
    id: 21,
    name: 'Bolivia',
    ne_lat: -9.669323,
    ne_lng: -57.453803,
    sw_lat: -22.89809,
    sw_lng: -69.64499,
  },
  {
    center_lat: -22.328474,
    center_lng: 24.684866,
    code: 'BW',
    id: 22,
    name: 'Botswana',
    ne_lat: -17.778137,
    ne_lng: 29.375304,
    sw_lat: -26.907545,
    sw_lng: 19.998905,
  },
  {
    center_lat: -14.235004,
    center_lng: -51.92528,
    code: 'BR',
    id: 23,
    name: 'Brazil',
    ne_lat: 5.271602,
    ne_lng: -29.345024,
    sw_lat: -33.751748,
    sw_lng: -73.982817,
  },
  {
    center_lat: 4.535277,
    center_lng: 114.727669,
    code: 'BN',
    id: 24,
    name: 'Brunei',
    ne_lat: 5.082646,
    ne_lng: 115.363562,
    sw_lat: 4.002508,
    sw_lng: 114.076063,
  },
  {
    center_lat: 42.733883,
    center_lng: 25.48583,
    code: 'BG',
    id: 25,
    name: 'Bulgaria',
    ne_lat: 44.215233,
    ne_lng: 28.609263,
    sw_lat: 41.235447,
    sw_lng: 22.357345,
  },
  {
    center_lat: 12.238333,
    center_lng: -1.561593,
    code: 'BF',
    id: 26,
    name: 'Burkina Faso',
    ne_lat: 15.085111,
    ne_lng: 2.404293,
    sw_lat: 9.393889,
    sw_lng: -5.521111,
  },
  {
    center_lat: -3.373056,
    center_lng: 29.918886,
    code: 'BI',
    id: 27,
    name: 'Burundi',
    ne_lat: -2.309987,
    ne_lng: 30.850173,
    sw_lat: -4.469228,
    sw_lng: 29.000993,
  },
  {
    center_lat: 12.565679,
    center_lng: 104.990963,
    code: 'KH',
    id: 28,
    name: 'Cambodia',
    ne_lat: 14.690179,
    ne_lng: 107.627687,
    sw_lat: 9.276808,
    sw_lng: 102.333542,
  },
  {
    center_lat: 7.369722,
    center_lng: 12.354722,
    code: 'CM',
    id: 29,
    name: 'Cameroon',
    ne_lat: 13.083335,
    ne_lng: 16.194408,
    sw_lat: 1.6559,
    sw_lng: 8.494763,
  },
  {
    center_lat: 56.130366,
    center_lng: -106.346771,
    code: 'CA',
    id: 30,
    name: 'Canada',
    ne_lat: 83.115061,
    ne_lng: -52.619409,
    sw_lat: 41.676556,
    sw_lng: -141.00187,
  },
  {
    center_lat: 15.120142,
    center_lng: -23.605172,
    code: 'CV',
    id: 31,
    name: 'Cape Verde',
    ne_lat: 17.205287,
    ne_lng: -22.657778,
    sw_lat: 14.802351,
    sw_lng: -25.360994,
  },
  {
    center_lat: 15.454166,
    center_lng: 18.732207,
    code: 'TD',
    id: 32,
    name: 'Chad',
    ne_lat: 23.449235,
    ne_lng: 24.000001,
    sw_lat: 7.442975,
    sw_lng: 13.47,
  },
  {
    center_lat: -35.675147,
    center_lng: -71.542969,
    code: 'CL',
    id: 33,
    name: 'Chile',
    ne_lat: -17.498329,
    ne_lng: -66.418202,
    sw_lat: -55.979781,
    sw_lng: -109.454881,
  },
  {
    center_lat: 35.86166,
    center_lng: 104.195397,
    code: 'CN',
    id: 34,
    name: 'China',
    ne_lat: 53.560974,
    ne_lng: 134.77281,
    sw_lat: 18.153522,
    sw_lng: 73.499414,
  },
  {
    center_lat: 4.570868,
    center_lng: -74.297333,
    code: 'CO',
    id: 35,
    name: 'Colombia',
    ne_lat: 13.39735,
    ne_lng: -66.851923,
    sw_lat: -4.22711,
    sw_lng: -81.73593,
  },
  {
    center_lat: -11.6455,
    center_lng: 43.3333,
    code: 'KM',
    id: 36,
    name: 'Comoros',
    ne_lat: -11.364639,
    ne_lng: 44.54057,
    sw_lat: -12.413821,
    sw_lng: 43.219421,
  },
  {
    center_lat: -0.228021,
    center_lng: 15.827659,
    code: 'CG',
    id: 37,
    name: 'Congo',
    ne_lat: 3.707791,
    ne_lng: 18.643611,
    sw_lat: -5.028949,
    sw_lng: 11.149548,
  },
  {
    center_lat: 9.748917,
    center_lng: -83.753428,
    code: 'CR',
    id: 38,
    name: 'Costa Rica',
    ne_lat: 11.219681,
    ne_lng: -82.552657,
    sw_lat: 5.499153,
    sw_lng: -87.094513,
  },
  {
    center_lat: 45.1,
    center_lng: 15.2,
    code: 'HR',
    id: 39,
    name: 'Croatia',
    ne_lat: 46.555223,
    ne_lng: 19.448052,
    sw_lat: 42.392265,
    sw_lng: 13.489691,
  },
  {
    center_lat: 21.521757,
    center_lng: -77.781167,
    code: 'CU',
    id: 40,
    name: 'Cuba',
    ne_lat: 23.276752,
    ne_lng: -74.132223,
    sw_lat: 19.825899,
    sw_lng: -85.071247,
  },
  {
    center_lat: 35.126413,
    center_lng: 33.429859,
    code: 'CY',
    id: 41,
    name: 'Cyprus',
    ne_lat: 35.7072,
    ne_lng: 34.6045,
    sw_lat: 34.632303,
    sw_lng: 32.268708,
  },
  {
    center_lat: 49.817492,
    center_lng: 15.472962,
    code: 'CZ',
    id: 42,
    name: 'Czech Republic',
    ne_lat: 51.055719,
    ne_lng: 18.859236,
    sw_lat: 48.551808,
    sw_lng: 12.090589,
  },
  {
    center_lat: 56.1554671,
    center_lng: 10.4309955,
    code: 'DK',
    id: 43,
    name: 'Denmark',
    ne_lat: 57.751813,
    ne_lng: 15.197281,
    sw_lat: 54.464597,
    sw_lng: 8.072241,
  },
  {
    center_lat: 11.825138,
    center_lng: 42.590275,
    code: 'DJ',
    id: 44,
    name: 'Djibouti',
    ne_lat: 12.713396,
    ne_lng: 43.416973,
    sw_lat: 10.931944,
    sw_lng: 41.759722,
  },
  {
    center_lat: 15.414999,
    center_lng: -61.370976,
    code: 'DM',
    id: 45,
    name: 'Dominica',
    ne_lat: 15.640064,
    ne_lng: -61.240303,
    sw_lat: 15.207682,
    sw_lng: -61.47983,
  },
  {
    center_lat: -8.874217,
    center_lng: 125.727539,
    code: 'TL',
    id: 46,
    name: 'Timor-Leste',
    ne_lat: -8.126807,
    ne_lng: 127.341635,
    sw_lat: -9.504195,
    sw_lng: 124.041738,
  },
  {
    center_lat: -1.831239,
    center_lng: -78.183406,
    code: 'EC',
    id: 47,
    name: 'Ecuador',
    ne_lat: 1.687211,
    ne_lng: -75.188794,
    sw_lat: -5.014351,
    sw_lng: -92.013048,
  },
  {
    center_lat: 26.820553,
    center_lng: 30.802498,
    code: 'EG',
    id: 48,
    name: 'Egypt',
    ne_lat: 31.671535,
    ne_lng: 36.894545,
    sw_lat: 22.0,
    sw_lng: 24.696775,
  },
  {
    center_lat: 13.794185,
    center_lng: -88.89653,
    code: 'SV',
    id: 49,
    name: 'El Salvador',
    ne_lat: 14.450557,
    ne_lng: -87.683752,
    sw_lat: 13.155431,
    sw_lng: -90.12681,
  },
  {
    center_lat: 15.179384,
    center_lng: 39.782334,
    code: 'ER',
    id: 50,
    name: 'Eritrea',
    ne_lat: 18.02121,
    ne_lng: 43.142977,
    sw_lat: 12.354723,
    sw_lng: 36.433348,
  },
  {
    center_lat: 58.595272,
    center_lng: 25.013607,
    code: 'EE',
    id: 51,
    name: 'Estonia',
    ne_lat: 59.685685,
    ne_lng: 28.210139,
    sw_lat: 57.509316,
    sw_lng: 21.764313,
  },
  {
    center_lat: 9.145,
    center_lng: 40.489673,
    code: 'ET',
    id: 52,
    name: 'Ethiopia',
    ne_lat: 14.894215,
    ne_lng: 48.0,
    sw_lat: 3.404136,
    sw_lng: 32.997734,
  },
  {
    center_lat: -17.713371,
    center_lng: 178.065032,
    code: 'FJ',
    id: 53,
    name: 'Fiji',
    ne_lat: -12.479535,
    ne_lng: -178.230107,
    sw_lat: -20.66943,
    sw_lng: 176.909494,
  },
  {
    center_lat: 61.92411,
    center_lng: 25.748151,
    code: 'FI',
    id: 54,
    name: 'Finland',
    ne_lat: 70.092293,
    ne_lng: 31.5871,
    sw_lat: 59.70544,
    sw_lng: 20.547411,
  },
  {
    center_lat: 46.227638,
    center_lng: 2.213749,
    code: 'FR',
    id: 55,
    name: 'France',
    ne_lat: 51.089166,
    ne_lng: 9.560068,
    sw_lat: 41.342328,
    sw_lng: -5.141228,
  },
  {
    center_lat: -0.803689,
    center_lng: 11.609444,
    code: 'GA',
    id: 56,
    name: 'Gabon',
    ne_lat: 2.318109,
    ne_lng: 14.520556,
    sw_lat: -3.958372,
    sw_lng: 8.699053,
  },
  {
    center_lat: 13.443182,
    center_lng: -15.310139,
    code: 'GM',
    id: 57,
    name: 'The Gambia',
    ne_lat: 13.826389,
    ne_lng: -13.798611,
    sw_lat: 13.065183,
    sw_lng: -16.813631,
  },
  {
    center_lat: 32.165622,
    center_lng: -82.900075,
    code: 'GE',
    id: 58,
    name: 'Georgia',
    ne_lat: 35.000659,
    ne_lng: -80.840787,
    sw_lat: 30.355591,
    sw_lng: -85.605165,
  },
  {
    center_lat: 51.165691,
    center_lng: 10.451526,
    code: 'DE',
    id: 59,
    name: 'Germany',
    ne_lat: 55.058347,
    ne_lng: 15.041896,
    sw_lat: 47.270112,
    sw_lng: 5.866342,
  },
  {
    center_lat: 7.946527,
    center_lng: -1.023194,
    code: 'GH',
    id: 60,
    name: 'Ghana',
    ne_lat: 11.166668,
    ne_lng: 1.199363,
    sw_lat: 4.738874,
    sw_lng: -3.260786,
  },
  {
    center_lat: 39.074208,
    center_lng: 21.824312,
    code: 'GR',
    id: 61,
    name: 'Greece',
    ne_lat: 41.748878,
    ne_lng: 29.645148,
    sw_lat: 34.801021,
    sw_lng: 19.372423,
  },
  {
    center_lat: 12.1165,
    center_lng: -61.679,
    code: 'GD',
    id: 62,
    name: 'Grenada',
    ne_lat: 12.530183,
    ne_lng: -61.377997,
    sw_lat: 11.984873,
    sw_lng: -61.802728,
  },
  {
    center_lat: 15.783471,
    center_lng: -90.230759,
    code: 'GT',
    id: 63,
    name: 'Guatemala',
    ne_lat: 17.815697,
    ne_lng: -88.225615,
    sw_lat: 13.740021,
    sw_lng: -92.231836,
  },
  {
    center_lat: 9.945587,
    center_lng: -9.696645,
    code: 'GN',
    id: 64,
    name: 'Guinea',
    ne_lat: 12.674617,
    ne_lng: -7.637853,
    sw_lat: 7.190909,
    sw_lng: -15.078206,
  },
  {
    center_lat: 11.803749,
    center_lng: -15.180413,
    code: 'GW',
    id: 65,
    name: 'Guinea-Bissau',
    ne_lat: 12.684723,
    ne_lng: -13.627504,
    sw_lat: 10.85997,
    sw_lng: -16.711736,
  },
  {
    center_lat: 4.860416,
    center_lng: -58.93018,
    code: 'GY',
    id: 66,
    name: 'Guyana',
    ne_lat: 8.546628,
    ne_lng: -56.49112,
    sw_lat: 1.164724,
    sw_lng: -61.414905,
  },
  {
    center_lat: 18.971187,
    center_lng: -72.285215,
    code: 'HT',
    id: 67,
    name: 'Haiti',
    ne_lat: 20.089614,
    ne_lng: -71.621754,
    sw_lat: 18.022078,
    sw_lng: -74.48091,
  },
  {
    center_lat: 15.199999,
    center_lng: -86.241905,
    code: 'HN',
    id: 68,
    name: 'Honduras',
    ne_lat: 17.417104,
    ne_lng: -83.127534,
    sw_lat: 12.984225,
    sw_lng: -89.355148,
  },
  {
    center_lat: 47.162494,
    center_lng: 19.503304,
    code: 'HU',
    id: 69,
    name: 'Hungary',
    ne_lat: 48.585234,
    ne_lng: 22.898122,
    sw_lat: 45.737089,
    sw_lng: 16.113387,
  },
  {
    center_lat: 64.963051,
    center_lng: -19.020835,
    code: 'IS',
    id: 70,
    name: 'Iceland',
    ne_lat: 66.566318,
    ne_lng: -13.495815,
    sw_lat: 63.296102,
    sw_lng: -24.546524,
  },
  {
    center_lat: 20.593684,
    center_lng: 78.96288,
    code: 'IN',
    id: 71,
    name: 'India',
    ne_lat: 35.504475,
    ne_lng: 97.395555,
    sw_lat: 6.753516,
    sw_lng: 68.162386,
  },
  {
    center_lat: -0.789275,
    center_lng: 113.921327,
    code: 'ID',
    id: 72,
    name: 'Indonesia',
    ne_lat: 6.076912,
    ne_lng: 141.019562,
    sw_lat: -11.007436,
    sw_lng: 95.009707,
  },
  {
    center_lat: 32.427908,
    center_lng: 53.688046,
    code: 'IR',
    id: 73,
    name: 'Iran',
    ne_lat: 39.781676,
    ne_lng: 63.333337,
    sw_lat: 25.059429,
    sw_lng: 44.031891,
  },
  {
    center_lat: 33.223191,
    center_lng: 43.679291,
    code: 'IQ',
    id: 74,
    name: 'Iraq',
    ne_lat: 37.380932,
    ne_lng: 48.575916,
    sw_lat: 29.061208,
    sw_lng: 38.793603,
  },
  {
    center_lat: 53.41291,
    center_lng: -8.24389,
    code: 'IE',
    id: 75,
    name: 'Ireland',
    ne_lat: 55.38849,
    ne_lng: -5.9947,
    sw_lat: 51.421938,
    sw_lng: -10.66958,
  },
  {
    center_lat: 31.046051,
    center_lng: 34.851612,
    code: 'IL',
    id: 76,
    name: 'Israel',
    ne_lat: 33.332805,
    ne_lng: 35.896244,
    sw_lat: 29.4797,
    sw_lng: 34.267387,
  },
  {
    center_lat: 41.87194,
    center_lng: 12.56738,
    code: 'IT',
    id: 77,
    name: 'Italy',
    ne_lat: 47.092,
    ne_lng: 18.520502,
    sw_lat: 35.49292,
    sw_lng: 6.62672,
  },
  {
    center_lat: 18.109581,
    center_lng: -77.297508,
    code: 'JM',
    id: 78,
    name: 'Jamaica',
    ne_lat: 18.52531,
    ne_lng: -76.183159,
    sw_lat: 17.705724,
    sw_lng: -78.368846,
  },
  {
    center_lat: 36.204824,
    center_lng: 138.252924,
    code: 'JP',
    id: 79,
    name: 'Japan',
    ne_lat: 45.522772,
    ne_lng: 153.98743,
    sw_lat: 24.046045,
    sw_lng: 122.93383,
  },
  {
    center_lat: 30.585164,
    center_lng: 36.238414,
    code: 'JO',
    id: 80,
    name: 'Jordan',
    ne_lat: 33.374688,
    ne_lng: 39.301154,
    sw_lat: 29.185036,
    sw_lng: 34.958337,
  },
  {
    center_lat: 48.019573,
    center_lng: 66.923684,
    code: 'KZ',
    id: 81,
    name: 'Kazakhstan',
    ne_lat: 55.441984,
    ne_lng: 87.315415,
    sw_lat: 40.568584,
    sw_lng: 46.493672,
  },
  {
    center_lat: -0.023559,
    center_lng: 37.906193,
    code: 'KE',
    id: 82,
    name: 'Kenya',
    ne_lat: 5.033421,
    ne_lng: 41.906832,
    sw_lat: -4.679682,
    sw_lng: 33.909821,
  },
  {
    center_lat: 1.870919,
    center_lng: -157.362601,
    code: 'KI',
    id: 83,
    name: 'Kiribati',
    ne_lat: 4.699959,
    ne_lng: -150.196032,
    sw_lat: -11.446519,
    sw_lng: 169.521532,
  },
  {
    center_lat: 40.339852,
    center_lng: 127.510093,
    code: 'KP',
    id: 84,
    name: 'North Korea',
    ne_lat: 43.01159,
    ne_lng: 130.6885,
    sw_lat: 37.673332,
    sw_lng: 124.173553,
  },
  {
    center_lat: 35.907757,
    center_lng: 127.766922,
    code: 'KR',
    id: 85,
    name: 'South Korea',
    ne_lat: 38.616931,
    ne_lng: 130.923218,
    sw_lat: 33.10611,
    sw_lng: 124.608139,
  },
  {
    center_lat: 42.581372,
    center_lng: 20.889336,
    code: 'XK',
    id: 86,
    name: 'Kosovo',
    ne_lat: 43.268899,
    ne_lng: 21.789867,
    sw_lat: 41.857641,
    sw_lng: 20.014284,
  },
  {
    center_lat: 29.31166,
    center_lng: 47.481766,
    code: 'KW',
    id: 87,
    name: 'Kuwait',
    ne_lat: 30.103699,
    ne_lng: 48.430458,
    sw_lat: 28.524446,
    sw_lng: 46.55304,
  },
  {
    center_lat: 41.20438,
    center_lng: 74.766098,
    code: 'KG',
    id: 88,
    name: 'Kyrgyzstan',
    ne_lat: 43.265357,
    ne_lng: 80.226559,
    sw_lat: 39.180254,
    sw_lng: 69.250998,
  },
  {
    center_lat: 19.85627,
    center_lng: 102.495496,
    code: 'LA',
    id: 89,
    name: 'Laos',
    ne_lat: 22.502872,
    ne_lng: 107.69483,
    sw_lat: 13.90972,
    sw_lng: 100.083214,
  },
  {
    center_lat: 56.879635,
    center_lng: 24.603189,
    code: 'LV',
    id: 90,
    name: 'Latvia',
    ne_lat: 58.085569,
    ne_lng: 28.241403,
    sw_lat: 55.674777,
    sw_lng: 20.962343,
  },
  {
    center_lat: 33.854721,
    center_lng: 35.862285,
    code: 'LB',
    id: 91,
    name: 'Lebanon',
    ne_lat: 34.69209,
    ne_lng: 36.62372,
    sw_lat: 33.055026,
    sw_lng: 35.103778,
  },
  {
    center_lat: -29.609988,
    center_lng: 28.233608,
    code: 'LS',
    id: 92,
    name: 'Lesotho',
    ne_lat: -28.570801,
    ne_lng: 29.455709,
    sw_lat: -30.675579,
    sw_lng: 27.011231,
  },
  {
    center_lat: 6.428055,
    center_lng: -9.429499,
    code: 'LR',
    id: 93,
    name: 'Liberia',
    ne_lat: 8.551986,
    ne_lng: -7.369255,
    sw_lat: 4.315414,
    sw_lng: -11.474248,
  },
  {
    center_lat: 26.3351,
    center_lng: 17.228331,
    code: 'LY',
    id: 94,
    name: 'Libya',
    ne_lat: 33.166787,
    ne_lng: 25.146954,
    sw_lat: 19.50043,
    sw_lng: 9.391466,
  },
  {
    center_lat: 47.166,
    center_lng: 9.555373,
    code: 'LI',
    id: 95,
    name: 'Liechtenstein',
    ne_lat: 47.270547,
    ne_lng: 9.63565,
    sw_lat: 47.04829,
    sw_lng: 9.47162,
  },
  {
    center_lat: 55.169438,
    center_lng: 23.881275,
    code: 'LT',
    id: 96,
    name: 'Lithuania',
    ne_lat: 56.450321,
    ne_lng: 26.835591,
    sw_lat: 53.896879,
    sw_lng: 20.954368,
  },
  {
    center_lat: 49.815273,
    center_lng: 6.129583,
    code: 'LU',
    id: 97,
    name: 'Luxembourg',
    ne_lat: 50.18282,
    ne_lng: 6.53097,
    sw_lat: 49.447779,
    sw_lng: 5.73567,
  },
  {
    center_lat: -18.766947,
    center_lng: 46.869107,
    code: 'MG',
    id: 98,
    name: 'Madagascar',
    ne_lat: -11.951964,
    ne_lng: 50.48378,
    sw_lat: -25.606572,
    sw_lng: 43.185139,
  },
  {
    center_lat: -13.254308,
    center_lng: 34.301525,
    code: 'MW',
    id: 99,
    name: 'Malawi',
    ne_lat: -9.367154,
    ne_lng: 35.924166,
    sw_lat: -17.135278,
    sw_lng: 32.678889,
  },
  {
    center_lat: 4.210484,
    center_lng: 101.975766,
    code: 'MY',
    id: 100,
    name: 'Malaysia',
    ne_lat: 7.363468,
    ne_lng: 119.269362,
    sw_lat: 0.853821,
    sw_lng: 99.640573,
  },
  {
    center_lat: 1.977247,
    center_lng: 73.536103,
    code: 'MV',
    id: 101,
    name: 'Maldives',
    ne_lat: 7.10628,
    ne_lng: 73.759654,
    sw_lat: -0.703585,
    sw_lng: 72.638581,
  },
  {
    center_lat: 17.570692,
    center_lng: -3.996166,
    code: 'ML',
    id: 102,
    name: 'Mali',
    ne_lat: 25.000012,
    ne_lng: 4.266667,
    sw_lat: 10.147811,
    sw_lng: -12.238885,
  },
  {
    center_lat: 35.937496,
    center_lng: 14.375416,
    code: 'MT',
    id: 103,
    name: 'Malta',
    ne_lat: 36.082224,
    ne_lng: 14.5755,
    sw_lat: 35.805811,
    sw_lng: 14.183548,
  },
  {
    center_lat: 7.131474,
    center_lng: 171.184478,
    code: 'MH',
    id: 104,
    name: 'Marshall Islands',
    ne_lat: 14.673255,
    ne_lng: 172.170181,
    sw_lat: 4.572956,
    sw_lng: 160.797959,
  },
  {
    center_lat: 21.00789,
    center_lng: -10.940835,
    code: 'MR',
    id: 105,
    name: 'Mauritania',
    ne_lat: 27.294445,
    ne_lng: -4.833334,
    sw_lat: 14.721273,
    sw_lng: -17.070134,
  },
  {
    center_lat: -20.348404,
    center_lng: 57.552152,
    code: 'MU',
    id: 106,
    name: 'Mauritius',
    ne_lat: -10.336323,
    ne_lng: 63.503594,
    sw_lat: -20.525512,
    sw_lng: 56.514367,
  },
  {
    center_lat: 23.634501,
    center_lng: -102.552784,
    code: 'MX',
    id: 107,
    name: 'Mexico',
    ne_lat: 32.718763,
    ne_lng: -86.710571,
    sw_lat: 14.534549,
    sw_lng: -118.36443,
  },
  {
    center_lat: 6.887351,
    center_lng: 158.215072,
    code: 'FM',
    id: 108,
    name: 'Micronesia',
    ne_lat: 10.090378,
    ne_lng: 163.035591,
    sw_lat: 3.822442,
    sw_lng: 138.054982,
  },
  {
    center_lat: 47.411631,
    center_lng: 28.369885,
    code: 'MD',
    id: 109,
    name: 'Moldova',
    ne_lat: 48.491944,
    ne_lng: 30.162538,
    sw_lat: 45.466904,
    sw_lng: 26.616856,
  },
  {
    center_lat: 43.738418,
    center_lng: 7.424616,
    code: 'MC',
    id: 110,
    name: 'Monaco',
    ne_lat: 43.751903,
    ne_lng: 7.439811,
    sw_lat: 43.724743,
    sw_lng: 7.409105,
  },
  {
    center_lat: 46.862496,
    center_lng: 103.846656,
    code: 'MN',
    id: 111,
    name: 'Mongolia',
    ne_lat: 52.148697,
    ne_lng: 119.931949,
    sw_lat: 41.58152,
    sw_lng: 87.73762,
  },
  {
    center_lat: 42.708678,
    center_lng: 19.37439,
    code: 'ME',
    id: 112,
    name: 'Montenegro',
    ne_lat: 43.558743,
    ne_lng: 20.357765,
    sw_lat: 41.849731,
    sw_lng: 18.433792,
  },
  {
    center_lat: 31.791702,
    center_lng: -7.09262,
    code: 'MA',
    id: 113,
    name: 'Morocco',
    ne_lat: 35.922507,
    ne_lng: -0.996976,
    sw_lat: 27.666667,
    sw_lng: -13.172891,
  },
  {
    center_lat: -18.665695,
    center_lng: 35.529562,
    code: 'MZ',
    id: 114,
    name: 'Mozambique',
    ne_lat: -10.471202,
    ne_lng: 40.839121,
    sw_lat: -26.868109,
    sw_lng: 30.21555,
  },
  {
    center_lat: -22.95764,
    center_lng: 18.49041,
    code: 'NA',
    id: 115,
    name: 'Namibia',
    ne_lat: -16.963486,
    ne_lng: 25.261752,
    sw_lat: -28.970639,
    sw_lng: 11.724247,
  },
  {
    center_lat: -0.522778,
    center_lng: 166.931503,
    code: 'NR',
    id: 116,
    name: 'Nauru',
    ne_lat: -0.50264,
    ne_lng: 166.958928,
    sw_lat: -0.554189,
    sw_lng: 166.909549,
  },
  {
    center_lat: 28.394857,
    center_lng: 84.124008,
    code: 'NP',
    id: 117,
    name: 'Nepal',
    ne_lat: 30.446945,
    ne_lng: 88.199298,
    sw_lat: 26.347779,
    sw_lng: 80.052222,
  },
  {
    center_lat: 52.132633,
    center_lng: 5.291266,
    code: 'NL',
    id: 118,
    name: 'The Netherlands',
    ne_lat: 53.556021,
    ne_lng: 7.22751,
    sw_lat: 50.750384,
    sw_lng: 3.357962,
  },
  {
    center_lat: -40.900557,
    center_lng: 174.885971,
    code: 'NZ',
    id: 119,
    name: 'New Zealand',
    ne_lat: -29.231342,
    ne_lng: -175.831536,
    sw_lat: -52.619418,
    sw_lng: 165.869437,
  },
  {
    center_lat: 12.865416,
    center_lng: -85.207229,
    code: 'NI',
    id: 120,
    name: 'Nicaragua',
    ne_lat: 15.030276,
    ne_lng: -82.592071,
    sw_lat: 10.708055,
    sw_lng: -87.691069,
  },
  {
    center_lat: 17.607789,
    center_lng: 8.081666,
    code: 'NE',
    id: 121,
    name: 'Niger',
    ne_lat: 23.5,
    ne_lng: 15.999034,
    sw_lat: 11.693756,
    sw_lng: 0.166667,
  },
  {
    center_lat: 9.081999,
    center_lng: 8.675277,
    code: 'NG',
    id: 122,
    name: 'Nigeria',
    ne_lat: 13.885645,
    ne_lng: 14.677981,
    sw_lat: 4.269857,
    sw_lng: 2.676932,
  },
  {
    center_lat: 60.472024,
    center_lng: 8.468946,
    code: 'NO',
    id: 123,
    name: 'Norway',
    ne_lat: 71.185476,
    ne_lng: 31.168268,
    sw_lat: 57.959744,
    sw_lng: 4.500096,
  },
  {
    center_lat: 21.512583,
    center_lng: 55.923255,
    code: 'OM',
    id: 124,
    name: 'Oman',
    ne_lat: 26.405395,
    ne_lng: 59.839397,
    sw_lat: 16.650336,
    sw_lng: 52.000002,
  },
  {
    center_lat: 30.375321,
    center_lng: 69.345116,
    code: 'PK',
    id: 125,
    name: 'Pakistan',
    ne_lat: 37.084107,
    ne_lng: 77.833469,
    sw_lat: 23.694695,
    sw_lng: 60.872972,
  },
  {
    center_lat: 7.51498,
    center_lng: 134.58252,
    code: 'PW',
    id: 126,
    name: 'Palau',
    ne_lat: 8.094023,
    ne_lng: 134.721099,
    sw_lat: 2.812743,
    sw_lng: 131.12011,
  },
  {
    center_lat: 8.537981,
    center_lng: -80.782127,
    code: 'PA',
    id: 127,
    name: 'Panama',
    ne_lat: 9.647779,
    ne_lng: -77.158488,
    sw_lat: 7.203556,
    sw_lng: -83.052241,
  },
  {
    center_lat: -6.314993,
    center_lng: 143.95555,
    code: 'PG',
    id: 128,
    name: 'Papua New Guinea',
    ne_lat: -0.871319,
    ne_lng: 159.492959,
    sw_lat: -11.657861,
    sw_lng: 140.84197,
  },
  {
    center_lat: -23.442503,
    center_lng: -58.443832,
    code: 'PY',
    id: 129,
    name: 'Paraguay',
    ne_lat: -19.287707,
    ne_lng: -54.258562,
    sw_lat: -27.588334,
    sw_lng: -62.638051,
  },
  {
    center_lat: -9.189967,
    center_lng: -75.015152,
    code: 'PE',
    id: 130,
    name: 'Peru',
    ne_lat: -0.038777,
    ne_lng: -68.652329,
    sw_lat: -18.35158,
    sw_lng: -81.328504,
  },
  {
    center_lat: 12.879721,
    center_lng: 121.774017,
    code: 'PH',
    id: 131,
    name: 'Philippines',
    ne_lat: 19.574024,
    ne_lng: 126.604384,
    sw_lat: 4.613444,
    sw_lng: 116.931557,
  },
  {
    center_lat: 51.919438,
    center_lng: 19.145136,
    code: 'PL',
    id: 132,
    name: 'Poland',
    ne_lat: 54.835812,
    ne_lng: 24.145893,
    sw_lat: 49.002025,
    sw_lng: 14.122864,
  },
  {
    center_lat: 39.399872,
    center_lng: -8.224454,
    code: 'PT',
    id: 133,
    name: 'Portugal',
    ne_lat: 42.154205,
    ne_lng: -6.190209,
    sw_lat: 32.40374,
    sw_lng: -31.27533,
  },
  {
    center_lat: 25.354826,
    center_lng: 51.183884,
    code: 'QA',
    id: 134,
    name: 'Qatar',
    ne_lat: 26.183093,
    ne_lng: 51.64326,
    sw_lat: 24.471118,
    sw_lng: 50.750055,
  },
  {
    center_lat: 45.943161,
    center_lng: 24.96676,
    code: 'RO',
    id: 135,
    name: 'Romania',
    ne_lat: 48.265274,
    ne_lng: 29.757101,
    sw_lat: 43.618619,
    sw_lng: 20.261759,
  },
  {
    center_lat: 61.52401,
    center_lng: 105.318756,
    code: 'RU',
    id: 136,
    name: 'Russia',
    ne_lat: 81.858122,
    ne_lng: -169.046727,
    sw_lat: 41.185353,
    sw_lng: 19.640535,
  },
  {
    center_lat: -1.940278,
    center_lng: 29.873888,
    code: 'RW',
    id: 137,
    name: 'Rwanda',
    ne_lat: -1.047572,
    ne_lng: 30.899401,
    sw_lat: -2.83984,
    sw_lng: 28.861755,
  },
  {
    center_lat: -13.759029,
    center_lng: -172.104629,
    code: 'WS',
    id: 138,
    name: 'Samoa',
    ne_lat: -13.440033,
    ne_lng: -171.405859,
    sw_lat: -14.076588,
    sw_lng: -172.803676,
  },
  {
    center_lat: 43.94236,
    center_lng: 12.457777,
    code: 'SM',
    id: 139,
    name: 'San Marino',
    ne_lat: 43.992075,
    ne_lng: 12.516704,
    sw_lat: 43.893681,
    sw_lng: 12.403482,
  },
  {
    center_lat: 23.885942,
    center_lng: 45.079162,
    code: 'SA',
    id: 140,
    name: 'Saudi Arabia',
    ne_lat: 32.154284,
    ne_lng: 55.6667,
    sw_lat: 16.379528,
    sw_lng: 34.548998,
  },
  {
    center_lat: 14.497401,
    center_lng: -14.452362,
    code: 'SN',
    id: 141,
    name: 'Senegal',
    ne_lat: 16.693054,
    ne_lng: -11.348607,
    sw_lat: 12.307289,
    sw_lng: -17.529848,
  },
  {
    center_lat: 44.016521,
    center_lng: 21.005859,
    code: 'RS',
    id: 142,
    name: 'Serbia',
    ne_lat: 46.190032,
    ne_lng: 23.00631,
    sw_lat: 42.231503,
    sw_lng: 18.838522,
  },
  {
    center_lat: -4.679574,
    center_lng: 55.491977,
    code: 'SC',
    id: 143,
    name: 'Seychelles',
    ne_lat: -4.209786,
    ne_lng: 56.294294,
    sw_lat: -10.227033,
    sw_lng: 46.197785,
  },
  {
    center_lat: 8.460555,
    center_lng: -11.779889,
    code: 'SL',
    id: 144,
    name: 'Sierra Leone',
    ne_lat: 9.999972,
    ne_lng: -10.271651,
    sw_lat: 6.899025,
    sw_lng: -13.302007,
  },
  {
    center_lat: 1.352083,
    center_lng: 103.819836,
    code: 'SG',
    id: 145,
    name: 'Singapore',
    ne_lat: 1.470772,
    ne_lng: 104.085557,
    sw_lat: 1.166398,
    sw_lng: 103.605575,
  },
  {
    center_lat: 48.669026,
    center_lng: 19.699024,
    code: 'SK',
    id: 146,
    name: 'Slovakia',
    ne_lat: 49.613805,
    ne_lng: 22.558934,
    sw_lat: 47.731159,
    sw_lng: 16.833182,
  },
  {
    center_lat: 46.151241,
    center_lng: 14.995463,
    code: 'SI',
    id: 147,
    name: 'Slovenia',
    ne_lat: 46.876659,
    ne_lng: 16.596686,
    sw_lat: 45.421674,
    sw_lng: 13.375336,
  },
  {
    center_lat: -9.64571,
    center_lng: 160.156194,
    code: 'SB',
    id: 148,
    name: 'Solomon Islands',
    ne_lat: -6.58924,
    ne_lng: 167.283081,
    sw_lat: -11.863458,
    sw_lng: 155.486241,
  },
  {
    center_lat: 5.152149,
    center_lng: 46.199616,
    code: 'SO',
    id: 149,
    name: 'Somalia',
    ne_lat: 11.988614,
    ne_lng: 51.413029,
    sw_lat: -1.662041,
    sw_lng: 40.994373,
  },
  {
    center_lat: -30.559482,
    center_lng: 22.937506,
    code: 'ZA',
    id: 150,
    name: 'South Africa',
    ne_lat: -22.125387,
    ne_lng: 32.890991,
    sw_lat: -34.833138,
    sw_lng: 16.454436,
  },
  {
    center_lat: 7.963092,
    center_lng: 30.15893,
    code: 'SS',
    id: 151,
    name: 'South Sudan',
    ne_lat: 12.236389,
    ne_lng: 35.948997,
    sw_lat: 3.48898,
    sw_lng: 23.440849,
  },
  {
    center_lat: 40.463667,
    center_lng: -3.74922,
    code: 'ES',
    id: 152,
    name: 'Spain',
    ne_lat: 43.79238,
    ne_lng: 4.327784,
    sw_lat: 27.637789,
    sw_lng: -18.160788,
  },
  {
    center_lat: 7.873054,
    center_lng: 80.771797,
    code: 'LK',
    id: 153,
    name: 'Sri Lanka',
    ne_lat: 9.836001,
    ne_lng: 81.878703,
    sw_lat: 5.919078,
    sw_lng: 79.521983,
  },
  {
    center_lat: 13.909444,
    center_lng: -60.978893,
    code: 'LC',
    id: 154,
    name: 'Saint Lucia',
    ne_lat: 14.110932,
    ne_lng: -60.873098,
    sw_lat: 13.708118,
    sw_lng: -61.079672,
  },
  {
    center_lat: 12.862807,
    center_lng: 30.217636,
    code: 'SD',
    id: 155,
    name: 'Sudan',
    ne_lat: 22.224918,
    ne_lng: 38.584219,
    sw_lat: 9.347221,
    sw_lng: 21.814939,
  },
  {
    center_lat: 3.919305,
    center_lng: -56.027783,
    code: 'SR',
    id: 156,
    name: 'Suriname',
    ne_lat: 6.009283,
    ne_lng: -53.951024,
    sw_lat: 1.837306,
    sw_lng: -58.070506,
  },
  {
    center_lat: -26.522503,
    center_lng: 31.465866,
    code: 'SZ',
    id: 157,
    name: 'Swaziland',
    ne_lat: -25.71852,
    ne_lng: 32.134844,
    sw_lat: -27.317363,
    sw_lng: 30.791094,
  },
  {
    center_lat: 60.128161,
    center_lng: 18.643501,
    code: 'SE',
    id: 158,
    name: 'Sweden',
    ne_lat: 69.059971,
    ne_lng: 24.166809,
    sw_lat: 55.32872,
    sw_lng: 10.963187,
  },
  {
    center_lat: 46.818188,
    center_lng: 8.227512,
    code: 'CH',
    id: 159,
    name: 'Switzerland',
    ne_lat: 47.808455,
    ne_lng: 10.49234,
    sw_lat: 45.81792,
    sw_lng: 5.95608,
  },
  {
    center_lat: 34.802075,
    center_lng: 38.996815,
    code: 'SY',
    id: 160,
    name: 'Syria',
    ne_lat: 37.320569,
    ne_lng: 42.376309,
    sw_lat: 32.311136,
    sw_lng: 35.716596,
  },
  {
    center_lat: 23.69781,
    center_lng: 120.960515,
    code: 'TW',
    id: 161,
    name: 'Taiwan',
    ne_lat: 26.387353,
    ne_lng: 122.006905,
    sw_lat: 20.563791,
    sw_lng: 116.71186,
  },
  {
    center_lat: 38.861034,
    center_lng: 71.276093,
    code: 'TJ',
    id: 162,
    name: 'Tajikistan',
    ne_lat: 41.044367,
    ne_lng: 75.153956,
    sw_lat: 36.67199,
    sw_lng: 67.342012,
  },
  {
    center_lat: -6.369028,
    center_lng: 34.888822,
    code: 'TZ',
    id: 163,
    name: 'Tanzania',
    ne_lat: -0.984397,
    ne_lng: 40.444965,
    sw_lat: -11.761254,
    sw_lng: 29.34,
  },
  {
    center_lat: 15.870032,
    center_lng: 100.992541,
    code: 'TH',
    id: 164,
    name: 'Thailand',
    ne_lat: 20.465143,
    ne_lng: 105.636812,
    sw_lat: 5.612729,
    sw_lng: 97.343396,
  },
  {
    center_lat: 8.619543,
    center_lng: 0.824782,
    code: 'TG',
    id: 165,
    name: 'Togo',
    ne_lat: 11.139496,
    ne_lng: 1.80905,
    sw_lat: 6.112358,
    sw_lng: -0.144042,
  },
  {
    center_lat: -21.178986,
    center_lng: -175.198242,
    code: 'TO',
    id: 166,
    name: 'Tonga',
    ne_lat: -15.566393,
    ne_lng: -173.702484,
    sw_lat: -21.473461,
    sw_lng: -175.679616,
  },
  {
    center_lat: 33.886917,
    center_lng: 9.537499,
    code: 'TN',
    id: 167,
    name: 'Tunisia',
    ne_lat: 37.358287,
    ne_lng: 11.599217,
    sw_lat: 30.228034,
    sw_lng: 7.522313,
  },
  {
    center_lat: 38.963745,
    center_lng: 35.243322,
    code: 'TR',
    id: 168,
    name: 'Turkey',
    ne_lat: 42.106239,
    ne_lng: 44.818128,
    sw_lat: 35.80768,
    sw_lng: 25.663637,
  },
  {
    center_lat: 38.969719,
    center_lng: 59.556278,
    code: 'TM',
    id: 169,
    name: 'Turkmenistan',
    ne_lat: 42.798844,
    ne_lng: 66.707353,
    sw_lat: 35.12876,
    sw_lng: 52.447845,
  },
  {
    center_lat: -7.478442,
    center_lng: 178.679921,
    code: 'TV',
    id: 170,
    name: 'Tuvalu',
    ne_lat: -5.64223,
    ne_lng: 179.872261,
    sw_lat: -10.791659,
    sw_lng: 176.058891,
  },
  {
    center_lat: 1.373333,
    center_lng: 32.290275,
    code: 'UG',
    id: 171,
    name: 'Uganda',
    ne_lat: 4.223001,
    ne_lng: 35.033049,
    sw_lat: -1.481542,
    sw_lng: 29.573433,
  },
  {
    center_lat: 48.379433,
    center_lng: 31.16558,
    code: 'UA',
    id: 172,
    name: 'Ukraine',
    ne_lat: 52.379581,
    ne_lng: 40.228581,
    sw_lat: 44.386463,
    sw_lng: 22.137159,
  },
  {
    center_lat: 55.378051,
    center_lng: -3.435973,
    code: 'GB',
    id: 173,
    name: 'United Kingdom',
    ne_lat: 59.86067,
    ne_lng: 3.976555,
    sw_lat: 49.562515,
    sw_lng: -12.649357,
  },
  {
    center_lat: 39.50024,
    center_lng: -98.350891,
    code: 'US',
    id: 174,
    name: 'United States',
    ne_lat: 49.38024,
    ne_lng: -66.940662,
    sw_lat: 25.820898,
    sw_lng: -124.712891,
  },
  {
    center_lat: -32.522779,
    center_lng: -55.765835,
    code: 'UY',
    id: 175,
    name: 'Uruguay',
    ne_lat: -30.085215,
    ne_lng: -53.184292,
    sw_lat: -35.031419,
    sw_lng: -58.43915,
  },
  {
    center_lat: 41.377491,
    center_lng: 64.585262,
    code: 'UZ',
    id: 176,
    name: 'Uzbekistan',
    ne_lat: 45.590075,
    ne_lng: 73.148946,
    sw_lat: 37.172257,
    sw_lng: 55.998218,
  },
  {
    center_lat: -15.376706,
    center_lng: 166.959158,
    code: 'VU',
    id: 177,
    name: 'Vanuatu',
    ne_lat: -13.072455,
    ne_lng: 170.23846,
    sw_lat: -20.252293,
    sw_lng: 166.541759,
  },
  {
    center_lat: 41.902916,
    center_lng: 12.453389,
    code: 'VA',
    id: 178,
    name: 'Vatican City',
    ne_lat: 41.907561,
    ne_lng: 12.45848,
    sw_lat: 41.900198,
    sw_lng: 12.445687,
  },
  {
    center_lat: 6.42375,
    center_lng: -66.58973,
    code: 'VE',
    id: 179,
    name: 'Venezuela',
    ne_lat: 12.486694,
    ne_lng: -59.805666,
    sw_lat: 0.647529,
    sw_lng: -73.351558,
  },
  {
    center_lat: 14.058324,
    center_lng: 108.277199,
    code: 'VN',
    id: 180,
    name: 'Vietnam',
    ne_lat: 23.393395,
    ne_lng: 109.468975,
    sw_lat: 8.41273,
    sw_lng: 102.14441,
  },
  {
    center_lat: 15.552727,
    center_lng: 48.516388,
    code: 'YE',
    id: 181,
    name: 'Yemen',
    ne_lat: 18.999633,
    ne_lng: 54.533555,
    sw_lat: 12.108166,
    sw_lng: 41.816055,
  },
  {
    center_lat: -13.133897,
    center_lng: 27.849332,
    code: 'ZM',
    id: 182,
    name: 'Zambia',
    ne_lat: -8.203284,
    ne_lng: 33.702222,
    sw_lat: -18.077418,
    sw_lng: 21.996388,
  },
  {
    center_lat: -19.015438,
    center_lng: 29.154857,
    code: 'ZW',
    id: 183,
    name: 'Zimbabwe',
    ne_lat: -15.609319,
    ne_lng: 33.068236,
    sw_lat: -22.424523,
    sw_lng: 25.237368,
  },
] as const;

export type CountryCode = (typeof countries)[number]['code'];
