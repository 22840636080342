import './SliderVertical.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { Icon, isIconName } from '../Icon';
import { Slider } from './Slider';
export interface SliderVerticalProps {
  itemList?: Array<{
    cta?: b2x.contentTypes.CtaContentSection;
  }>;
}

export const SliderVertical = ({ itemList }: SliderVerticalProps) => {
  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <Slider
      autoHeight
      autoplay={itemList && itemList.length > 1 ? { delay: 3000 } : undefined}
      className="slider-vertical"
      loop
      pagination
      slidesPerView={1}
      vertical
    >
      {itemList &&
        itemList.map((item, index) => (
          <SwiperSlide className="d-flex justify-content-center align-items-center small" key={item.cta?.label}>
            {(slideData) =>
              item.cta?.label ? (
                <b2x.DeprecatedCta
                  className={classnames('text-reset text-decoration-none d-flex align-items-center', {
                    'extra-small': b2x.untilBreakpoint('md', currentBreakpoint),
                  })}
                  cta={item.cta}
                >
                  {item.cta.icon && isIconName(item.cta.icon) && (
                    <Icon className="me-1" name={item.cta.icon} size={16} />
                  )}
                  {item.cta.label}
                </b2x.DeprecatedCta>
              ) : (
                <></>
              )
            }
          </SwiperSlide>
        ))}
    </Slider>
  );
};
