import { b2x } from '@b2x/react/src';

import { Box } from '../Box';

export interface LoginUserFormProps {
  handleLoginSuccess?(): void;
  subTitle?: string;
  title?: string;
}

export const LoginUserForm = ({ handleLoginSuccess, subTitle, title }: LoginUserFormProps) => {
  return (
    <Box fullHeight>
      <div className="border-bottom mb-3 border-2">
        <h5 className="text-center mb-3 fw-bold text-uppercase">{b2x.formatHtml(title)}</h5>
      </div>
      <div className="text-center mb-4">{b2x.formatHtml(subTitle)}</div>
      <b2x.LoginForm onSuccess={handleLoginSuccess} />
    </Box>
  );
};
