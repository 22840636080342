import React from 'react';

import { useAppContext } from './AppContext';
import { appConfig } from './config';
import { createUUID } from './util';

export interface FutureFashion3DViewerProps {
  backgroundImage?: string;
  productId: string;
}

export const FutureFashion3DViewer = ({ backgroundImage, productId }: FutureFashion3DViewerProps) => {
  const { locale } = useAppContext();
  const title = React.useMemo(() => `future-fashion-3d-viewer-${createUUID()}`, []);

  return (
    <div
      className="product-3d-viewer w-100"
      style={{
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    >
      <iframe
        allowFullScreen
        height={'500px'}
        src={`https://3dviewer.futurefashionsolution.com/?customerId=${
          appConfig.futureFashion3DViewer?.customerId
        }&productId=${productId}&lang=${locale?.languageCode ?? 'en'}`}
        title={title}
        width={'100%'}
      />
    </div>
  );
};
