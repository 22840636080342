import { b2x } from '@b2x/react/src';

import { OffcanvasHeader } from './Offcanvas';

interface MobileSimpleSearchOffcanvasProps extends b2x.OffcanvasProps {}

const MobileSimpleSearchOffcanvas = ({ ...offcanvasProps }: MobileSimpleSearchOffcanvasProps) => {
  return (
    <b2x.Offcanvas placement="top" {...offcanvasProps}>
      {({ close }) => (
        <>
          <OffcanvasHeader title="Search" />
          <b2x.OffcanvasBody className="d-flex align-items-end">
            <b2x.SimpleSearchForm className="flex-grow-1" onSuccess={close} />
          </b2x.OffcanvasBody>
        </>
      )}
    </b2x.Offcanvas>
  );
};

export const useMobileSimpleSearchOffcanvas = (props?: MobileSimpleSearchOffcanvasProps) =>
  b2x.useStaticModal(MobileSimpleSearchOffcanvas, 'offcanvas', props);
