import classnames from 'classnames';

import { Button } from '../Button';
import { Span } from '../HTMLElement';
import { Icon } from '../Icon';
import { uiClassName } from '../util';
import { useStoreLocatorContext } from './StoreLocatorContext';

export interface FiltersToggleProps {
  className?: string;
  filterCount: number;
  toggleFiltersPanel(): void;
}

export const FiltersToggle = ({ className, filterCount, toggleFiltersPanel }: FiltersToggleProps) => {
  const { filtersToggleButtonVariant, iconFilter, iconsSize, variantPrimary } = useStoreLocatorContext();

  return (
    <Button
      className={classnames('filters-toggle', className, uiClassName({ bs5: 'position-relative' }))}
      onClick={toggleFiltersPanel}
      variant={filtersToggleButtonVariant}
    >
      <Icon name={iconFilter} size={iconsSize} />
      {filterCount > 0 && (
        <Span
          className={uiClassName({
            bs5: 'position-absolute top-0 start-100 translate-middle bg-primary rounded-circle d-flex align-items-center justify-content-center',
          })}
          style={{ height: 20, width: 20 }}
        >
          <Span className={uiClassName({ bs5: `lh-1 small fw-bold text-${variantPrimary}` })}>{filterCount}</Span>
        </Span>
      )}
    </Button>
  );
};
