import './SearchPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppContext } from '../AppContext';
import { Breadcrumb } from '../Breadcrumb';
// import React from 'react';
import { Button } from '../Button';
import { Container } from '../Container';
import { SearchPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { useMobileSearchFiltersOffcanvas } from '../MobileSearchFiltersOffcanvas';
import { SliderB } from '../slider/SliderB';
//import { SearchFilterSlider } from '../slider/SearchFilterSlider';
import { ErrorSearchPage } from './ErrorSearchPage';
import { Page } from './Page';

export interface SearchPageProps {}

export const SearchPage = (props: SearchPageProps) => {
  const { page, searchResult } = b2x.useSearch<SearchPageContentType>({
    defaultPageSize: 12,
    pageOptions: {
      populate: {
        breadcrumb: true,
        children: true,
        content: true,
      },
    },
  });

  const [MobileSearchFiltersOffcanvas, showMobileSearchFiltersOffcanvas] = useMobileSearchFiltersOffcanvas({
    searchResult: searchResult,
  });

  const [mobileColumn, setMobileColumn] = React.useState<string>('multiple');

  const handleSetMobileListingColumn = React.useCallback((column: 'single' | 'multiple') => {
    setMobileColumn(column);
  }, []);

  const { getPage } = b2x.usePagesApi();

  const [filterSliderItems, setFilterSliderItems] = React.useState<Array<b2x.PageApiDto>>();

  React.useEffect(() => {
    // if (page?.breadcrumb && page.breadcrumb.length > 2) {
    //   if (page.children && page.children.length > 0) {
    //     setFilterSliderItems(page.children);
    //   } else {
    //     const parent = page.breadcrumb[page.breadcrumb.length - 2];

    //     getPage(parent.id, { populate: { children: true } }).then((response) => {
    //       const parentChildren = response.data.children;

    //       if (parentChildren) {
    //         const indexOfElementToMove = parentChildren.findIndex((el) => el.code === page.code);
    //         const elementToMove = parentChildren[indexOfElementToMove];

    //         parentChildren.splice(indexOfElementToMove, 1);
    //         parentChildren.unshift(elementToMove);

    //         setFilterSliderItems(parentChildren);
    //       }
    //     });
    //   }
    // } else {
    //   setFilterSliderItems(undefined);
    // }

    if (page?.breadcrumb && page.breadcrumb.length > 1) {
      if (page.children && page.children.length > 0) {
        setFilterSliderItems(page.children);
      } else {
        const parent = page.breadcrumb[page.breadcrumb.length - 2];

        getPage(parent.id, { populate: { children: true } }).then((response) => {
          const parentChildren = response.data.children;

          if (parentChildren) {
            const indexOfElementToMove = parentChildren.findIndex((el) => el.code === page.code);
            const elementToMove = parentChildren[indexOfElementToMove];

            parentChildren.splice(indexOfElementToMove, 1);
            parentChildren.unshift(elementToMove);

            setFilterSliderItems(parentChildren);
          }
        });
      }
    } else {
      setFilterSliderItems(undefined);
    }
  }, [filterSliderItems, getPage, page?.breadcrumb, page?.children, page?.code]);

  const { headerHeight } = useAppContext();
  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <>
      {searchResult?.itemsCount === 0 ? (
        <ErrorSearchPage />
      ) : (
        <Page className="search-page" noPaddingBottom noPaddingTop>
          {MobileSearchFiltersOffcanvas}
          <section
            className={classnames('search-header text-center bg-gray-100 pt-4 pt-lg-5 mb-3', {
              'pb-4 pb-lg-5': !filterSliderItems,
            })}
          >
            <Container>
              <b2x.Div display={'flex'} justifyContent={'center'}>
                <Breadcrumb />
              </b2x.Div>
              <b2x.Div className={classnames({ 'mb-3 mb-lg-4': filterSliderItems })}>
                <h1 className="text-uppercase fw-bold">
                  {page?.content?.body.title ? page.content.body.title : page?.name}
                </h1>
                {page?.content?.body.descriptions?.short && (
                  <p className="m-0">{b2x.formatHtml(page.content.body.descriptions.short)}</p>
                )}
              </b2x.Div>
            </Container>
            <div className="px-xl-5">{filterSliderItems && <SliderB itemList={filterSliderItems} />}</div>
          </section>
          <section className="search-content">
            <Container fluid="always">
              <div className="px-xl-5 position-relative">
                <b2x.Row>
                  <b2x.Col size={{ lg: 3, xs: 12 }}>
                    <div
                      className="search-filters-container mb-3 mb-lg-5 pt-4 pe-lg-3"
                      style={
                        !b2x.untilBreakpoint('md', currentBreakpoint)
                          ? {
                              height: `calc(100vh - ${headerHeight}px)`,
                              top: headerHeight,
                            }
                          : undefined
                      }
                    >
                      <b2x.SearchFormHelper searchResult={searchResult} submitOnChange>
                        {({ fieldsHelper, formik }) => (
                          <>
                            <div className="d-grid d-sm-block d-lg-none mb-3">
                              <Button
                                className="fw-bold px-2"
                                onClick={showMobileSearchFiltersOffcanvas}
                                type="button"
                                variant="outline-secondary"
                              >
                                <div className="d-flex justify-content-between align-items-center w-100">
                                  <div className="me-3">
                                    <Icon className="me-2" name="filter" size={20} />
                                    {t('misc.filterAndOrderBy')}
                                  </div>
                                  {fieldsHelper.activeFilters.length > 0 && (
                                    <div
                                      className="bg-black text-white rounded-circle px-2 extra-small d-flex align-items-center justify-content-center"
                                      style={{ height: '20px', width: '20px' }}
                                    >
                                      {fieldsHelper.activeFilters.length > 1
                                        ? fieldsHelper.activeFilters.length - 1
                                        : fieldsHelper.activeFilters.length}
                                    </div>
                                  )}
                                </div>
                              </Button>
                            </div>
                            <b2x.Div className="search-form-active-filter d-none d-lg-block mb-4">
                              {fieldsHelper.activeFilters.length > 0 && (
                                <b2x.Row gap={2}>
                                  {fieldsHelper.activeFilters.map((activeFilter, index) => (
                                    <b2x.Col key={activeFilter.filter.name + activeFilter.filter.id} size={'auto'}>
                                      {activeFilter.fromSimpleSearch && (
                                        <small className="py-1">{t('misc.youSearchedFor')}</small>
                                      )}
                                      <div>
                                        <Button
                                          className={classnames('btn-sm px-2 fw-bold gap-3')}
                                          iconEnd={{ name: 'delete', size: 14 }}
                                          justifyContent="between"
                                          label={activeFilter.filter.name}
                                          onClick={activeFilter.handleClick}
                                          type="button"
                                          variant="outline-secondary"
                                        />
                                      </div>
                                    </b2x.Col>
                                  ))}
                                </b2x.Row>
                              )}
                            </b2x.Div>
                            <div className="mb-3">
                              <b2x.SearchFilters fieldsHelper={fieldsHelper} />
                            </div>
                          </>
                        )}
                      </b2x.SearchFormHelper>
                      {page?.content?.body.descriptions?.long && (
                        <div className="long-description d-none d-lg-block small">
                          <b2x.ShowMore content={page.content.body.descriptions.long} truncateTo={500} />
                        </div>
                      )}
                    </div>
                  </b2x.Col>
                  <b2x.Col size={{ lg: 9, xs: 12 }}>
                    {searchResult?.itemsCount && (
                      <b2x.Row className="justify-content-end mb-3 small">
                        <b2x.Col size={'auto'}>
                          <div className="text-end mb-3">
                            {t('misc.searchItemResult', { count: searchResult.itemsCount })}
                          </div>
                          <SortingOptionsDropdown className="d-none d-lg-block" searchResult={searchResult} />
                          <div className="d-block d-md-none">
                            <div className="hstack gap-2">
                              <Button
                                className="p-2"
                                iconStart={{ name: 'two-column-display', size: 14 }}
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() => handleSetMobileListingColumn('multiple')}
                                variant="blank"
                              />
                              <div className="vr"></div>
                              <Button
                                className="p-2"
                                iconStart={{ name: 'one-column-display', size: 14 }}
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() => handleSetMobileListingColumn('single')}
                                variant="blank"
                              />
                            </div>
                          </div>
                        </b2x.Col>
                      </b2x.Row>
                    )}
                    {searchResult && searchResult.items && (
                      <b2x.Listing name="Search page" products={searchResult.items}>
                        <b2x.ProductsTiles
                          className="mb-5"
                          enableExtraInfo
                          gap={{ lg: 4, xs: 2 }}
                          products={searchResult.items.map((product) => ({ product: product }))}
                          productsPerRow={{
                            lg: 3,
                            md: 3,
                            sm: mobileColumn === 'multiple' ? 2 : 1,
                            xl: 3,
                            xs: mobileColumn === 'multiple' ? 2 : 1,
                            xxl: 3,
                          }}
                        />
                      </b2x.Listing>
                    )}
                    {searchResult && (
                      <b2x.Div className="mb-5" display="flex" justifyContent={{ lg: 'end', xs: 'center' }}>
                        <b2x.Pagination
                          currentPage={searchResult.pageNumber}
                          pageOffset={2}
                          showDots
                          singleStepNavigation
                          totalPages={searchResult.pagesCount}
                        />
                      </b2x.Div>
                    )}
                    {page?.content?.body.descriptions?.long && (
                      <div className="long-description extra-small mb-5 d-lg-none">
                        <b2x.ShowMore content={page.content.body.descriptions.long} truncateTo={500} />
                      </div>
                    )}
                  </b2x.Col>
                </b2x.Row>
              </div>
            </Container>
          </section>
        </Page>
      )}
    </>
  );
};

interface SortingOptionsDropdownProps {
  className?: string;
  searchResult?: b2x.SearchProductsApiDto;
}

const SortingOptionsDropdown = ({ className, searchResult }: SortingOptionsDropdownProps) => {
  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
  ]);

  return (
    <>
      {searchResult && (
        <b2x.Dropdown
          buttonClassname="btn-sm px-2 gap-3 fw-bold"
          className={classnames('order-dropdown ', className)}
          iconEnd={{ name: 'filter', size: 20 }}
          label={b2x.formatHtml(
            `${t('misc.orderBy')}: ${sortingOptions.find((sortingItem) => sortingItem.active)?.label}`
          )}
          variant="outline-secondary"
        >
          {sortingOptions.map((sortingOption) => (
            <b2x.DropdownItem active={sortingOption.active} key={sortingOption.label} onClick={sortingOption.onClick}>
              <span>{sortingOption.label}</span>
            </b2x.DropdownItem>
          ))}
        </b2x.Dropdown>
      )}
    </>
  );
};
