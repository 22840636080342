import { b2x } from '@b2x/react/src';

import { AuthUserSection } from '../auth/AuthUserSection';
import { RegistrationFormContentType } from '../contentTypes';
import { Page } from './Page';

export interface RegistrationPageProps {}

export const RegistrationPage = (props: RegistrationPageProps) => {
  const content = b2x.useContent<RegistrationFormContentType>('REGISTRATION_FORM_CONTENT');
  return (
    <Page greyBackground thingsToLoadBeforePageReady={[content]}>
      <AuthUserSection signUp={content?.body} />
    </Page>
  );
};
