import { FilterApiDto, MenuApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { FormikHelpers, FormikProps } from 'formik';
import qs from 'qs';
import React from 'react';
import * as yup from 'yup';

import { Button } from '../Button';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { useNavigate, useSearchParams } from '../router/router';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { TextInput, TextInputProps } from './fields/Input';
import { Checkbox, CheckboxProps } from './fields/RadioCheck';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface ShopSearchFormProps extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  menu: MenuApiDto | undefined;
  scrollTopOnFiltersChange?: boolean;
  submitOnChange?: boolean;
}

interface FormValues {
  facets: Array<formikString>;
  simpleSearch: formikString;
}

export type ShopSearchFormValues = FormValues;

type ValidationSchema = {
  facets: yup.ArraySchema<yup.StringSchema>;
  simpleSearch: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  activeFilters: Array<ShopSearchActiveFilter>;
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  filters: Array<ShopSearchFilter>;
  simpleSearch: { formGroup: FormGroupProps; textInput: TextInputProps };
}

export type ShopSearchFieldsHelper = FieldsHelper;

export interface ShopSearchFilter {
  checkbox: CheckboxProps;
  children: Array<ShopSearchFilter>;
  filter: Omit<FilterApiDto, 'children'>;
}

export interface ShopSearchActiveFilter {
  filter: Omit<FilterApiDto, 'children'>;
  fromSimpleSearch: boolean;
  handleClick(): void;
}

const Filters = ({ filters }: { filters: Array<ShopSearchFilter> }) => {
  return (
    <>
      {filters.length > 0 && (
        <ul>
          {filters.map((filter) => (
            <li key={filter.filter.id}>
              <Checkbox
                {...filter.checkbox}
                label={`${filter.checkbox.label} (${filter.filter.hits}/${filter.filter.total} )`}
              />
              {<Filters filters={filter.children} />}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export const ShopSearchFormHelper = ({
  children,
  className,
  initialValues,
  menu,
  onCancel,
  onSuccess,
  scrollTopOnFiltersChange = true,
  submitOnChange = false,
  ...otherProps
}: ShopSearchFormProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  /*
    In attesa dei filtri relativi alla ricerca lato API, lo accrocco lato client.
    In questo modo posso usare la stella logica del SearchForm dei prodotti.
    Lo faccio a partire dal menu (MenuApiDto) e i searchParam attivi.
    Ora per semplicità gestisco solo una lista piatta di filtri di tipo FACET (singolo livello).
  */

  const searchResultFilters = React.useMemo<Array<FilterApiDto>>(() => {
    const facets = searchParams.getAll('facets');

    return menu
      ? menu.children.reduce<Array<FilterApiDto>>((previousValue, currentValue) => {
          previousValue.push({
            checkType: currentValue.children.some((child) => facets.includes(child.id))
              ? 'CHILD_CHECKED'
              : 'NOT_CHECKED',
            children: currentValue.children.map((child) => ({
              checkType: facets.includes(child.id) ? 'CHECKED' : 'NOT_CHECKED',
              code: child.code ?? '',
              depth: 1,
              description: child.description,
              id: child.id,
              image: child.image,
              name: child.label,
              note: child.note,
              type: 'FACET',
            })),
            code: currentValue.code ?? '',
            depth: 0,
            description: currentValue.description,
            id: currentValue.id,
            image: currentValue.image,
            name: currentValue.label,
            note: currentValue.note,
            type: 'FACET',
          });
          return previousValue;
        }, [])
      : [];
  }, [menu, searchParams]);

  const activeFilters = React.useMemo<Array<FilterApiDto>>(() => {
    const f = (filters?: Array<FilterApiDto>, depth = 0) => {
      if (filters) {
        return filters.reduce<Array<FilterApiDto>>((previousValue, currentValue) => {
          if ((depth > 0 || currentValue.code !== 'FC_SEARCH') && currentValue.checkType === 'CHECKED') {
            previousValue.push(currentValue);
          }
          const x: Array<FilterApiDto> = f(currentValue.children, depth + 1);
          if (x.length > 0) {
            previousValue.push(...x);
          }
          return previousValue;
        }, []);
      } else {
        return [];
      }
    };
    return f(searchResultFilters);
  }, [searchResultFilters]);

  const _initialValues = React.useMemo<FormValues>(() => {
    return {
      facets: activeFilters
        .filter((activeFilter) => activeFilter.type === 'FACET')
        .map((activeFilter) => activeFilter.id),
      simpleSearch: getInitialString(searchParams.get('simpleSearch') ?? undefined),
    };
  }, [activeFilters, searchParams]);

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      facets: yup.array(),
      simpleSearch: yup.string(),
    }),
    []
  );

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const fixedValues = {
        ...values,
        facets: values.facets,
        pageSize: -1,
      };
      navigate(
        `/?${qs.stringify(fixedValues, {
          indices: false,
          skipNulls: true,
        })}`,
        {
          scrollTop: scrollTopOnFiltersChange,
        }
      );
      onSuccess && onSuccess();
      return Promise.resolve();
    },
    [navigate, onSuccess, scrollTopOnFiltersChange]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('shop-search-form', className)}
      enableReinitialize
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      submitOnChange={false}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          activeFilters: buildActiveFilters(activeFilters, searchParams, formik),
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.shopSearchForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.shopSearchForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik) || !formik.dirty,
              label: t('form.shopSearchForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          filters: buildFilters(formik, submitOnChange, searchResultFilters),
          simpleSearch: {
            formGroup: { label: 'Simple search', names: ['simpleSearch'] },
            textInput: { name: 'simpleSearch', placeholder: 'Simple search' },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.simpleSearch.formGroup}>
              <TextInput {...fieldsHelper.simpleSearch.textInput} />
            </FormGroup>
            <Filters filters={fieldsHelper.filters} />
            <div className="mb-3">
              {fieldsHelper.activeFilters.map((activeFilter) => (
                <Button
                  className="me-2"
                  key={activeFilter.filter.id}
                  label={activeFilter.filter.name}
                  onClick={activeFilter.handleClick}
                />
              ))}
            </div>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

const buildFilters = (
  formik: FormikProps<FormValues>,
  submitOnChange: boolean,
  filters?: Array<FilterApiDto>
): Array<ShopSearchFilter> =>
  filters
    ? filters
        .filter((filter) => filter.depth > 0 || filter.code !== 'FC_SEARCH')
        .map((filter) => {
          const { children, ...otherFilterProps } = filter;
          return {
            checkbox: {
              id: filter.code,
              inhibitFormikOnChange: true,
              label: filter.name,
              name: filter.type === 'FACET' ? 'facets' : '',
              onChange: () => {
                if (filter.type === 'FACET') {
                  // Se non c'era lo aggiungo
                  if (!formik.values.facets.includes(filter.id)) {
                    formik.setFieldValue('facets', formik.values.facets.concat(filter.id));
                  } else {
                    // Se no lo levo
                    formik.setFieldValue(
                      'facets',
                      formik.values.facets.filter((id) => id !== filter.id)
                    );
                  }
                }
                if (submitOnChange) {
                  formik.submitForm();
                }
              },
              value: filter.id,
            },
            children: buildFilters(formik, submitOnChange, children),
            filter: { ...otherFilterProps },
          };
        })
    : [];

const buildActiveFilters = (
  activeFilters: Array<FilterApiDto>,
  searchParams: URLSearchParams,
  formik: FormikProps<FormValues>
): Array<ShopSearchActiveFilter> => {
  const result: Array<ShopSearchActiveFilter> = [];
  const simpleSearch: string | undefined = searchParams.get('simpleSearch') ?? undefined;

  if (simpleSearch) {
    result.push({
      filter: {
        checkType: 'CHECKED',
        code: '',
        depth: 0,
        id: '0',
        name: simpleSearch,
      },
      fromSimpleSearch: true,
      handleClick: () => {
        formik.setFieldValue('simpleSearch', '');
        formik.submitForm();
      },
    });
  }

  result.push(
    ...activeFilters.map((activeFilter) => ({
      filter: { ...activeFilter, children: undefined },
      fromSimpleSearch: false,
      handleClick: () => {
        if (activeFilter.type === 'FACET') {
          formik.setFieldValue(
            'facets',
            formik.values.facets.filter((filter) => filter !== activeFilter.id)
          );
        }
        formik.submitForm();
      },
    }))
  );

  if (result.length >= 2) {
    result.push({
      filter: {
        checkType: 'CHECKED',
        code: '',
        depth: 0,
        id: '0',
        name: t('misc.removeFilters'),
      },
      fromSimpleSearch: false,
      handleClick: () => {
        formik.setFieldValue('facets', []);
        formik.setFieldValue('simpleSearch', '');
        formik.submitForm();
      },
    });
  }
  return result;
};

export type ShopSearchFormVariants = '';

const ShopSearchFormController = (props: PropsWithCustomComponentWithoutChildren<ShopSearchFormProps>) => (
  <VariantsController<ShopSearchFormProps, ShopSearchFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ShopSearchFormHelper,
      name: 'ShopSearchForm',
    }}
  />
);
export { ShopSearchFormController as ShopSearchForm };
