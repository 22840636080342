import React from 'react';

import { t } from './i18n/i18n';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, ModalProps } from './Modal';
import { useStaticModal } from './useModal';

interface GeolocationModalProps extends ModalProps {
  onPermissionGranted(position: GeolocationPosition): void;
}

const GeolocationModal = ({ onPermissionGranted, ...modalProps }: GeolocationModalProps) => {
  const handleClose = React.useCallback(() => {
    navigator.geolocation.getCurrentPosition(onPermissionGranted);
  }, [onPermissionGranted]);

  return (
    <Modal {...modalProps} onClose={handleClose}>
      <ModalHeader closable={false} title={t('modal.geolocation.title')} />
      <ModalBody>
        <p className="lh-sm">{t('modal.geolocation.content', { storeName: t('misc.storeName') })}</p>
      </ModalBody>
      <ModalFooter>
        <div className="d-grid w-100">
          <ModalButton close label="Chiudi" />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export const useGeolocationModal = (props: GeolocationModalProps) => useStaticModal(GeolocationModal, 'modal', props);
