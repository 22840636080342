import './CustomRadioCheck.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

export type CustomRadioCheckProps = b2x.CustomRadioCheckProps;

export const CustomRadioCheck = ({ color, image, label }: CustomRadioCheckProps) => {
  return (
    <div className="custom-radio-check d-flex align-items-center gap-2">
      {(color || image) && (
        <div className={classnames('custom-radio-check-image')}>
          <div style={{ backgroundColor: color }}>{image && <b2x.Image fluid format={64} {...image} />}</div>
        </div>
      )}
      <div className="custom-radio-check-label fw-bold">{label}</div>
    </div>
  );
};
