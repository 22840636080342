import { b2x } from '@b2x/react/src';

import { NewsletterPageContentType } from '../contentTypes';
import { NewsletterForm } from '../forms/NewsletterForm';
import { Page } from './Page';

export interface NewsletterSubscribePageProps {}

export const NewsletterSubscribePage = () => {
  const content = b2x.useContent<NewsletterPageContentType>('NEWSLETTER_PAGE_CONTENT');

  return (
    <Page noPaddingTop>
      <div className="container-fluid">
        <div className="row gx-0">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6 px-0">
                <b2x.ImageFromContentV1 {...content?.body.img} fluid />
              </div>
              <div className="col-lg-6 px-0">
                <div className="bg-gray-100 h-100 px-3 py-3 py-lg-0 d-lg-flex align-items-center">
                  <div className="pe-lg-5">
                    <div className="row justify-content-center">
                      <div className="col-lg-12 col-xl-10 px-lg-5">
                        <h1 className="text-primary h2">{b2x.formatHtml(content?.body.title)}</h1>
                        <div className="my-3">{b2x.formatHtml(content?.body.content)}</div>
                        <NewsletterForm className="mb-3" emailFieldStyle="textInput" source="website-modal" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
