import './MobileSearchFiltersOffcanvas.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import { OffcanvasHeader } from './Offcanvas';

interface MobileSearchFiltersOffcanvasProps extends b2x.OffcanvasProps {
  searchResult?: b2x.SearchProductsApiDto;
}

const MobileSearchFiltersOffcanvas = ({ searchResult, ...offcanvasProps }: MobileSearchFiltersOffcanvasProps) => {
  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
  ]);
  return (
    <b2x.Offcanvas {...offcanvasProps} className="mobile-search-filter-offcanvas">
      {({ close }) => (
        <>
          <OffcanvasHeader smallPadding title={t('misc.filter')} />
          <b2x.OffcanvasBody className="pt-0 px-3">
            <b2x.SearchFormHelper className="position-relative h-100" searchResult={searchResult} submitOnChange>
              {({ fieldsHelper, formik }) => {
                return (
                  <b2x.Drawers itemsClassName="px-3 py-1">
                    <div className="bg-gray-100 mt-3 p-3">
                      <div className="small mb-2">{t('misc.orderBy')}</div>
                      <div className="d-flex gap-2 flex-wrap ">
                        {sortingOptions.map((sortingOption) => (
                          <Button
                            className="btn-sm text-nowrap text-lowercase fw-normal px-2"
                            key={sortingOption.label}
                            label={sortingOption.label}
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={() => {
                              sortingOption.onClick();
                              close();
                            }}
                            variant={sortingOption.active ? 'outline-secondary' : 'outline-secondary'}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="p-3 mb-0 small">{t('misc.filterYourSearchResults')}</p>
                    </div>
                    {fieldsHelper.activeFilters.length > 0 && (
                      <div className="border-bottom">
                        <b2x.Row gap={0}>
                          {fieldsHelper.activeFilters.map((activeFilter, index) => (
                            <b2x.Col key={activeFilter.filter.name + activeFilter.filter.id} size={'auto'}>
                              {activeFilter.fromSimpleSearch && (
                                <small className="py-1">{t('misc.youSearchedFor')}</small>
                              )}
                            </b2x.Col>
                          ))}
                        </b2x.Row>
                      </div>
                    )}
                    <DrawerSearchFiltersItems close={close} items={fieldsHelper.filters} />
                  </b2x.Drawers>
                );
              }}
            </b2x.SearchFormHelper>
          </b2x.OffcanvasBody>
        </>
      )}
    </b2x.Offcanvas>
  );
};

interface DrawerBackItemProps {
  label?: string;
}

const DrawerBackItem = ({ label = 'Indietro' }: DrawerBackItemProps) => {
  const { closeActiveDrawer } = b2x.useDrawersContext();

  return (
    <b2x.DrawerButtonItem onClick={closeActiveDrawer}>
      <b2x.DrawerItem
        center={{ center: label }}
        className="extra-small gap-3"
        end={{ center: <Icon className="invisible" name="arrow-sx" /> }}
        height={35}
        justifyContent="between"
        start={{ center: <Icon name="arrow-sx" /> }}
      />
    </b2x.DrawerButtonItem>
  );
};

interface DrawerSearchFiltersItemsProps {
  close(): void;
  items: Array<b2x.SearchFilter>;
  previousItem?: b2x.SearchFilter;
}

const DrawerSearchFiltersItems = ({ close, items, previousItem }: DrawerSearchFiltersItemsProps) => {
  return (
    <>
      {items.map((item) => {
        const hasChildren = item.children.length > 0;
        const checkedChildren = item.children.filter((child) => child.filter.checkType === 'CHECKED');

        return hasChildren ? (
          <b2x.DrawerTriggerItem
            key={item.filter.id}
            newDrawer={
              <Drawer backItemProps={{ label: item.filter.name }}>
                <DrawerSearchFiltersItems close={close} items={item.children} previousItem={item} />
              </Drawer>
            }
          >
            <b2x.DrawerItem
              className={classnames('small')}
              end={{ center: <Icon name="arrow-dx" /> }}
              height={50}
              justifyContent="between"
              start={{
                bottom:
                  checkedChildren.length > 0 ? (
                    <div className="extra-small">{checkedChildren.map((child) => child.filter.name).join(', ')}</div>
                  ) : undefined,
                top: (
                  <span
                    className={classnames(
                      item.filter.checkType === 'CHECKED' || item.filter.checkType === 'CHILD_CHECKED'
                        ? 'fw-bold'
                        : 'fw-normal'
                    )}
                  >
                    {item.filter.name}
                  </span>
                ),
              }}
            />
          </b2x.DrawerTriggerItem>
        ) : (
          <b2x.DrawerCheckboxItem key={item.filter.id} {...item.checkbox}>
            <b2x.DrawerItem
              className={classnames('small border-bottom', {
                'fw-bold': item.filter.checkType === 'CHECKED',
              })}
              end={{
                center: item.filter.checkType === 'CHECKED' ? <Icon name="delete" /> : undefined,
              }}
              height={50}
              justifyContent="between"
              start={{ center: <CustomDrawerItem item={item} /> }}
            />
          </b2x.DrawerCheckboxItem>
        );
      })}
      {previousItem && (
        <b2x.DrawerCheckboxItem {...previousItem.checkbox}>
          <b2x.DrawerItem
            className={classnames('small', {
              'fw-bold': previousItem.filter.checkType === 'CHECKED',
            })}
            end={{
              center: previousItem.filter.checkType === 'CHECKED' ? <Icon name="delete" /> : undefined,
            }}
            height={50}
            justifyContent="between"
            start={{ center: 'Seleziona tutto' }}
          />
        </b2x.DrawerCheckboxItem>
      )}
    </>
  );
};

interface DrawerProps {
  backItemProps?: DrawerBackItemProps;
  children: React.ReactNode;
}

const Drawer = ({ backItemProps, children }: DrawerProps) => {
  return (
    <div className="d-flex flex-column h-100 overflow-auto">
      <DrawerBackItem {...backItemProps} />
      <div className="bg-white flex-grow-1 overflow-auto">{children}</div>
    </div>
  );
};

export const useMobileSearchFiltersOffcanvas = (props?: MobileSearchFiltersOffcanvasProps) =>
  b2x.useStaticModal(MobileSearchFiltersOffcanvas, 'offcanvas', props);

interface CustomDrawerItemProps {
  item: b2x.SearchFilter;
}

const CustomDrawerItem = ({ item }: CustomDrawerItemProps) => {
  const colorFromTag = item.filter.tags?.find((tag) => tag.includes('COLOR_'))?.substring(6, 13);

  return (
    <div className="hstack gap-2">
      {(colorFromTag || item.filter.image) && (
        <div className="rounded-pill overflow-hidden" style={{ backgroundColor: colorFromTag, height: 25, width: 25 }}>
          {item.filter.image && <b2x.Image {...item.filter.image} fluid />}
        </div>
      )}
      <div>{item.filter.name}</div>
      {item.filter.checkType === 'CHECKED' && (
        <div>
          <Icon name="check" size={14} />
        </div>
      )}
    </div>
  );
};
