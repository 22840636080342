import classnames from 'classnames';

import { useAppContext } from './AppContext';
import { appConfig } from './config';
import { Container } from './Container';
import { Fetching } from './Fetching';
import { HeaderCheckoutA } from './HeaderCheckoutA';
import { Div } from './HTMLElement';
import { t } from './i18n/i18n';
import { Icon } from './Icon';
import { Image } from './Image';
import { Breakpoint } from './interfaces';
import { Link } from './router/Link';
import { useBreakpoint } from './useBreakpoint';
import { formatCurrency, untilBreakpoint } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface HeaderCheckoutProps {
  desktopCenterItemStyle?: string;
  desktopRightItemStyle?: string;
  isSticky?: boolean;
  logo: string;
  logoSmall: string;
  mobileCenterItemStyle?: string;
  mobileFrom: Breakpoint;
  recalculateHeaderHeight(): void;
  wrapperStyle?: string;
}

const HeaderCheckout = ({
  desktopCenterItemStyle,
  desktopRightItemStyle,
  isSticky,
  logo,
  logoSmall,
  mobileCenterItemStyle,
  mobileFrom,
  recalculateHeaderHeight,
  wrapperStyle,
}: HeaderCheckoutProps) => {
  const { session, shippingCountry } = useAppContext();
  const currentBreakpoint = useBreakpoint();

  return (
    <>
      <Div className={'header-checkout'} uiClassName={{ bs5: classnames({ 'sticky-top': isSticky }) }}>
        <Div
          className={classnames(wrapperStyle, 'header-checkout-wrapper')}
          paddingY={2}
          uiClassName={{ bs5: 'shadow-sm' }}
        >
          <Container>
            {!untilBreakpoint(mobileFrom, currentBreakpoint) ? (
              <Div className="header-checkout-desktop">
                <Div alignItems={'center'} display={'flex'} justifyContent={'spaceBetween'}>
                  <Div style={{ flex: '1 1 0' }}>
                    <Link to="/">
                      <Image className="logo" fluid onLoad={recalculateHeaderHeight} src={logo} />
                    </Link>
                  </Div>
                  <Div
                    className={classnames(desktopCenterItemStyle, 'center-item')}
                    style={{ flex: '1 1 0' }}
                    textAlign={'center'}
                  >
                    {session?.cart?.itemsNumber && (
                      <Fetching item={session.cart.itemsNumber}>
                        {(count) => t('header.cartSummary', { count: count })}
                      </Fetching>
                    )}
                  </Div>
                  <Div
                    className={classnames(desktopRightItemStyle, 'free-shipping-from')}
                    style={{ flex: '1 1 0' }}
                    textAlign={'end'}
                  >
                    {session?.cart?.shippingProfile?.freeThreshold &&
                      t('header.freeShippingFrom', {
                        from: formatCurrency(session.cart.shippingProfile.freeThreshold),
                        locale: shippingCountry?.name,
                      })}
                  </Div>
                </Div>
              </Div>
            ) : (
              <Div className="header-checkout-mobile">
                <Div
                  className={classnames(mobileCenterItemStyle, 'free-shipping-from')}
                  marginBottom={2}
                  textAlign={'center'}
                >
                  {session?.cart?.shippingProfile?.freeThreshold &&
                    t('header.freeShippingFrom', {
                      from: formatCurrency(session.cart.shippingProfile.freeThreshold),
                      locale: shippingCountry?.name,
                    })}
                </Div>
                <Div textAlign={'center'}>
                  <Link to="/">
                    <Image className="logo" fluid onLoad={recalculateHeaderHeight} src={logoSmall} />
                  </Link>
                </Div>
              </Div>
            )}
          </Container>
        </Div>
      </Div>
      <Div className="check-out-back">
        <Container>
          <Div display={'flex'} paddingY={3}>
            <Link className="checkout-back-link" to="/cart">
              <Div display={'flex'} gap={1}>
                {appConfig.icons?.arrow?.left && (
                  <div>
                    <Icon name={appConfig.icons.arrow.left} />
                  </div>
                )}
                <div>{t('checkout.backToCart')}</div>
              </Div>
            </Link>
          </Div>
        </Container>
      </Div>
    </>
  );
};

export type HeaderCheckoutVariants = 'A';

const HeaderCheckoutController = (props: PropsWithCustomComponent<HeaderCheckoutProps>) => (
  <VariantsController<HeaderCheckoutProps, HeaderCheckoutVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: HeaderCheckoutA },
      defaultComponent: HeaderCheckout,
      name: 'HeaderCheckout',
    }}
  />
);
export { HeaderCheckoutController as HeaderCheckout };
