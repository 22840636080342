import './FloatingActionButtonContainer.scss';

import classnames from 'classnames';
import React from 'react';

import { useFloatingActionButtonContext } from './FloatingActionButtonContext';

export interface FloatingActionButtonContainerProps {
  children?: React.ReactNode;
  className?: string;
  direction?: 'row' | 'column';
  position?: 'top start' | 'top end' | 'bottom start' | 'bottom end';
}

export const FloatingActionButtonContainer = ({
  children,
  className,
  direction = 'column',
  position = 'bottom start',
}: FloatingActionButtonContainerProps) => {
  const { offset } = useFloatingActionButtonContext();

  return (
    <div
      className={classnames('floating-action-button-container', className)}
      style={{
        bottom: position.includes('bottom') ? offset : undefined,
        left: position.includes('start') ? 0 : undefined,
        right: position.includes('end') ? 0 : undefined,
        top: position.includes('top') ? offset : undefined,
      }}
    >
      <div
        style={{
          bottom: position.includes('bottom') ? 0 : undefined,
          display: 'flex',
          flexDirection: direction,
          gap: '0.5rem',
          left: position.includes('start') ? 0 : undefined,
          padding: '0.5rem',
          position: 'absolute',
          right: position.includes('end') ? 0 : undefined,
          top: position.includes('top') ? 0 : undefined,
        }}
      >
        {children}
      </div>
    </div>
  );
};
