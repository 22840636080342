import './AmbassadorList.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { AmbassadorTile } from './AmbassadorTile';
import { Container } from './Container';
import { AmbassadorsPageContentType } from './contentTypes';

export interface AmbassadorListProps {
  ambassadorsPerRow?: Partial<Record<b2x.Breakpoint, 1 | 2 | 3 | 4>>;
  exclude?: string;
}

export const AmbassadorList = ({
  ambassadorsPerRow = { lg: 4, md: 3, sm: 2, xl: 4, xs: 2, xxl: 4 },
  exclude,
}: AmbassadorListProps) => {
  const breakpoint = b2x.useBreakpoint();
  let tempAmbassadorPerRow = 2;

  if (breakpoint === 'xs') {
    tempAmbassadorPerRow = ambassadorsPerRow.xs ? ambassadorsPerRow.xs : tempAmbassadorPerRow;
  }
  if (breakpoint === 'sm') {
    tempAmbassadorPerRow = ambassadorsPerRow.sm ? ambassadorsPerRow.sm : tempAmbassadorPerRow;
  }
  if (breakpoint === 'md') {
    tempAmbassadorPerRow = ambassadorsPerRow.md ? ambassadorsPerRow.md : tempAmbassadorPerRow;
  }
  if (breakpoint === 'lg') {
    tempAmbassadorPerRow = ambassadorsPerRow.lg ? ambassadorsPerRow.lg : tempAmbassadorPerRow;
  }
  if (breakpoint === 'xl') {
    tempAmbassadorPerRow = ambassadorsPerRow.xl ? ambassadorsPerRow.xl : tempAmbassadorPerRow;
  }
  if (breakpoint === 'xxl') {
    tempAmbassadorPerRow = ambassadorsPerRow.xxl ? ambassadorsPerRow.xxl : tempAmbassadorPerRow;
  }

  return (
    <div className="ambassador-list">
      <b2x.EqualHeight timeout={50}>
        {(equalHeightContext) => (
          <AmbassadorListWrapper
            ambassadorsPerRow={ambassadorsPerRow}
            equalHeightContext={equalHeightContext}
            exclude={exclude}
            tempAmbassadorPerRow={tempAmbassadorPerRow}
          />
        )}
      </b2x.EqualHeight>
    </div>
  );
};

interface AmbassadorListWrapperProps extends AmbassadorListProps {
  equalHeightContext: b2x.EqualHeightContextInterface;
  tempAmbassadorPerRow: number;
}

const AmbassadorListWrapper = ({
  ambassadorsPerRow,
  equalHeightContext,
  exclude,
  tempAmbassadorPerRow,
}: AmbassadorListWrapperProps) => {
  const page = b2x.usePageByCode<AmbassadorsPageContentType>('SITE_AMBASSADOR', {
    populate: {
      children: {
        content: true,
      },
      content: true,
    },
  });
  const windowSize = b2x.useWindowSize();
  const windowHeight = windowSize.height;
  const windowWidth = windowSize.width;

  const equalHeightStable = b2x.useStable(equalHeightContext);

  React.useEffect(() => {
    equalHeightStable.updateTrigger();
  }, [tempAmbassadorPerRow, equalHeightStable, windowWidth, windowHeight]);

  return (
    <Container>
      {page?.children?.length && page.children.length > 0 && (
        <b2x.Row cols={ambassadorsPerRow} gap={{ lg: 4, md: 3, xs: 2 }}>
          {page.children.map(
            (ambassador, index) =>
              exclude !== ambassador.id && (
                <b2x.Col key={ambassador.id}>
                  <AmbassadorTile
                    ambassadorsPerRow={Math.floor((index + 0) / tempAmbassadorPerRow)}
                    page={ambassador}
                  />
                </b2x.Col>
              )
          )}
        </b2x.Row>
      )}
    </Container>
  );
};
