/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { env } from '../env';
import { AnalyticsEventsProps, calculateCompleteSkuName, InitCommonProps } from './analytics';

type FBQ = (action: 'init' | 'track', eventOrPixelId: EventName | PixelId, data?: EventData) => void;

declare global {
  interface Window {
    _fbq: FBQ;
    fbq: FBQ;
  }
}

let initialized = false;
let debug = false;

type PixelId = string;

interface InitProps extends InitCommonProps {
  id?: string;
  sendPageView: boolean;
}

export type FacebookPixelInitProps = InitProps;

export const init = (props?: InitProps): void => {
  const id = props?.id ?? env.REACT_APP_FACEBOOK_PIXEL_ID;
  debug = props?.debug || false;
  if (id) {
    if (!initialized) {
      initialized = true;
      install(id, props?.sendPageView);
    }
  }
};

type EventName =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'CompleteRegistration'
  | 'Contact'
  | 'CustomizeProduct'
  | 'Donate'
  | 'FindLocation'
  | 'InitiateCheckout'
  | 'Lead'
  | 'PageView'
  | 'Purchase'
  | 'Schedule'
  | 'Search'
  | 'StartTrial'
  | 'SubmitApplication'
  | 'Subscribe'
  | 'ViewContent';

interface EventData {
  content_category?: string;
  content_ids?: Array<string>;
  content_name?: string;
  content_type?: 'product' | 'product_group';
  contents?: Array<{ id: string; item_price: number; name: string; quantity: number }>;
  currency?: string;
  num_items?: number;
  predicted_ltv?: number;
  search_string?: string;
  status?: boolean;
  value?: number;
}

const install = (pixelId: string, sendPageView = false) => {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  (function (f, b, e, v) {
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions, @typescript-eslint/no-explicit-any
    const n: any = (f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    });
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    const t = b.createElement(e) as HTMLScriptElement;
    t.async = !0;
    t.src = v;
    const s: Element = b.getElementsByTagName(e)[0];
    s.parentNode?.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', pixelId);
  if (sendPageView) {
    fbq('track', 'PageView');
  }
};

const fbq: FBQ = (action, eventOrPixelId, data) => {
  if (initialized) {
    log(action, eventOrPixelId, data);
    window.fbq(action, eventOrPixelId, data);
  } else {
    log('Blocked because not initialized.', action, eventOrPixelId, data);
  }
};

const event = (name: EventName, data?: EventData) => {
  fbq('track', name, data);
};

const log = (message?: unknown, ...optionalParams: Array<unknown>) => {
  if (debug) {
    console.log('analytics', 'facebookPixel:', message, ...optionalParams);
  }
};

export const facebookPixel: {
  events: AnalyticsEventsProps;
  init(props?: InitProps): void;
} = {
  events: {
    addPaymentInfo: (props) => {
      // Capire se presente in facebookPixel
    },
    addShippingInfo: (props) => {
      // Capire se presente in facebookPixel
    },
    addToCart: (props) => {
      event('AddToCart', {
        content_ids: [props.product.sku.id],
        content_name: calculateCompleteSkuName(props.product.name, props.product.sku.name),
        content_type: 'product',
        contents: [
          {
            id: props.product.sku.id,
            item_price: props.product.sku.price.value,
            name: calculateCompleteSkuName(props.product.name, props.product.sku.name),
            quantity: props.product.quantity,
          },
        ],
        currency: 'EUR',
        value: props.product.sku.price.value,
      });
    },
    addToWishlist: (props) => {
      event('AddToWishlist', {
        content_category: props.product.categories?.at(-1)?.name,
        content_ids: [props.product.sku.id],
        content_name: calculateCompleteSkuName(props.product.name, props.product.sku.name),
        contents: [
          {
            id: props.product.sku.id,
            item_price: props.product.sku.price.value,
            name: calculateCompleteSkuName(props.product.name, props.product.sku.name),
            quantity: 1,
          },
        ],
        currency: 'EUR',
        value: props.product.sku.price.value,
      });
    },
    checkoutInit: (props) => {
      // Assente in facebookPixel
    },
    completeRegistration: (props) => {
      event('CompleteRegistration', {
        content_name: props.origin,
        // currency: '',
        status: true,
        // value: 0,
      });
    },
    contact: (props) => {
      event('Contact');
    },
    customizeProduct: (props) => {
      event('CustomizeProduct');
    },
    donate: (props) => {
      event('Donate');
    },
    exception: (props) => {
      // Assente in facebookPixel
    },
    findLocation: () => {
      event('FindLocation');
    },
    lead: (props) => {
      event('Lead', {
        // content_category: '',
        content_name: props.origin,
        // currency: '',
        // value: 0,
      });
    },
    login: (props) => {
      // Assente in facebookPixel
    },
    pageView: (props) => {
      event('PageView');
    },
    purchase: (props) => {
      event('Purchase', {
        content_ids: props.products.map((product) => product.sku.id),
        content_name: props.result,
        content_type: 'product',
        contents: props.products.map((product) => ({
          id: product.sku.id,
          item_price: product.sku.price.value,
          name: calculateCompleteSkuName(product.name, product.sku.name),
          quantity: product.quantity,
        })),
        currency: 'EUR',
        num_items: props.productsNumber,
        value: props.totalCost,
      });
    },
    removeFromCart: (props) => {
      // Assente in facebookPixel
    },
    schedule: (props) => {
      event('Schedule');
    },
    search: (props) => {
      event('Search', {
        // content_category: '',
        // content_ids: [],
        // contents: [],
        // currency: 'EUR',
        search_string: props.simpleSearch,
        // value: 0,
      });
    },
    selectProduct: () => {
      // Da capire se presente in facebookPixel
    },
    startPayment: (props) => {
      event('InitiateCheckout', {
        // content_category: '',
        content_ids: props.products.map((product) => product.sku.id),
        contents: props.products.map((product) => ({
          id: product.sku.id,
          item_price: product.sku.price.value,
          name: calculateCompleteSkuName(product.name, product.sku.name),
          quantity: product.quantity,
        })),
        currency: 'EUR',
        num_items: props.productsNumber,
        value: props.totalCost,
      });
    },
    submitApplication: (props) => {
      event('SubmitApplication');
    },
    viewCart: (props) => {
      // Da capire se presente in facebookPixel
    },
    viewProduct: (props) => {
      event('ViewContent', {
        content_category: props.product.categories?.at(-1)?.name,
        content_ids: props.product.skus.map((sku) => sku.id),
        content_name: props.product.name,
        content_type: 'product',
        contents: props.product.skus.map((sku) => ({
          id: sku.id,
          item_price: sku.price.value,
          name: calculateCompleteSkuName(props.product.name, sku.name),
          quantity: 1,
        })),
        currency: 'EUR',
        value: props.product.fromPrice.value,
      });
    },
    viewProducts: (props) => {
      // Assente in facebookPixel
    },
  },
  init: (props) => {
    init(props);
  },
};
