import './Accordion.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

export interface AccordionProps extends b2x.AccordionProps {}

export const Accordion = ({ className, ...otherProps }: AccordionProps) => {
  return <b2x.Accordion {...otherProps} className={classnames(className)}></b2x.Accordion>;
};
