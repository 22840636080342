import './Header.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType } from './contentTypes';
import { DesktopHeader } from './DesktopHeader';
import { t } from './i18n/i18n';
import { Icon, isIconName } from './Icon';
import logo from './images/logo.svg';
import logoSmall from './images/logo-small.svg';
import { MobileHeader } from './MobileHeader';
import { SliderVertical } from './slider/SliderVertical';

export interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const content = b2x.useContent<HeaderContentType>('HEADER_CONTENT');
  const mobileHeaderRef = React.useRef<HTMLDivElement>(null);
  const desktopHeaderFirstRowRef = React.useRef<HTMLDivElement>(null);
  const { headerCheckout } = useAppContext();

  const { setHeaderHeight } = useAppStaticContext();

  const { width } = b2x.useWindowSize();

  const recalculateHeaderHeight = React.useCallback(() => {
    const currentBreakpoint = b2x.getCurrentBreakpoint();
    if (['xs', 'sm', 'md'].includes(currentBreakpoint)) {
      // Mobile
      if (mobileHeaderRef.current) {
        setHeaderHeight(mobileHeaderRef.current.clientHeight);
      }
    } else {
      // Desktop
      if (desktopHeaderFirstRowRef.current) {
        setHeaderHeight(desktopHeaderFirstRowRef.current.clientHeight);
      }
    }
  }, [desktopHeaderFirstRowRef, mobileHeaderRef, setHeaderHeight]);

  React.useEffect(() => {
    recalculateHeaderHeight();
  }, [recalculateHeaderHeight, width]);

  const [activeCategory, setActiveCategory] = React.useState<b2x.MenuApiDto>();
  const [localeBoxVisible, setLocaleBoxVisible] = React.useState<boolean>(false);

  const toggleLocaleBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setLocaleBoxVisible((prevState) => !prevState);
  }, []);

  return (
    <>
      {!headerCheckout ? (
        <>
          <TopBar content={content} toggleLocaleBoxVisible={toggleLocaleBoxVisible} />
          <MobileHeader
            content={content}
            innerRef={mobileHeaderRef}
            recalculateHeaderHeight={recalculateHeaderHeight}
            visibleUntil="xl"
          />
          <DesktopHeader
            activeCategory={activeCategory}
            content={content}
            firstRowRef={desktopHeaderFirstRowRef}
            localeBoxVisible={localeBoxVisible}
            recalculateHeaderHeight={recalculateHeaderHeight}
            setActiveCategory={setActiveCategory}
            toggleLocaleBoxVisible={toggleLocaleBoxVisible}
            visibleFrom="xl"
          />
        </>
      ) : (
        <b2x.HeaderCheckout
          desktopCenterItemStyle="m-0"
          desktopRightItemStyle="text-white"
          isSticky
          logo={logo}
          logoSmall={logoSmall}
          mobileCenterItemStyle="extra-small fw-light text-whhite text-uppercase mb-3"
          mobileFrom={'lg'}
          recalculateHeaderHeight={recalculateHeaderHeight}
          wrapperStyle="text-white bg-black py-xl-3"
        />
      )}
    </>
  );
};

interface TopBarProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  toggleLocaleBoxVisible(): void;
}

const TopBar = ({ content, toggleLocaleBoxVisible }: TopBarProps) => {
  const { setTopBarDesktopHeight, setTopBarRowRef } = useAppStaticContext();
  const { topBarRowRef } = useAppContext();

  React.useEffect(() => {
    setTopBarDesktopHeight(topBarRowRef.current?.clientHeight);
  }, [setTopBarDesktopHeight, topBarRowRef]);

  return (
    <div className={'top-bar bg-black text-white border-bottom border-gray-300 py-1'} ref={setTopBarRowRef}>
      <Container>
        <div className="hstack justify-content-center justify-content-xl-between align-items-center gap-3">
          <div className="d-none d-xl-flex">
            <LocaleToggle toggleLocaleBoxVisible={toggleLocaleBoxVisible} />
          </div>
          <div className="vr d-none d-xl-block bg-gray-300 opacity-100"></div>
          <div>
            {content?.body.topBar?.messagesList ? (
              <SliderVertical itemList={content.body.topBar.messagesList} />
            ) : (
              <span className="extra-small fw-light text-uppercase">{t('welcome')}</span>
            )}
          </div>
          <div className="vr d-none d-xl-block bg-gray-300 opacity-100"></div>
          <div className="d-none d-xl-flex align-items-center justify-content-end">
            {content?.body.topBar?.rightCta && (
              <b2x.DeprecatedCta className="text-reset text-decoration-none small" cta={content.body.topBar.rightCta}>
                {content.body.topBar.rightCta.icon && isIconName(content.body.topBar.rightCta.icon) && (
                  <Icon className="me-1" name={content.body.topBar.rightCta.icon} size={16} />
                )}
                {content.body.topBar.rightCta.label}
              </b2x.DeprecatedCta>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

interface LocaleToggleProps {
  toggleLocaleBoxVisible(): void;
}

const LocaleToggle = ({ toggleLocaleBoxVisible }: LocaleToggleProps) => {
  const { locale, shippingCountry } = b2x.useAppContext();

  return (
    <Button
      alignItems="center"
      className="text-decoration-none fw-normal text-reset"
      iconStart={{ name: 'language', size: 16 }}
      label={
        locale &&
        shippingCountry && (
          <span>{t('header.sendTo', { country: shippingCountry.name, locale: locale.languageDescriptionLocal })}</span>
        )
      }
      onClick={toggleLocaleBoxVisible}
      size="small"
      variant="blank"
    />
  );
};
