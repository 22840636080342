import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { LoginUserForm, LoginUserFormProps } from './LoginUserForm';
import { SignUpUserForm, SignUpUserFormProps } from './SignUpUserForm';
export interface AuthUserFormProps {
  classNames?: string;
  login?: LoginUserFormProps;
  signUp?: SignUpUserFormProps;
}

export const AuthUserSection = ({ classNames, login, signUp }: AuthUserFormProps) => {
  return (
    <Container className={classNames}>
      <b2x.Row className="justify-content-center">
        {login && (
          <b2x.Col className="pb-4 pb-xl-0" size={{ xl: 5, xs: 12 }}>
            <b2x.Div marginX={{ sm: 0, xs: -3 }}>
              <LoginUserForm {...login} />
            </b2x.Div>
          </b2x.Col>
        )}
        {signUp && (
          <b2x.Col size={{ xl: 5, xs: 12 }}>
            <b2x.Div marginX={{ sm: 0, xs: -3 }}>
              <SignUpUserForm {...signUp} />
            </b2x.Div>
          </b2x.Col>
        )}
      </b2x.Row>
    </Container>
  );
};
