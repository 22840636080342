import { Resource } from '../i18n';

export const it: Resource = {
  misc: {
    ourAmbassadors: 'I nostri ambassador',
    storeName: 'Acavallo',
  },
  product: {
    colorsCount: '',
    colorsCount_one: '{{count}} color',
    colorsCount_other: '{{count}} colors',
    showAllImages: 'Visualizza altre immagini',
    size: 'Taglia',
    technicalFeatures: 'Caratteristiche tecniche',
  },
  welcome: 'Benvenuto su store-acavallo!',
};
