import classNames from 'classnames';
import React from 'react';

export interface BlankButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  innerRef?: React.RefObject<HTMLSpanElement>;
  onClick?(): void;
}

export const BlankButton = ({
  children,
  className,
  disabled,
  innerRef,
  onClick,
  style,
  ...otherProps
}: BlankButtonProps) => {
  const handleClick = React.useCallback(() => {
    if (!disabled) {
      onClick && onClick();
    }
  }, [disabled, onClick]);

  return (
    <span
      {...otherProps}
      className={classNames(className, { disabled: disabled })}
      onClick={handleClick}
      onKeyPress={handleClick}
      ref={innerRef}
      role="button"
      style={{ cursor: disabled ? 'not-allowed' : 'pointer', ...style }}
      tabIndex={0}
    >
      {children}
    </span>
  );
};
