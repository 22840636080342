import './Icon.scss';

import { b2x } from '@b2x/react/src';

export const iconsNames = [
  'filter-map',
  'geolocator-map',
  'locator-map',
  'minus',
  'plus',
  'navigator',
  'sharing',
  'voice-recorder',
  'voice-recorder-on',
  'arrow-dx',
  'arrow-slider-dx',
  'arrow-slider-sx',
  'arrow-sx',
  'arrow-up',
  'award',
  'cart',
  'check',
  'compare',
  'delete',
  'delete-compare',
  'delivery',
  'delivery-rounded',
  'discount',
  'download',
  'email',
  'facebook-rounded',
  'filter',
  'flag',
  'headphone',
  'hidden',
  'info',
  'instagram-rounded',
  'language',
  'locator',
  'lock-rounded',
  'logout',
  'menu',
  'minus',
  'one-column-display',
  'pay',
  'phone',
  'plus',
  'privacy',
  'quote',
  'return',
  'return-rounded',
  'search',
  'size',
  'trash',
  'two-column-display',
  'visible',
  'wishlist',
  'wishlist-full',
  'account',
  'arrow-down',
] as const;
export type IconName = (typeof iconsNames)[number];

export type IconSize = 12 | 14 | 16 | 20 | 24 | 25 | 30 | 35 | 48 | 56 | 72 | 100 | 120;

export interface IconProps extends b2x.IconProps<IconName, IconSize> {}

export const Icon = (props: IconProps) => <b2x.Icon {...props} />;

export const isIconName = (name?: string): name is IconName => b2x.isIconName(iconsNames, name);
