import './FloatingActionButton.scss';

import React from 'react';

export interface FloatingActionButtonProps {
  children?: React.ReactNode;
}

export const FloatingActionButton = ({ children }: FloatingActionButtonProps) => {
  return <div className={'floating-action-button'}>{children}</div>;
};
