import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { t } from './i18n/i18n';
import { Breakpoint } from './interfaces';
import { useWindowSize } from './useWindowSize';
import { formatHtml, getCurrentBreakpoint, renderUI } from './util';

export interface ShowMoreProps {
  activeOnBreakpoint?: Partial<Record<Breakpoint, boolean>>;
  content: string;
  truncateTo: number;
}

export const ShowMore = ({ activeOnBreakpoint, content, truncateTo }: ShowMoreProps) => {
  const [status, setStatus] = React.useState<boolean>(true);
  const [displayContent, setDisplayContent] = React.useState<boolean>(content.length > truncateTo ? false : true);
  const handleShowMoreButtonClick = React.useCallback(() => setDisplayContent(!displayContent), [displayContent]);

  const windowSize = useWindowSize();

  const updateStatus = React.useCallback(() => {
    activeOnBreakpoint?.hasOwnProperty(getCurrentBreakpoint()) && activeOnBreakpoint[getCurrentBreakpoint()]
      ? setStatus(true)
      : setStatus(false);
  }, [activeOnBreakpoint]);

  React.useEffect(() => {
    updateStatus();
  }, [updateStatus, windowSize.width]);

  return renderUI({
    bs5: (
      <>
        <div className="show-more">
          {activeOnBreakpoint && !status ? (
            <div className={classnames('content')}>{formatHtml(content)}</div>
          ) : (
            <>
              <div className={classnames('content', { 'show-more-active': displayContent })}>
                {content.length > truncateTo && !displayContent
                  ? formatHtml(
                      content
                        .replace(/<\/?[^>]+(>|$)/g, '')
                        .substring(0, truncateTo)
                        .trim()
                    )
                  : displayContent && formatHtml(content)}
              </div>
              {content.length > truncateTo && (
                <Button
                  className="mt-3 p-0 btn-sm"
                  label={!displayContent ? t('misc.showMore') : t('misc.showLess')}
                  onClick={handleShowMoreButtonClick}
                  variant="link"
                />
              )}
            </>
          )}
        </div>
      </>
    ),
  });
};
