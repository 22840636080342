import 'swiper/scss/navigation'; // Navigation module
import 'swiper/scss'; // core Swiper
import './SliderB.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { SliderButton } from './SliderButton';

export interface SliderBProps {
  itemList: Array<b2x.PageApiDto>;
}

export const SliderB = ({ itemList }: SliderBProps) => {
  const currentBreakpoint = b2x.useBreakpoint();
  const sliderUUID = React.useMemo(() => `slider-${b2x.createUUID()}`, []);

  return (
    <div className="slider-b position-relative px-xxl-5" id={sliderUUID}>
      <b2x.EqualHeight>
        <Swiper
          {...(currentBreakpoint === 'xs' && { slidesPerView: 2.5 })}
          {...(currentBreakpoint === 'sm' && { slidesPerView: 3.5 })}
          {...(currentBreakpoint === 'md' && { slidesPerView: 4.5 })}
          {...(currentBreakpoint === 'lg' && { slidesPerView: 5.5 })}
          {...(currentBreakpoint === 'xl' && { slidesPerView: 6, spaceBetween: 16 })}
          {...(currentBreakpoint === 'xxl' && { slidesPerView: 7, spaceBetween: 32 })}
          centerInsufficientSlides
          modules={[Navigation]}
          navigation={{ nextEl: `#${sliderUUID} .swiper-button-next`, prevEl: `#${sliderUUID} .swiper-button-prev` }}
        >
          {itemList.map((item, index) => (
            <SwiperSlide key={item.id}>
              {(slideData) => (
                <div className="slide text-center">
                  <b2x.router.NavLink className={'text-decoration-none text-black d-block'} to={item.fullPath}>
                    <b2x.EqualHeightElement name="filter-image">
                      <div className="p-3 d-flex align-items-end justify-content-center h-100">
                        {item.image && <b2x.Image fluid {...item.image} />}
                      </div>
                    </b2x.EqualHeightElement>
                    <b2x.EqualHeightElement name="filter-title">
                      <h6
                        className={classnames(
                          { small: b2x.untilBreakpoint('md', currentBreakpoint) },
                          'text-uppercase fw-medium m-0 px-2 pb-3 pb-lg-4'
                        )}
                      >
                        {item.name}
                      </h6>
                    </b2x.EqualHeightElement>
                  </b2x.router.NavLink>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="slider-navigation position-absolute top-0 start-0 w-100">
          <b2x.EqualHeightElement name="filter-image">
            <SliderButton direction="left" size={'small'} />
            <SliderButton direction="right" size={'small'} />
          </b2x.EqualHeightElement>
        </div>
      </b2x.EqualHeight>
    </div>
  );
};
