import './MobileHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType } from './contentTypes';
import { Icon } from './Icon';
import logoSmall from './images/logo-small.svg';
// import { useMmenuOffcanvas } from './Mmenu';
import { useMobileNavigationOffcanvas } from './MobileNavigationOffcanvas';
import { useMobileSimpleSearchOffcanvas } from './MobileSimpleSearchOffcanvas';
//import { SliderVertical } from './slider/SliderVertical';

export interface MobileHeaderProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  innerRef: React.RefObject<HTMLDivElement>;
  recalculateHeaderHeight(): void;
  visibleUntil: b2x.Breakpoint;
}

export const MobileHeader = ({ content, innerRef, recalculateHeaderHeight, visibleUntil }: MobileHeaderProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const [MobileNavigationOffcanvas, showMobileNavigationOffcanvas] = useMobileNavigationOffcanvas();
  const [MobileSimpleSearchOffcanvas, showMobileSimpleSearchOffcanvas] = useMobileSimpleSearchOffcanvas();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <>
      {MobileNavigationOffcanvas}
      {MobileSimpleSearchOffcanvas}
      <b2x.Sticky>
        {({ isSticky }) => (
          <div
            className={classnames(`mobile-header bg-black sticky-top d-block d-${visibleUntil}-none`)}
            ref={innerRef}
          >
            <Container>
              <b2x.Row className={classnames('align-items-center py-2')} gap={0}>
                <b2x.Col className="d-flex">
                  <Button
                    className="text-white btn-menu me-3"
                    iconEnd={{ name: 'menu', size: 25 }}
                    onClick={showMobileNavigationOffcanvas}
                    variant="blank"
                  />
                  <Button
                    className="text-white btn-search"
                    iconEnd={{ name: 'search', size: 25 }}
                    onClick={showMobileSimpleSearchOffcanvas}
                    variant="blank"
                  />
                </b2x.Col>
                <b2x.Col className="text-center">
                  <b2x.router.Link to="/">
                    <b2x.Image onLoad={recalculateHeaderHeight} src={logoSmall} />
                  </b2x.router.Link>
                </b2x.Col>
                <b2x.Col className="d-flex justify-content-end">
                  <div className="hstack gap-2">
                    {session?.customer ? (
                      <b2x.router.Link className="btn-account lh-1 text-primary" to={getPagePath('SITE_ACCOUNT')}>
                        <Icon name="account" size={25} />
                      </b2x.router.Link>
                    ) : (
                      <Button
                        className="btn-account text-white"
                        iconEnd={{ name: 'account', size: 25 }}
                        onClick={showAccountOffcanvas}
                        variant="blank"
                      />
                    )}
                    {b2x.appConfig.enableWishlist &&
                      (session?.customer ? (
                        <b2x.router.Link className="btn-wishlist lh-1 p-2" to="/account/area/wishlist">
                          <Button className="position-relative text-white" variant="blank">
                            <Icon name={'wishlist'} size={25} />
                            {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                              (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                              <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                                {(session.wishlist.products ? session.wishlist.products.length : 0) +
                                  (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                              </span>
                            )}
                          </Button>
                        </b2x.router.Link>
                      ) : (
                        <Button
                          className="btn-wishlist text-white p-2"
                          iconEnd={{ name: 'wishlist', size: 25 }}
                          onClick={showAccountOffcanvas}
                          variant="blank"
                        />
                      ))}
                    <Button
                      className="btn-cart position-relative text-white"
                      onClick={showCartOffcanvas}
                      variant="blank"
                    >
                      <Icon name={'cart'} size={25} />
                      {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
                        <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                          {session.cart.itemsNumber}
                        </span>
                      )}
                    </Button>
                  </div>
                </b2x.Col>
              </b2x.Row>
            </Container>
          </div>
        )}
      </b2x.Sticky>
    </>
  );
};
