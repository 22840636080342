import { b2x } from '@b2x/react/src';

import { Box } from '../Box';
import { Page } from './Page';

export interface ChangePasswordPageProps {}

export const ChangePasswordPage = (props: ChangePasswordPageProps) => {
  return (
    <Page greyBackground>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-xl-5">
            <Box>
              <div className="mb-4 ">
                <h3 className="text-uppercase text-center mb-3">Recupera password</h3>
                <span className="fw-light">Inserisci la nuova password e clicca su Invia Richiesta.</span>
              </div>
              <b2x.ChangePasswordByTokenFormHelper className="mb-4">
                {({ fieldsHelper }) => (
                  <>
                    <b2x.FormGroup {...fieldsHelper.newPassword.formGroup} label={undefined}>
                      <b2x.PasswordInput {...fieldsHelper.newPassword.passwordInput} />
                    </b2x.FormGroup>
                    <b2x.FormGroup {...fieldsHelper.confirmNewPassword.formGroup} label={undefined}>
                      <b2x.PasswordInput {...fieldsHelper.confirmNewPassword.passwordInput} />
                    </b2x.FormGroup>
                    <b2x.Button {...fieldsHelper.buttons.submit} />
                  </>
                )}
              </b2x.ChangePasswordByTokenFormHelper>
            </Box>
          </div>
        </div>
      </div>
    </Page>
  );
};
