import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { ConditionalWrapper } from './ConditionalWrapper';
import { Checkbox } from './form/fields/RadioCheck';
import { SearchFilter } from './form/SearchForm';
import { t } from './i18n/i18n';
import { SearchFiltersProps } from './SearchFilters';
import { renderUI } from './util';

export const SearchFiltersB = ({ excludeFacets, fieldsHelper }: SearchFiltersProps) => {
  const filteredFilters = fieldsHelper.filters.filter(({ filter }) => !excludeFacets?.includes(filter.code));

  return renderUI({
    bs5: (
      <div className="search-filters">
        <ul className="list-unstyled m-0">
          {fieldsHelper.activeFilters.map(
            (activeFilter) =>
              activeFilter.fromSimpleSearch && (
                <li className="search-result p-3" key={activeFilter.filter.code}>
                  <h6 className="d-none d-lg-block text-primary mb-0">{t('misc.youSearchedFor')}</h6>
                  <ul className="list-unstyled">
                    <li>
                      <span className="d-inline-block d-lg-none me-3">{t('misc.youSearchedFor')}</span>
                      <div className="d-grid">
                        <Button
                          className="btn-sm extra-small ps-0 pe-1 pb-0"
                          iconEnd={{ name: 'delete', size: 14 }}
                          justifyContent="between"
                          label={activeFilter.filter.name}
                          onClick={activeFilter.handleClick}
                          variant={'outline'}
                        />
                      </div>
                    </li>
                  </ul>
                </li>
              )
          )}
          {filteredFilters.map((filter, filterIndex) => (
            <li
              className={classnames(
                { 'border-top other-search-filter': filterIndex > 0 },
                'd-none d-lg-block',
                `filter-type-${filter.filter.type?.toLocaleLowerCase()}`
              )}
              key={filter.checkbox.id}
            >
              {filter.filter.type === 'CATEGORY' ? (
                <>
                  <ul className="list-unstyled">
                    {filter.children.map((filterChildren, filterChildrenIndex) => (
                      <li
                        className={classnames({ 'border-top': filterChildrenIndex > 0 })}
                        key={filterChildren.checkbox.id}
                      >
                        {filterChildren.children.length > 0 ? (
                          <FilterWithChildren filter={filterChildren} mainFilter />
                        ) : (
                          <Checkbox {...filterChildren.checkbox} className="main-filter py-3" />
                        )}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <FilterWithChildren filter={filter} mainFilter />
              )}
            </li>
          ))}
        </ul>
      </div>
    ),
  });
};

interface FilterWithChildrenProps {
  filter: SearchFilter;
  mainFilter?: boolean;
}

const FilterWithChildren = ({ filter, mainFilter }: FilterWithChildrenProps) => {
  const accordionButtonRef = React.useRef<HTMLButtonElement>(null);
  const [accordionCollapsed, setAccordionCollapsed] = React.useState<boolean>();

  React.useEffect(() => {
    setAccordionCollapsed(filter.filter.checkType === 'NOT_CHECKED' ? true : false);
  }, [filter.filter.checkType]);

  const accordionButtonClick = React.useCallback(() => {
    if (accordionButtonRef.current?.classList.contains('collapsed')) {
      setAccordionCollapsed(true);
    } else {
      setAccordionCollapsed(false);
    }
  }, []);

  // TMP: Purina tmp solution for filters search
  // TODO: Remove this when we have a better solution
  const isPurinaTMPSolution = filter.filter.id === '37589';

  return renderUI({
    bs5: (
      <div className="accordion" id={`accordion-${filter.checkbox.id}`}>
        <div className="accordion-item border-0">
          <div
            className={classnames('accordion-header', { 'main-filter': mainFilter })}
            id={`heading-${filter.checkbox.id}`}
          >
            <ConditionalWrapper
              condition={filter.filter.type === 'CATEGORY' && !isPurinaTMPSolution}
              wrapper={<div className="d-flex justify-content-between" />}
            >
              {filter.filter.type === 'CATEGORY' && !isPurinaTMPSolution && (
                <div>
                  <Checkbox
                    {...filter.checkbox}
                    className={classnames({ 'py-1 my-2': mainFilter }, { 'small pb-2': !mainFilter })}
                  />
                </div>
              )}
              <div
                className={classnames(
                  { 'd-flex align-items-center pe-2': filter.filter.type === 'CATEGORY' && !isPurinaTMPSolution },
                  { 'd-grid': filter.filter.type !== 'CATEGORY' || isPurinaTMPSolution }
                )}
              >
                <Button
                  aria-controls={`collapse-${filter.checkbox.id}`}
                  aria-expanded="true"
                  className={classnames(
                    { 'py-1 my-2 pe-2': (filter.filter.type !== 'CATEGORY' || isPurinaTMPSolution) && mainFilter },
                    { 'btn-sm pe-2': !mainFilter },
                    { collapsed: filter.filter.checkType === 'NOT_CHECKED' }
                  )}
                  data-bs-target={`#collapse-${filter.checkbox.id}`}
                  data-bs-toggle="collapse"
                  iconEnd={{
                    name: accordionCollapsed
                      ? mainFilter
                        ? 'plus'
                        : 'small-arrow-down'
                      : mainFilter
                      ? 'minus'
                      : 'small-arrow-up',
                    size: mainFilter ? 20 : 14,
                  }}
                  innerRef={accordionButtonRef}
                  justifyContent="between"
                  label={filter.filter.type === 'CATEGORY' && !isPurinaTMPSolution ? undefined : filter.filter.name}
                  onClick={accordionButtonClick}
                  type="button"
                  variant="blank"
                />
              </div>
            </ConditionalWrapper>
          </div>
          <div
            aria-labelledby={`heading-${filter.checkbox.id}`}
            className={classnames(
              'accordion-collapse collapse',
              { show: filter.filter.checkType === 'CHECKED' },
              { show: filter.filter.checkType === 'CHILD_CHECKED' }
            )}
            data-bs-parent={`#accordion-${filter.checkbox.id}`}
            id={`collapse-${filter.checkbox.id}`}
          >
            <div className="accordion-body p-0 pb-2">
              <ul className="list-unstyled ps-3">
                {filter.children.map((filterItem) => (
                  <li key={filterItem.checkbox.id}>
                    {filterItem.children.length > 0 ? (
                      <FilterWithChildren filter={filterItem} />
                    ) : (
                      <Checkbox {...filterItem.checkbox} className="filter-item-checkbox form-check small py-1" />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    ),
  });
};
