import './AmbassadorPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { AmbassadorList } from '../AmbassadorList';
import { Container } from '../Container';
import { AmbassadorPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { Page } from './Page';

export interface AmbassadorPageProps {}

export const AmbassadorPage = (props: AmbassadorPageProps) => {
  const page = b2x.usePage<AmbassadorPageContentType>();

  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <Page className="ambassador-page" noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {page?.content?.body.images?.wallpaper && (
        <div className="ambassador-wallpaper">
          <b2x.ImageFromContentV1 {...page.content.body.images.wallpaper} className="w-100" />
        </div>
      )}
      <div className="ambassador-details position-relative mb-3 mb-lg-5">
        <Container>
          <b2x.Row className="justify-content-center">
            <b2x.Col size={{ lg: 11, xs: 12, xxl: 9 }}>
              <div className={classnames({ 'bg-white': !b2x.untilBreakpoint('md', currentBreakpoint) })}>
                <div className="pt-lg-4 px-3 px-lg-5 pb-3 pb-lg-5">
                  <b2x.Row>
                    <b2x.Col size={{ lg: 5, xs: 12 }}>
                      {page?.content?.body.images?.profile && (
                        <div className="image-ambassador-profile">
                          <b2x.ImageFromContentV1
                            className="rounded-circle"
                            fluid
                            {...page.content.body.images.profile}
                          />
                        </div>
                      )}
                    </b2x.Col>
                    <b2x.Col size={{ lg: 7, xs: 12 }}>
                      <div className="pt-3 px-lg-4">
                        <h1
                          className={classnames(
                            { 'text-center': b2x.untilBreakpoint('md', currentBreakpoint) },
                            'text-uppercase fw-bold mb-3'
                          )}
                        >
                          {page?.content?.body.name} {page?.content?.body.surname}
                        </h1>
                        {page?.content?.body.quote && (
                          <div className="botrder border-bottom border-gray-200 d-flex gap-3 py-3 mb-3">
                            <div>
                              <div className="text-gray-200 pt-1">
                                <Icon name="quote" size={b2x.untilBreakpoint('sm', currentBreakpoint) ? 35 : 72} />
                              </div>
                            </div>
                            <div className="fw-bold fst-italic">{b2x.formatHtml(page.content.body.quote)}</div>
                          </div>
                        )}
                        {page?.content?.body.awards && page.content.body.awards.length > 0 && (
                          <ul className="list-unstyled d-flex flex-column gap-3">
                            {page.content.body.awards.map((item) => (
                              <li key={item.contentSectionId}>
                                <div className="d-flex gap-2">
                                  <div className="text-primary pt-1">
                                    <Icon name="award" size={25} />
                                  </div>
                                  <div>{b2x.formatHtml(item.award)}</div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </b2x.Col>
                  </b2x.Row>
                </div>
                {page?.content?.body.content && (
                  <div className="ambassador-content text-center">{b2x.formatHtml(page.content.body.content)}</div>
                )}
              </div>
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>
      <div className="mb-3 mb-lg-5">
        <Container>
          <h3 className="h1 fw-bold text-center mb-4 mb-lg-5">{t('misc.ourAmbassadors')}</h3>
        </Container>
        <AmbassadorList exclude={page?.id} />
      </div>
    </Page>
  );
};
