import './DeprecatedVideo.scss';

import classnames from 'classnames';
import React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

import { DivAsButton } from './DivAsButton';
import { Image } from './Image';
import { useBreakpoint } from './useBreakpoint';

export interface DeprecatedVideoProps extends Omit<ReactPlayerProps, 'playing'> {
  autoplay?: boolean;
  onLoad?(): void;
  src: Src | BreakpointSrc;
}

interface BreakpointSrc {
  lg?: Src;
  md?: Src;
  sm?: Src;
  xl?: Src;
  xs: Src;
  xxl?: Src;
}

interface Src {
  preview?: string;
  video: string;
}

export const DeprecatedVideo = ({ autoplay, onLoad, src, ...otherProps }: DeprecatedVideoProps) => {
  const videoRef = React.useRef<ReactPlayer>(null);

  const [videoUrl, setVideoUrl] = React.useState<string>();
  const [previewUrl, setPreviewUrl] = React.useState<string>('');

  const [showReload, setShowReload] = React.useState<boolean>(false);
  const [playing, setPlaying] = React.useState<boolean>(autoplay !== undefined && autoplay);

  const breakpoint = useBreakpoint();

  React.useEffect(() => {
    let _video = '';
    let _preview = '';

    if (src.hasOwnProperty('video')) {
      const _src = src as Src;

      _video = _src.video;
      _preview = _src.preview ? _src.preview : '';
    } else {
      const _src = src as BreakpointSrc;

      _video = _src.xs.video;
      _preview = _src.xs.preview ?? '';

      if (['sm', 'md', 'lg', 'xl', 'xxl'].includes(breakpoint)) {
        _video = _src.sm ? _src.sm.video : _video;
        _preview = _src.sm?.preview ? _src.sm.preview : _preview;
      }
      if (['md', 'lg', 'xl', 'xxl'].includes(breakpoint)) {
        _video = _src.md ? _src.md.video : _video;
        _preview = _src.md?.preview ? _src.md.preview : _preview;
      }
      if (['lg', 'xl', 'xxl'].includes(breakpoint)) {
        _video = _src.lg ? _src.lg.video : _video;
        _preview = _src.lg?.preview ? _src.lg.preview : _preview;
      }
      if (['xl', 'xxl'].includes(breakpoint)) {
        _video = _src.xl ? _src.xl.video : _video;
        _preview = _src.xl?.preview ? _src.xl.preview : _preview;
      }
      if (breakpoint === 'xxl') {
        _video = _src.xxl ? _src.xxl.video : _video;
        _preview = _src.xxl?.preview ? _src.xxl.preview : _preview;
      }
    }

    setVideoUrl(_video);
    setPreviewUrl(_preview);
  }, [breakpoint, src]);

  const handleClickPreview = React.useCallback(() => {
    setPlaying(true);
  }, [setPlaying]);

  const handleEndVideo = React.useCallback(() => {
    setShowReload(true);
  }, [setShowReload]);

  const handleReloadVideo = React.useCallback(() => {
    videoRef.current?.seekTo(0);
    setShowReload(false);
  }, [setShowReload]);

  return (
    <div className={'Video'}>
      {previewUrl !== '' && !playing ? (
        <div className="preview">
          <Image fluid src={previewUrl} />
          <DivAsButton className="overlay" onClick={handleClickPreview}>
            <div>
              <svg
                height="16"
                version="1.1"
                viewBox="0 0 16 16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <path
                  d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 14.5c-3.59 0-6.5-2.91-6.5-6.5s2.91-6.5 6.5-6.5 6.5 2.91 6.5 6.5-2.91 6.5-6.5 6.5zM6 4.5l6 3.5-6 3.5z"
                  fill="#FFFFFF"
                ></path>
              </svg>
            </div>
          </DivAsButton>
        </div>
      ) : (
        videoUrl && (
          <div>
            <ReactPlayer
              {...otherProps}
              config={{
                file: { attributes: { poster: previewUrl } },
                youtube: {
                  playerVars: { modestbranding: 1, showinfo: 0 },
                },
              }}
              controls={otherProps.controls}
              height={'100%'}
              loop={otherProps.loop}
              muted={otherProps.muted ? true : playing}
              onEnded={handleEndVideo}
              onReady={onLoad}
              playing={playing}
              playsinline
              ref={videoRef}
              url={videoUrl}
              width={'100%'}
            />
            {!otherProps.controls && (
              <DivAsButton
                className={classnames('reload', { 'show-reload': showReload })}
                onClick={showReload ? handleReloadVideo : undefined}
              >
                <div>
                  <svg
                    height="16"
                    version="1.1"
                    viewBox="0 0 16 16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <path
                      d="M13.901 2.599c-1.463-1.597-3.565-2.599-5.901-2.599-4.418 0-8 3.582-8 8h1.5c0-3.59 2.91-6.5 6.5-6.5 1.922 0 3.649 0.835 4.839 2.161l-2.339 2.339h5.5v-5.5l-2.099 2.099z"
                      fill="#FFFFFF"
                    ></path>
                    <path
                      d="M14.5 8c0 3.59-2.91 6.5-6.5 6.5-1.922 0-3.649-0.835-4.839-2.161l2.339-2.339h-5.5v5.5l2.099-2.099c1.463 1.597 3.565 2.599 5.901 2.599 4.418 0 8-3.582 8-8h-1.5z"
                      fill="#FFFFFF"
                    ></path>
                  </svg>
                </div>
              </DivAsButton>
            )}
          </div>
        )
      )}
    </div>
  );
};
