import { useAppContext, useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { ReferralBoxContentSection } from './contentTypes';
import { t } from './i18n/i18n';
import { ReferralBoxUI } from './ReferralBoxUI';
import { useSharingModal } from './SharingModal';
import { useReferralCode } from './useReferralCode';
import { useShareButtonClickHandler } from './useShareButtonClickHandler';

interface ReferralBoxProps extends ReferralBoxContentSection {}

export const ReferralBox = ({ shareCta, shareDataTitle, ...props }: ReferralBoxProps) => {
  const { session } = useAppContext();
  const isLoggedIn = !!session?.customer;

  const [SharingModal, showSharingModal] = useSharingModal();

  const { referralLink } = useReferralCode();

  const { onLoginActionCallback } = useAppStaticContext();
  const handleShareButtonClick = useShareButtonClickHandler({
    data: {
      title: shareDataTitle,
      url: referralLink,
    },
    fallback: showSharingModal,
  });

  return (
    <>
      <ReferralBoxUI {...props}>
        {isLoggedIn ? (
          <Button
            iconStart={{ name: shareCta?.icon || '', size: 24 }}
            label={shareCta?.label}
            onClick={handleShareButtonClick}
            variant={shareCta?.variant}
          />
        ) : (
          <Button onClick={onLoginActionCallback}>{t('account.login')}</Button>
        )}
      </ReferralBoxUI>
      {SharingModal}
    </>
  );
};
