import 'swiper/scss/navigation'; // Navigation module
import 'swiper/scss'; // core Swiper
import './SliderAmbassadors.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { AmbassadorTile } from '../AmbassadorTile';
import { AmbassadorsPageContentType } from '../contentTypes';
import { SliderButton } from './SliderButton';
export interface SliderAmbassadorsProps {}

export const SliderAmbassadors = (props: SliderAmbassadorsProps) => {
  const currentBreakpoint = b2x.useBreakpoint();
  const sliderUUID = React.useMemo(() => `slider-${b2x.createUUID()}`, []);

  const page = b2x.usePageByCode<AmbassadorsPageContentType>('SITE_AMBASSADOR', {
    populate: {
      children: {
        content: true,
      },
      content: true,
    },
  });

  return (
    <div className="slider-ambassadors position-relative" id={sliderUUID}>
      {page?.children?.length && page.children.length > 0 && (
        <b2x.EqualHeight>
          <Swiper
            spaceBetween={16}
            {...(currentBreakpoint === 'xs' && { slidesPerView: 1.15 })}
            {...(currentBreakpoint === 'sm' && { slidesPerView: 2.25 })}
            {...(currentBreakpoint === 'md' && { slidesPerView: 2.25 })}
            {...(currentBreakpoint === 'lg' && { slidesPerView: 3.25 })}
            {...(currentBreakpoint === 'xl' && { slidesPerView: 4 })}
            {...(currentBreakpoint === 'xxl' && { slidesPerView: 4 })}
            centerInsufficientSlides
            modules={[Navigation]}
            navigation={{ nextEl: `#${sliderUUID} .swiper-button-next`, prevEl: `#${sliderUUID} .swiper-button-prev` }}
          >
            {page.children.map((ambassador) => (
              <SwiperSlide key={ambassador.id}>
                <AmbassadorTile ambassadorsPerRow={page.children?.length} page={ambassador} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="slider-navigation position-absolute top-0 bottom-0 start-0 w-100">
            <SliderButton direction="left" size={'small'} />
            <SliderButton direction="right" size={'small'} />
          </div>
        </b2x.EqualHeight>
      )}
    </div>
  );
};
