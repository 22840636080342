import React from 'react';

import { useIntersectionObserver } from './useIntersectionObserver';
import { isElementInViewport } from './util';

export const usePreLoadedScrollingPagination = <T,>(allItems?: Array<T>, pageSize = 20) => {
  const [visibleItems, setVisibleItems] = React.useState<Array<T>>();

  const [ref, setRef, visible] = useIntersectionObserver<HTMLDivElement>(false);

  React.useEffect(() => {
    setVisibleItems(allItems?.slice(0, pageSize));
  }, [allItems, pageSize]);

  React.useEffect(() => {
    if (
      visible &&
      allItems !== undefined &&
      visibleItems !== undefined &&
      allItems.length > visibleItems.length &&
      ref.current &&
      isElementInViewport(ref.current, true)
    ) {
      setVisibleItems((prevState) => {
        return prevState ? prevState.concat(allItems.slice(prevState.length, prevState.length + pageSize)) : undefined;
      });
    }
  }, [allItems, pageSize, ref, visible, visibleItems]);

  const hasMoreItemsToShow = React.useMemo(
    () => allItems !== undefined && visibleItems !== undefined && allItems.length > visibleItems.length,
    [allItems, visibleItems]
  );

  return { hasMoreItemsToShow, items: visibleItems, triggerRef: setRef };
};
