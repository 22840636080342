import './CheckoutPage.scss';

import { b2x } from '@b2x/react/src';

import { Page } from './Page';

export type CheckoutPageProps = b2x.CheckoutPageProps;

export const CheckoutPage = (props: CheckoutPageProps) => {
  return (
    <Page className="checkout-page" greyBackground headerCheckout noPaddingTop>
      <b2x.CheckoutPage {...props} />
    </Page>
  );
};
