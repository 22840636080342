import { queryClient, SearchShopsApiDto } from '@b2x/storefront-api-js-client/src';
import { SearchShopsOptions } from '@b2x/storefront-api-js-client/src/shops';
import _ from 'lodash';
import React from 'react';

import { b2x } from '.';
import { ApiRequestConfig } from './api/useApiRequest';
import { useShopsApi } from './api/useShopsApi';
import { useSearchParams } from './router/router';
import { storage } from './storage';
import { useStable } from './util';

export interface UseShopSearchOptions extends SearchShopsOptions {
  defaultPageSize: number;
  fixedFilters?: Array<string>;
  mock?: boolean;
  thingsToLoadBeforeSearch?: Array<unknown>;
}

export const useShopSearch = (
  options?: UseShopSearchOptions,
  config?: ApiRequestConfig,
  deps?: React.DependencyList
) => {
  const [searchResult, setSearchResult] = React.useState<SearchShopsApiDto>();
  const [fetching, setFetching] = React.useState<boolean>(false);

  const { searchShops, searchShopsMock } = useShopsApi();
  const [searchParams] = useSearchParams();

  config = useStable(config);

  const { defaultPageSize = 20, fixedFilters, mock, populate, thingsToLoadBeforeSearch = [] } = options ?? {};

  const queryKey = React.useRef<Array<unknown>>();

  // Faccio partire la search ogni volta che cambia quacosa nella path
  React.useEffect(() => {
    const everythingIsReady = thingsToLoadBeforeSearch.every((thing) => thing !== undefined);

    if (everythingIsReady) {
      const storedPageSize = storage.getNumber('shopSearchPageSize');
      const pageSize = searchParams.get('pageSize') ?? undefined;
      const pageNum = searchParams.get('pageNum') ?? undefined;
      const orderBy = (searchParams.get('orderBy') as b2x.SearchShopOrderApiType | undefined) ?? undefined;
      const orderingType = (searchParams.get('orderingType') as b2x.OrderingApiType | undefined) ?? undefined;

      const searchShopsOptions: SearchShopsOptions = {
        filters: [...searchParams.getAll('facets'), ...(fixedFilters ? fixedFilters : [])],
        orderBy: orderBy,
        orderingType: orderingType,
        pageNum: pageNum ? parseInt(pageNum) : undefined,
        pageSize: pageSize ? parseInt(pageSize) : storedPageSize ? storedPageSize : defaultPageSize,
        populate: populate,
        simpleSearch: searchParams.get('simpleSearch') ?? undefined,
      };

      const newQueryKey = ['searchShops', searchShopsOptions];
      queryKey.current &&
        !_.isEqual(queryKey.current, newQueryKey) &&
        queryClient.cancelQueries({ queryKey: queryKey.current });
      queryKey.current = newQueryKey;

      setFetching(true);
      if (mock) {
        searchShopsMock().then((response) => {
          setSearchResult({
            isPaginated: false,
            items: response,
            itemsCount: response.length,
            pageNumber: 1,
            pageSize: -1,
            pagesCount: 1,
          });
        });
      } else {
        searchShops(
          queryKey.current,
          {
            ...searchShopsOptions,
          },
          config
        )
          .then((response) => {
            setSearchResult(response.data);
          })
          .finally(() => {
            setFetching(false);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, ...thingsToLoadBeforeSearch, ...(deps ? deps : [])]);

  return { fetching, searchResult };
};
