import {
  useGooglePlacesAutocomplete,
  UseGooglePlacesAutocompleteOptions,
  UseGooglePlacesAutocompleteResult,
} from './useGooglePlacesAutocomplete';

export interface GooglePlacesAutocompleteProps extends UseGooglePlacesAutocompleteOptions {
  children(useGooglePlacesAutocompleteResult: UseGooglePlacesAutocompleteResult): React.ReactNode;
}

export const GooglePlacesAutocomplete = ({ children, ...otherProps }: GooglePlacesAutocompleteProps) => {
  const googlePlacesAutocompleteResult = useGooglePlacesAutocomplete(otherProps);

  return <>{children(googlePlacesAutocompleteResult)}</>;
};
