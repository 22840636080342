import classnames from 'classnames';

import { Button } from './Button';
import { Col } from './Col';
import { appConfig } from './config';
import { Container } from './Container';
import { HeaderCheckoutProps } from './HeaderCheckout';
import { Div } from './HTMLElement';
import { t } from './i18n/i18n';
import { Image } from './Image';
import { Link } from './router/Link';
import { Row } from './Row';
import { useBreakpoint } from './useBreakpoint';
import { untilBreakpoint } from './util';

export const HeaderCheckoutA = ({
  isSticky,
  logo,
  logoSmall,
  mobileFrom,
  recalculateHeaderHeight,
  wrapperStyle,
}: HeaderCheckoutProps) => {
  const currentBreakpoint = useBreakpoint();

  return (
    <>
      <Div
        className={classnames(wrapperStyle, 'header-checkout')}
        uiClassName={{ bs5: classnames({ 'sticky-top': isSticky }) }}
      >
        {!untilBreakpoint(mobileFrom, currentBreakpoint) ? (
          <Div
            className="header-checkout-desktop"
            paddingY={3}
            style={{ position: 'relative' }}
            uiClassName={{ bs5: 'shadow-sm' }}
          >
            <Container>
              <Row alignItems={'center'}>
                <Col className="copy-container">{t('misc.checkout')}</Col>
                <Col className="logo-container" size={'auto'}>
                  <Link to="/">
                    <Image className="logo" fluid onLoad={recalculateHeaderHeight} src={logo} />
                  </Link>
                </Col>
                <Col className="backlink-container" display={'flex'} justifyContent={'end'}>
                  <Link to="/cart">
                    <Button
                      iconStart={appConfig.icons?.arrow?.left ? { name: appConfig.icons.arrow.left } : undefined}
                      label={t('checkout.backToCart')}
                    />
                  </Link>
                </Col>
              </Row>
            </Container>
          </Div>
        ) : (
          <Div className="header-checkout-mobile">
            <Div className="header-checkout-mobile-first-line" paddingY={2} textAlign={'center'}>
              <Container>
                <Div className="logo-container">
                  <Link to="/">
                    <Image className="logo" fluid onLoad={recalculateHeaderHeight} src={logoSmall} />
                  </Link>
                </Div>
              </Container>
            </Div>
            <Div className="header-checkout-mobile-second-line" paddingY={2}>
              <Container>
                <Row alignItems={'center'}>
                  <Col className="copy-container" size={6}>
                    {t('misc.checkout')}
                  </Col>
                  <Col className="backlink-container" display={'flex'} justifyContent={'end'} size={6}>
                    <Link to="/cart">
                      <Button
                        iconStart={appConfig.icons?.arrow?.left ? { name: appConfig.icons.arrow.left } : undefined}
                        label={t('checkout.backToCart')}
                      />
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Div>
          </Div>
        )}
      </Div>
    </>
  );
};
