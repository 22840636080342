import { b2x } from '@b2x/react/src';

import { ThankYouPageContentType } from '../contentTypes';
import { MessageBoxPage } from './MessageBoxPage';

export interface ThankYouPageOrderFailedProps {}

export const ThankYouPageOrderFailed = (props: ThankYouPageOrderFailedProps) => {
  const content = b2x.useContent<ThankYouPageContentType>('THANKYOU_PAGE_FAILED_CONTENT');
  return (
    <MessageBoxPage alignItems="align-items-center" img={content?.body.img} justifyContent="justify-content-end">
      <div className="px-2 my-3">
        <h2 className="h3 fw-semi-bold py-1"> {b2x.formatHtml(content?.body.title)}</h2>
        <p className="py-1"> {b2x.formatHtml(content?.body.subTitle)}</p>
        <div className="small fw-light px-3">{b2x.formatHtml(content?.body.content)}</div>
      </div>
    </MessageBoxPage>
  );
};
