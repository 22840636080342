import React from 'react';

import { useCustomerApi } from './api/useCustomerApi';
import { useAppStaticContext } from './AppContext';
import { useNavigate, useSearchParams } from './router/router';

interface Options {
  errorIfMissingToken?: boolean;
}

export const useNewsletterUnsubscriber = (options?: Options) => {
  const { errorIfMissingToken = true } = options ?? {};
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') ?? undefined;
  const { unsubscribeNewsletterByToken } = useCustomerApi();
  const navigate = useNavigate();
  const { getFixedPagePath } = useAppStaticContext();
  const [state, setState] = React.useState<'pending' | 'success' | 'error'>();

  React.useEffect(() => {
    if (token) {
      setState('pending');
      unsubscribeNewsletterByToken(token, { suppressErrorModal: true })
        .then(() => {
          setState('success');
        })
        .catch(() => {
          setState('error');
        });
    } else {
      errorIfMissingToken ? setState('error') : setState('success');
    }
  }, [errorIfMissingToken, token, unsubscribeNewsletterByToken]);

  React.useEffect(() => {
    if (state === 'error') {
      navigate(getFixedPagePath('error'));
    }
  }, [state, getFixedPagePath, navigate]);

  return { state };
};
