import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { LandingExternalPageContentType } from '../contentTypes';
import { Page } from './Page';

export interface LandingExternalPageProps {}

export const LandingExternalPage = (props: LandingExternalPageProps) => {
  const page = b2x.usePage<LandingExternalPageContentType>({});

  return (
    <Page className="landing-external-page" noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <Container fluid="always">
        {page?.content?.body.contentHtml && <div>{b2x.formatHtml(page.content.body.contentHtml)}</div>}
      </Container>
    </Page>
  );
};
