export const en = {
  misc: {
    ourAmbassadors: 'Our ambassadors',
    storeName: 'Acavallo',
  },
  product: {
    colorsCount: '',
    colorsCount_one: '{{count}} color',
    colorsCount_other: '{{count}} colors',
    showAllImages: 'Show more images',
    size: 'Size',
    technicalFeatures: 'Technical features',
  },
  welcome: 'Welcome to Acavallo',
};
