import React, { useEffect, useRef } from 'react';

const listener = (
  event: MouseEvent,
  refs: Array<React.RefObject<HTMLDivElement>>,
  handler: (e: MouseEvent) => void,
  delay?: number
) => {
  const checkIfContains = () => refs.some((ref) => ref.current?.contains(event.target as Node));

  if (checkIfContains()) {
    return;
  }

  if (delay) {
    return setTimeout(() => {
      if (checkIfContains()) {
        return;
      }
      handler(event);
    }, delay);
  }

  handler(event);
};

export const useOnHoverOutside = (
  handler: (e: MouseEvent) => void,
  initialRefs?: Array<React.RefObject<HTMLDivElement>>,
  delay?: number
) => {
  const [refs, setRefs] = React.useState<Array<React.RefObject<HTMLDivElement>>>(initialRefs || []);

  const handlerRef = useRef(handler);
  handlerRef.current = handler;
  const refsRef = useRef(refs);
  refsRef.current = refs;
  const delayRef = useRef(delay);
  delayRef.current = delay;

  useEffect(() => {
    let toDispose: NodeJS.Timeout | undefined;

    const eventListener = (e: MouseEvent) => {
      clearTimeout(toDispose);
      toDispose = listener(e, refsRef.current, handlerRef.current, delayRef.current);
    };
    document.addEventListener('mouseover', eventListener);

    return () => document.removeEventListener('mouseout', eventListener);
  }, []);

  const addRef = React.useCallback((ref: React.RefObject<HTMLDivElement>) => setRefs((prev) => [...prev, ref]), []);

  const removeRef = React.useCallback(
    (ref: React.RefObject<HTMLDivElement>) => setRefs((prev) => prev.filter((r) => r !== ref)),
    []
  );

  return {
    addRef,
    refs,
    removeRef,
  };
};
