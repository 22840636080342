import { b2x } from '@b2x/react/src';

import { NewsletterContentType } from './contentTypes';
import { NewsletterForm } from './forms/NewsletterForm';

interface PopUpNewsletterModalProps {}

export const PopUpNewsletterModal = (props: PopUpNewsletterModalProps) => {
  const content = b2x.useContent<NewsletterContentType>('NEWSLETTER_CONTENT');
  return (
    <>
      <div className="text-center mb-3">
        <h5 className="fw-semi-bold">{b2x.formatHtml(content?.body.title)}</h5>
        <span>{b2x.formatHtml(content?.body.subTitle)}</span>
      </div>
      <NewsletterForm emailFieldStyle="textInput" source="website-modal" />
    </>
  );
};
