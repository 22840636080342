import { Cluster, MarkerClusterer, MarkerClustererOptions, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import React from 'react';
import { flushSync } from 'react-dom';
import ReactDOM from 'react-dom/client';

import { countries, CountryCode } from './googleMaps/countries';
import { useUUID } from './useUUID';
import { createUUID, getCssVariableAsNumber } from './util';

export type MapProps = Omit<google.maps.MapOptions, 'mapId'>;

export interface MarkerContent {
  element?: React.ReactElement;
  image?: {
    className?: string;
    id?: string;
    src: string;
    width?: number;
  };
  pin?: Omit<google.maps.marker.PinElementOptions, 'glyph'> & {
    glyph?: {
      element?: React.ReactElement;
      image?: string;
      text?: string;
    };
  };
}

export type MarkerProps = Omit<google.maps.marker.AdvancedMarkerElementOptions, 'map' | 'content'> & {
  content?: MarkerContent;
  eventListeners?: Array<[keyof HTMLElementEventMap, (marker: google.maps.marker.AdvancedMarkerElement) => void]>;
  id: string;
  listeners?: Array<['click' | 'drag' | 'dragend' | 'dragstart', (event: google.maps.MapMouseEvent) => void]>;
};

export type MarkerClustererProps =
  | boolean
  | (Omit<MarkerClustererOptions, 'markers' | 'map' | 'algorithm' | 'algorithmOptions'> & {
      algorithmOptions?: {
        extent?: number;
        generateId?: boolean;
        log?: boolean;
        maxZoom?: number;
        minPoints?: number;
        minZoom?: number;
        nodeSize?: number;
        radius?: number;
      };
      defaultRendererOptions?: {
        aboveAvarageColor?: string;
        belowAvarageColor?: string;
        fontSize?: number;
        offset?: number;
        opacity?: number;
        size?: number;
      };
      markerContentBuilder?(cluster: Cluster): MarkerContent;
    });

type MapEventName =
  | 'bounds_changed'
  | 'center_changed'
  | 'click'
  | 'contextmenu'
  | 'dblclick'
  | 'drag'
  | 'dragend'
  | 'dragstart'
  | 'heading_changed'
  | 'idle'
  | 'isfractionalzoomenabled_changed'
  | 'mapcapabilities_changed'
  | 'maptypeid_changed'
  | 'mousemove'
  | 'mouseout'
  | 'mouseover'
  | 'projection_changed'
  | 'renderingtype_changed'
  | 'tilesloaded'
  | 'tilt_changed'
  | 'zoom_changed'
  | 'rightclick';

export interface UseGoogleMapOptions extends MapProps {
  clusterer?: MarkerClustererProps;
  countryCode: CountryCode;
  debug?: boolean;
  markers?: Array<MarkerProps>;
}

export interface GoogleMapInstance {
  addControl(props: { control: React.ReactNode; onClick(): void; position: google.maps.ControlPosition }): void;
  addListener(eventName: MapEventName, handler: () => void): () => void;
  addMarkerClassName(marker: google.maps.marker.AdvancedMarkerElement, className: string): void;
  addMarkerClassNameById(id: string, className: string): void;
  fitBounds(): void;
  fitBoundsToBounds(bounds: google.maps.LatLngBounds): void;
  fitBoundsToCountry(countryCode: CountryCode): void;
  getVisibleMarkersIds(): Array<string | undefined> | undefined;
  getZoom(): number;
  panTo(latlng: google.maps.LatLng | google.maps.LatLngLiteral): void;
  removeMarkerClassName(marker: google.maps.marker.AdvancedMarkerElement, className: string): void;
  removeMarkerClassNameById(id: string, className: string): void;
  resetSearchedPosition(): void;
  setCurrentPosition(currentPosition: GeolocationPosition, content?: MarkerContent): void;
  setMarkers(markersToSet: Array<MarkerProps>): void;
  setSearchedPosition(latlng: google.maps.LatLng | google.maps.LatLngLiteral, content?: MarkerContent): void;
  setZoom(zoom: number): void;
}

export const useGoogleMap = ({
  clusterer: clustererProps,
  countryCode,
  debug,
  markers: markersProps,
  ...mapProps
}: UseGoogleMapOptions) => {
  const log = React.useCallback(
    (...messages: Array<unknown>) => {
      if (debug) {
        console.log('useGoogleMap', messages);
      }
    },
    [debug]
  );
  const ref = React.useRef<HTMLDivElement>(null);

  const mapId = useUUID();
  const initialized = React.useRef<boolean>(false);

  const libraries = React.useRef<{
    core: google.maps.CoreLibrary;
    maps: google.maps.MapsLibrary;
    marker: google.maps.MarkerLibrary;
  }>();

  const map = React.useRef<google.maps.Map>();
  const clusterer = React.useRef<MarkerClusterer>();
  const markers = React.useRef<Array<google.maps.marker.AdvancedMarkerElement>>();
  const enabledMarkers = React.useRef<Array<google.maps.marker.AdvancedMarkerElement>>();
  const enabledMarkersIds = React.useRef<Array<string>>();
  const visibleMarkersIds = React.useRef<Array<string>>();
  const bounds = React.useRef<google.maps.LatLngBounds>();
  const currentPositionMarker = React.useRef<google.maps.marker.AdvancedMarkerElement>();
  const searchedPositionMarker = React.useRef<google.maps.marker.AdvancedMarkerElement>();

  const [ready, setReady] = React.useState<boolean>(false);

  const loadLibraries = React.useCallback(async () => {
    log('loadLibraries');

    const [maps, marker, core] = await Promise.all([
      google.maps.importLibrary('maps'),
      google.maps.importLibrary('marker'),
      google.maps.importLibrary('core'),
    ]);
    libraries.current = {
      core: core as google.maps.CoreLibrary,
      maps: maps as google.maps.MapsLibrary,
      marker: marker as google.maps.MarkerLibrary,
    };
  }, [log]);

  const addListener = React.useCallback((eventName: MapEventName, handler: () => void) => {
    const listener = map.current?.addListener(eventName, handler);
    return () => {
      listener?.remove();
    };
  }, []);

  const addControl = React.useCallback(
    ({
      control,
      onClick,
      position,
    }: {
      control: React.ReactNode;
      onClick(): void;
      position: google.maps.ControlPosition;
    }) => {
      const uuid = createUUID();

      if (map.current) {
        const wrapper = document.createElement('div');
        const root = ReactDOM.createRoot(wrapper);
        flushSync(() => {
          root.render(control);
        });
        const controlElement = wrapper.firstElementChild as HTMLElement;
        controlElement.dataset.id = uuid;
        controlElement.addEventListener('click', onClick);
        map.current.controls[position].push(controlElement);
        return () => {
          let indexOfControl: number | undefined;
          const positionControls = map.current?.controls[position];
          positionControls?.forEach((element, index) => {
            if (element.dataset.id === uuid) {
              indexOfControl = index;
            }
          });
          if (indexOfControl !== undefined) {
            positionControls?.removeAt(indexOfControl);
          }
        };
      }
    },
    []
  );

  /*
    Se "onlyVisible" è a true, abilito solo i markers visibili (dentro il bounds), altrimenti li abilito tutti.
    Variante veloce, ma i marker flashano.
  */
  const enableMarkersFromScratch = React.useCallback(
    (onlyVisible: boolean) => {
      log('enableMarkersFromScratch', onlyVisible);

      const markersToEnable: Array<google.maps.marker.AdvancedMarkerElement> = [];
      const currentBounds = map.current?.getBounds();

      // if (!currentBounds) {
      //   throw new Error(
      //     'Chiamato "enableVisibleMarkersFromScratch" troppo presto, ovvero prima di aver definito un bound.'
      //   );
      // }

      markers.current?.forEach((marker) => {
        // Se è visibile
        if (!onlyVisible || (marker.position && currentBounds?.contains(marker.position))) {
          // Lo aggiungo a quelli da abilitare
          markersToEnable.push(marker);
          // Lo metto direttamente in mappa solo se non gestisco il cluster
          marker.map = clusterer.current === undefined ? map.current : undefined;
        } else {
          // Se non è visibile, lo tolgo dalla mappa
          marker.map = undefined;
        }
      });
      if (clusterer.current !== undefined) {
        /*
        A volte va in errore la chiamate seguente "clearMarkers", con un errore del tipo "Cannot read properties of undefined (reading 'range')".
        Questo accade quando non ho ancora mai "mostrato" la mappa, quindi effettivamente non ha senso parlare di marker "visibili".
        La mappa non viene mostrata quindi non si può sapere quali sono quelli visibili.
        La mappa non viene mostrata quando non ho ancora settato un livello di zoom, o quando non ho ancora fatto un fitBounds().
      */
        clusterer.current.clearMarkers();
        clusterer.current.addMarkers(markersToEnable);
      }

      enabledMarkers.current = markersToEnable;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      enabledMarkersIds.current = markersToEnable.map((markerToEnable) => markerToEnable.element.dataset.id!);
      visibleMarkersIds.current = enabledMarkersIds.current;

      log('enableMarkersFromScratch', onlyVisible, `Enabled ${markersToEnable.length} markers.`);
    },
    [log]
  );

  /*
    Abilito solo i markers visibili (dentro il bounds).
    Variante lenta ma risolve il problema del flash dei marker.
  */
  const enableVisibleMarkersWithDelta = React.useCallback(
    (disableInvisibleMarkers?: boolean) => {
      log('enableVisibleMarkersWithDelta', { disableInvisibleMarkers });

      const markersToDisable: Array<google.maps.marker.AdvancedMarkerElement> = [];
      const markersIdsToDisable: Array<string> = [];
      const markersToEnable: Array<google.maps.marker.AdvancedMarkerElement> = [];
      const markersIdsToEnable: Array<string> = [];

      visibleMarkersIds.current = [];

      const isMarkerAlreadyEnabled = (marker: google.maps.marker.AdvancedMarkerElement) =>
        marker.element.dataset.id !== undefined && enabledMarkersIds.current?.includes(marker.element.dataset.id);

      const isMarkerVisible = (marker: google.maps.marker.AdvancedMarkerElement) =>
        marker.position && currentBounds?.contains(marker.position);

      const currentBounds = map.current?.getBounds();

      markers.current?.forEach((marker) => {
        if (marker.element.dataset.id !== undefined) {
          if (isMarkerVisible(marker)) {
            visibleMarkersIds.current?.push(marker.element.dataset.id);
            if (isMarkerAlreadyEnabled(marker)) {
            } else {
              markersToEnable.push(marker);
              markersIdsToEnable.push(marker.element.dataset.id);
            }
          } else {
            if (!isMarkerAlreadyEnabled(marker)) {
            } else {
              if (disableInvisibleMarkers) {
                markersToDisable.push(marker);
                markersIdsToDisable.push(marker.element.dataset.id);
              }
            }
          }
        }
      });

      if (clusterer.current !== undefined) {
        clusterer.current.removeMarkers(markersToDisable);
        clusterer.current.addMarkers(markersToEnable);
      } else {
        markersToDisable.forEach((markerToDisable) => {
          markerToDisable.map = null;
        });
        markersToEnable.forEach((markerToEnable) => {
          markerToEnable.map = map.current;
        });
      }

      enabledMarkers.current = enabledMarkers.current?.filter(
        (enabledMarker) =>
          enabledMarker.element.dataset.id !== undefined &&
          !markersIdsToDisable.includes(enabledMarker.element.dataset.id)
      );
      enabledMarkersIds.current = enabledMarkersIds.current?.filter(
        (enabledMarkerId) => !markersIdsToDisable.includes(enabledMarkerId)
      );
      enabledMarkers.current?.push(...markersToEnable);
      enabledMarkersIds.current?.push(...markersIdsToEnable);
    },
    [log]
  );

  const createMarkerContent = React.useCallback(
    (content?: MarkerContent): google.maps.marker.AdvancedMarkerElementOptions['content'] => {
      let _content: google.maps.marker.AdvancedMarkerElementOptions['content'];
      if (content !== undefined) {
        if (content.image) {
          const img = document.createElement('img');
          img.src = content.image.src;
          if (content.image.id) {
            img.id = content.image.id;
          }
          if (content.image.className) {
            img.className = content.image.className;
          }
          if (content.image.width) {
            img.width = content.image.width;
          }
          _content = img;
        } else if (content.pin) {
          let glyph: google.maps.marker.PinElementOptions['glyph'];
          if (content.pin.glyph !== undefined) {
            if (content.pin.glyph.text) {
              glyph = content.pin.glyph.text;
            } else if (content.pin.glyph.image) {
              const glyphImg = document.createElement('img');
              glyphImg.src = content.pin.glyph.image;
              glyph = glyphImg;
            } else if (content.pin.glyph.element) {
              const wrapper = document.createElement('div');
              const root = ReactDOM.createRoot(wrapper);
              flushSync(() => {
                root.render(content.pin?.glyph?.element);
              });
              glyph = wrapper.firstElementChild;
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const glyphSvgPinElement = new libraries.current!.marker.PinElement({
            ...content.pin,
            glyph: glyph,
          });
          _content = glyphSvgPinElement.element;
        } else if (content.element) {
          const wrapper = document.createElement('div');
          const root = ReactDOM.createRoot(wrapper);
          flushSync(() => {
            root.render(content.element);
          });
          _content = wrapper.firstElementChild;
        }
      }

      return _content;
    },
    []
  );

  const setMarkers = React.useCallback(
    (markersToSet: Array<MarkerProps>) => {
      const useCase = markers.current === undefined ? 'startup' : 'change';
      log('setMarkers', useCase, markersToSet);

      if (libraries.current === undefined) {
        throw new Error('libraries not loaded yet');
      }

      /*
        Questo blocco serve per "pulire" TUTTI i marker precedenti.
        Poi alla fine del metodo chiamerò "enableVisibleMarkersFromScratch" che abiliterà solo quelli visibili tra quelli nuovi.
        Non sono quindi da conconfere le 2 "clear".
        Questa pulisce tutti i vecchi, la successiva pulirà, tra quelli nuovi, quelli fuori dal bound.
      */
      if (markers.current) {
        if (clusterer.current) {
          clusterer.current.clearMarkers();
        } else {
          markers.current.forEach((marker) => {
            marker.map = null;
          });
        }
        markers.current = undefined;
      }

      bounds.current = new libraries.current.core.LatLngBounds();

      markers.current = markersToSet.map(
        ({ collisionBehavior, content, eventListeners, id, listeners, ...otherMarkerProps }) => {
          if (libraries.current === undefined) {
            throw new Error('libraries not loaded yet');
          }

          const marker = new libraries.current.marker.AdvancedMarkerElement({
            ...otherMarkerProps,
            content: createMarkerContent(content),
            // zIndex: getCssVariableAsNumber('marker-default-zindex'),
          });

          marker.element.dataset.id = id;

          listeners?.forEach(([eventName, handler]) => {
            marker.addListener(eventName, handler);
          });

          eventListeners?.forEach(([eventName, handler]) => {
            marker.element.addEventListener(eventName, () => {
              handler(marker);
            });
          });

          if (otherMarkerProps.position) {
            bounds.current?.extend(otherMarkerProps.position);
          }

          return marker;
        }
      );

      /*
        Devo accertarmi di non chiamare 'enableVisibleMarkersFromScratch' se non c'è ancora un bound attivo, o se non c'è la coppia centro+zoom.
        Senza uno di questi 2, non ha senso abilitare quelli "visibili"... visibili dove?
      */
      if (map.current?.getBounds() || (map.current?.getCenter() && map.current.getZoom())) {
        enableMarkersFromScratch(true);
      }
    },
    [createMarkerContent, enableMarkersFromScratch, log]
  );

  const fitBoundsToCountry = React.useCallback(
    (_countryCode: CountryCode) => {
      log('fitBoundsToCountry', _countryCode);

      if (libraries.current === undefined) {
        throw new Error('libraries not loaded yet');
      }

      const countryBounds = new libraries.current.core.LatLngBounds();

      const country = countries.find(({ code }) => code === _countryCode.toUpperCase());
      if (country) {
        countryBounds.extend({ lat: country.ne_lat, lng: country.ne_lng });
        countryBounds.extend({ lat: country.sw_lat, lng: country.sw_lng });
        map.current?.fitBounds(countryBounds);
      }
    },
    [log]
  );

  const fitBoundsToBounds = React.useCallback(
    (_bounds: google.maps.LatLngBounds) => {
      log('fitBoundsToBounds', _bounds);

      if (libraries.current === undefined) {
        throw new Error('libraries not loaded yet');
      }
      map.current?.fitBounds(_bounds);
    },
    [log]
  );

  const init = React.useCallback(() => {
    log('init');

    if (libraries.current === undefined) {
      throw new Error('libraries not loaded yet');
    }

    if (ref.current) {
      map.current = new libraries.current.maps.Map(ref.current, { ...mapProps, mapId: mapId });

      // Per iniziare, come fallback in mancanza di markers, faccio un fitBounds sulla country.
      // Lo faccio solo se non sono stati passati dei marker già in fase di init, se no da un errore strano settando lo zoom a 0.
      if (markersProps === undefined) {
        fitBoundsToCountry(countryCode);
      }

      if (clustererProps) {
        clusterer.current = new MarkerClusterer({
          ...(typeof clustererProps === 'object' ? clustererProps : {}),
          algorithm: new SuperClusterAlgorithm(
            typeof clustererProps === 'object' ? clustererProps.algorithmOptions : undefined
          ),
          map: map.current,
          renderer:
            typeof clustererProps === 'object' && clustererProps.markerContentBuilder
              ? {
                  render: (cluster) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    return new libraries.current!.marker.AdvancedMarkerElement({
                      content: clustererProps.markerContentBuilder
                        ? createMarkerContent(clustererProps.markerContentBuilder(cluster))
                        : undefined,
                      position: cluster.position,
                      title: cluster.count.toString(),
                      zIndex: getCssVariableAsNumber('cluster-zindex'),
                    });
                  },
                }
              : typeof clustererProps === 'object' && clustererProps.defaultRendererOptions
              ? {
                  render: ({ count, position }, stats) => {
                    const {
                      aboveAvarageColor,
                      belowAvarageColor,
                      fontSize = 12,
                      offset = 0.15,
                      opacity = 0.6,
                      size = 50,
                    } = clustererProps.defaultRendererOptions ?? {};

                    const color =
                      count > Math.max(10, stats.clusters.markers.mean) ? aboveAvarageColor : belowAvarageColor;

                    const parser = new DOMParser();

                    const svgString = `
                      <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${size * 2} ${
                      size * 2
                    }" width="${size}" height="${size}" transform="translate(0 25)" style="font-size: ${fontSize}px">
                        <circle cx="${size}" cy="${size}" opacity="${opacity}" r="${size * (1 - offset * 2)}"></circle>
                        <circle cx="${size}" cy="${size}" opacity=".3" r="${size * (1 - offset)}"></circle>
                        <circle cx="${size}" cy="${size}" opacity=".2" r="${size}"></circle>
                        <text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="2em" dominant-baseline="middle" font-family="roboto,arial,sans-serif">
                          ${count}
                        </text>
                      </svg>
                    `;

                    const svg = parser.parseFromString(svgString, 'image/svg+xml').documentElement;

                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    return new libraries.current!.marker.AdvancedMarkerElement({
                      content: svg,
                      position: position,
                      title: `Cluster of ${count} markers`,
                      zIndex: getCssVariableAsNumber('cluster-zindex'),
                      // zIndex: 1000000 + count,
                    });
                  },
                }
              : undefined,
        });
      }

      addListener('dragend', () => enableVisibleMarkersWithDelta());
      addListener('zoom_changed', () => enableMarkersFromScratch(true));

      enabledMarkers.current = [];
      enabledMarkersIds.current = [];

      // Se già in fase di init ho passato dei markers, li setto.
      if (markersProps) {
        setMarkers(markersProps);
        enableMarkersFromScratch(false);
      }
    }
  }, [
    addListener,
    clustererProps,
    countryCode,
    createMarkerContent,
    enableMarkersFromScratch,
    enableVisibleMarkersWithDelta,
    fitBoundsToCountry,
    mapId,
    mapProps,
    markersProps,
    setMarkers,
    log,
  ]);

  React.useEffect(() => {
    if (!initialized.current) {
      loadLibraries().then(() => {
        init();
        setReady(true);
      });
      initialized.current = true;
    }
  }, [init, loadLibraries]);

  const addMarkerClassName = React.useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement, className: string) => {
      log('addMarkerClassName', marker, className);
      marker.element.classList.add(className);
    },
    [log]
  );

  const addMarkerClassNameById = React.useCallback(
    (id: string, className: string) => {
      log('addMarkerClassNameById', id, className);
      const marker = markers.current?.find((_marker) => _marker.element.dataset.id === id);
      if (marker) {
        addMarkerClassName(marker, className);
      }
    },
    [addMarkerClassName, log]
  );

  const removeMarkerClassName = React.useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement, className: string) => {
      log('removeMarkerClassName', marker, className);
      marker.element.classList.remove(className);
    },
    [log]
  );

  const removeMarkerClassNameById = React.useCallback(
    (id: string, className: string) => {
      log('removeMarkerClassNameById', id, className);
      const marker = markers.current?.find((_marker) => _marker.element.dataset.id === id);
      if (marker) {
        removeMarkerClassName(marker, className);
      }
    },
    [removeMarkerClassName, log]
  );

  const panTo = React.useCallback(
    (latlng: google.maps.LatLng | google.maps.LatLngLiteral) => {
      log('panTo', latlng);
      map.current?.panTo(latlng);
    },
    [log]
  );

  const getZoom = React.useCallback((): number => {
    log('getZoom');
    return map.current?.getZoom() ?? 15;
  }, [log]);

  const setZoom = React.useCallback(
    (_zoom: number) => {
      log('setZoom', _zoom);
      map.current?.setZoom(_zoom);
    },
    [log]
  );

  const fitBounds = React.useCallback(() => {
    log('fitBounds');
    if (markers.current === undefined) {
      throw new Error('Impossibile invocare "fitBounds" prima di "setMarkers"');
    }
    if (bounds.current) {
      map.current?.fitBounds(bounds.current);
    }
  }, [log]);

  const getVisibleMarkersIds = React.useCallback(() => {
    log('getVisibleMarkersIds');
    return visibleMarkersIds.current;
  }, [log]);

  const setCurrentPosition = React.useCallback(
    (currentPosition: GeolocationPosition, content?: MarkerContent) => {
      log('setCurrentPosition', currentPosition);

      if (libraries.current === undefined) {
        throw new Error('libraries not loaded yet');
      }

      if (currentPositionMarker.current !== undefined) {
        currentPositionMarker.current.map = undefined;
      }

      currentPositionMarker.current = new libraries.current.marker.AdvancedMarkerElement({
        content: createMarkerContent(content),
        map: map.current,
        position: { lat: currentPosition.coords.latitude, lng: currentPosition.coords.longitude },
        title: 'Current position',
        zIndex: getCssVariableAsNumber('current-position-marker-zindex'),
      });
    },
    [createMarkerContent, log]
  );

  const setSearchedPosition = React.useCallback(
    (latlng: google.maps.LatLng | google.maps.LatLngLiteral, content?: MarkerContent) => {
      log('setSearchedPosition', latlng);

      if (libraries.current === undefined) {
        throw new Error('libraries not loaded yet');
      }

      if (searchedPositionMarker.current !== undefined) {
        searchedPositionMarker.current.map = undefined;
      }

      searchedPositionMarker.current = new libraries.current.marker.AdvancedMarkerElement({
        content: createMarkerContent(content),
        map: map.current,
        position: latlng,
        title: 'Searched position',
        zIndex: getCssVariableAsNumber('searched-position-marker-zindex'),
      });
    },
    [createMarkerContent, log]
  );

  const resetSearchedPosition = React.useCallback(() => {
    log('resetSearchedPosition');

    if (libraries.current === undefined) {
      throw new Error('libraries not loaded yet');
    }

    if (searchedPositionMarker.current !== undefined) {
      searchedPositionMarker.current.map = undefined;
    }
  }, [log]);

  const googleMap = React.useMemo<GoogleMapInstance | undefined>(
    () =>
      ready
        ? {
            addControl,
            addListener,
            addMarkerClassName,
            addMarkerClassNameById,
            fitBounds,
            fitBoundsToBounds,
            fitBoundsToCountry,
            getVisibleMarkersIds,
            getZoom,
            panTo,
            removeMarkerClassName,
            removeMarkerClassNameById,
            resetSearchedPosition,
            setCurrentPosition,
            setMarkers,
            setSearchedPosition,
            setZoom,
          }
        : undefined,
    [
      ready,
      addControl,
      addListener,
      addMarkerClassName,
      addMarkerClassNameById,
      fitBounds,
      fitBoundsToCountry,
      getVisibleMarkersIds,
      getZoom,
      panTo,
      removeMarkerClassName,
      removeMarkerClassNameById,
      resetSearchedPosition,
      setCurrentPosition,
      setMarkers,
      setZoom,
      fitBoundsToBounds,
      setSearchedPosition,
    ]
  );

  return [ref, googleMap] as const;
};
