import classnames from 'classnames';

import { Div } from '../HTMLElement';
import { uiClassName } from '../util';
import { FiltersToggle } from './FiltersToggle';

export interface DesktopFiltersToggleProps {
  detailsPanelVisibility: boolean;
  filterCount: number;
  filtersPanelVisibility: boolean;
  toggleFiltersPanel(): void;
}

export const DesktopFiltersToggle = ({
  detailsPanelVisibility,
  filterCount,
  filtersPanelVisibility,
  toggleFiltersPanel,
}: DesktopFiltersToggleProps) => {
  return (
    <Div
      className={uiClassName({ bs5: 'position-absolute' })}
      display={{ lg: 'block', xs: 'none' }}
      id="desktop-filters-toggle-container"
    >
      <Div
        className={classnames('shadow', {
          hide: filtersPanelVisibility || detailsPanelVisibility,
        })}
        id="desktop-filters-toggle"
        padding={3}
        paddingStart={0}
      >
        <FiltersToggle filterCount={filterCount} toggleFiltersPanel={toggleFiltersPanel} />
      </Div>
    </Div>
  );
};
