import './SliderProduct.scss';
import 'swiper/scss/navigation'; // Navigation module
import 'swiper/scss'; // core Swiper

import { b2x } from '@b2x/react/src';
import React from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ProductTile } from '../ProductTile';
import { SliderButton } from './SliderButton';
export interface SliderProductProps {
  products: Array<b2x.ProductApiDto>;
}

export const SliderProduct = ({ products }: SliderProductProps) => {
  const currentBreakpoint = b2x.useBreakpoint();
  const sliderUUID = React.useMemo(() => `slider-${b2x.createUUID()}`, []);
  return (
    <div className="slider-product position-relative" id={sliderUUID}>
      <b2x.EqualHeight>
        <Swiper
          spaceBetween={16}
          {...(currentBreakpoint === 'xs' && { slidesPerView: 1.15 })}
          {...(currentBreakpoint === 'sm' && { slidesPerView: 2.25 })}
          {...(currentBreakpoint === 'md' && { slidesPerView: 2.25 })}
          {...(currentBreakpoint === 'lg' && { slidesPerView: 3.25 })}
          {...(currentBreakpoint === 'xl' && { slidesPerView: 4 })}
          {...(currentBreakpoint === 'xxl' && { slidesPerView: 4 })}
          centerInsufficientSlides
          modules={[Navigation]}
          navigation={{ nextEl: `#${sliderUUID} .swiper-button-next`, prevEl: `#${sliderUUID} .swiper-button-prev` }}
        >
          {products.map((product, index) => (
            <SwiperSlide key={product.id}>
              <ProductTile product={product} productsPerRow={1} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="slider-navigation position-absolute top-0 start-0 w-100">
          <b2x.EqualHeightElement name="product-image-1">
            <SliderButton direction="left" size={'small'} />
            <SliderButton direction="right" size={'small'} />
          </b2x.EqualHeightElement>
        </div>
      </b2x.EqualHeight>
    </div>
  );
};
