// eslint-disable-next-line no-restricted-imports
import { api } from '@b2x/storefront-api-js-client/src';
import { ShopApiDto } from '@b2x/storefront-api-js-client/src/dto';
import { GetShopOptions, SearchShopsOptions } from '@b2x/storefront-api-js-client/src/shops';
import React from 'react';

import lottomaticaStores from './lottomatica.json';
import { ApiRequestConfig, useApiRequest } from './useApiRequest';

export const useShopsApi = () => {
  const { apiRequest } = useApiRequest();

  const searchShops = React.useCallback(
    (key?: Array<unknown>, options?: SearchShopsOptions, config?: ApiRequestConfig) =>
      apiRequest(api.shops.search(key, options), { silent: false, ...config }),
    [apiRequest]
  );

  const searchShopsMock = React.useCallback(
    () =>
      new Promise<Array<ShopApiDto>>((resolve) => {
        setTimeout(() => {
          resolve(
            lottomaticaStores
              // .filter((lottomaticaStore) => lottomaticaStore.brand === 'Better')
              .slice(0, 1000)
              .map((lottomaticaStore) => ({
                address: {
                  addressLine1: lottomaticaStore.indirizzo,
                  id: lottomaticaStore.id,
                  latitude: lottomaticaStore.latitudine,
                  longitude: lottomaticaStore.longitudine,
                  zipCode: lottomaticaStore.cap,
                },
                code: lottomaticaStore.brand,
                id: lottomaticaStore.id.toString(),
                name: lottomaticaStore.ragioneSociale,
              }))
          );
        }, 100);
      }),
    []
  );

  const getShop = React.useCallback(
    (id: string, options?: GetShopOptions, config?: ApiRequestConfig) =>
      apiRequest(api.shops.get(id, options), { ...config }),
    [apiRequest]
  );

  return {
    getShop,
    searchShops,
    searchShopsMock,
  };
};

// export interface ShopApiDto {
//   cap: string;
//   codice_nazione: string;
//   comune: string;
//   fatturazione_elettronica: boolean;
//   id: number;
//   indirizzo: string;
//   latitudine: number;
//   longitudine: number;
//   servizi_mobile_payment: boolean;
// }
