import { Div } from './HTMLElement';
import { renderUI } from './util';

export interface HorizontalRuleWithCenteredChildrenProps {
  children?: React.ReactElement;
  className?: string;
}

export const HorizontalRuleWithCenteredChildren = ({
  children,
  className,
}: HorizontalRuleWithCenteredChildrenProps) => {
  return renderUI({
    bs5: (
      <div className="position-relative">
        <hr className={className} />
        <Div className="position-absolute top-50 start-50 translate-middle">{children}</Div>
      </div>
    ),
  });
};
