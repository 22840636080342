import { b2x } from '@b2x/react/src';
import React from 'react';

import { AuthUserSection } from '../auth/AuthUserSection';
import { RegistrationFormContentType, WhoisPageContentType } from '../contentTypes';
import { Page } from './Page';

export interface WhoisPageProps {}

export const WhoisPage = (props: WhoisPageProps) => {
  const whoisContent = b2x.useContent<WhoisPageContentType>('WHOIS_PAGE_CONTENT');
  const registrationContent = b2x.useContent<RegistrationFormContentType>('REGISTRATION_FORM_CONTENT');

  const navigate = b2x.router.useNavigate();

  const handleLoginSuccess = React.useCallback(() => {
    navigate('/checkout');
  }, [navigate]);

  const handleRegistrationSuccess = React.useCallback(() => {
    navigate('/checkout');
  }, [navigate]);

  return (
    <Page greyBackground headerCheckout minimalFooter noPaddingTop>
      <AuthUserSection
        login={{
          handleLoginSuccess,
          subTitle: whoisContent?.body.continueAsRegisteredUser?.subTitle,
          title: whoisContent?.body.continueAsRegisteredUser?.title,
        }}
        signUp={{
          canProceedWithoutRegistration: true,
          handleRegistrationSuccess,
          newsletterDiscount: registrationContent?.body.newsletterDiscount,
          ...whoisContent?.body.continueAsGuest,
        }}
      />
    </Page>
  );
};
